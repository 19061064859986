import { FormGroup } from '@angular/forms';
import { TestAnalysisDto } from 'src/app/models/dto/testAnalysisDto';
import { TestCaseDto } from 'src/app/models/dto/testCaseDto';
import { TestCoverageDto } from 'src/app/models/dto/testCoverageDto';
import { TestSpecificationDto } from 'src/app/models/dto/testSpecificationDto';
import { TestSuiteDto } from 'src/app/models/dto/testSuiteDto';
import { ItemType } from 'src/app/models/enums/itemType';
import { TestCaseFactory } from './test-case.factory';
import { TestSuiteFactory } from './test-suite.factory';
import { TestAnalysisFactory } from './test-analysis.factory';
import { TestCoverageFactory } from './test-coverage.factory';
import { TestSpecificationFactory } from './test-specification.factory';

export class ItemFactory {

    public static createItem(originalItem: any, form: FormGroup, itemType: ItemType): any {
        switch(itemType) {
            case ItemType.TestCase:
                return TestCaseFactory.create(originalItem as TestCaseDto, form);
            case ItemType.TestSuite:
                return TestSuiteFactory.create(originalItem as TestSuiteDto, form);
            case ItemType.TestAnalysis:
                return TestAnalysisFactory.create(originalItem as TestAnalysisDto, form);
            case ItemType.TestCoverage:
                return TestCoverageFactory.create(originalItem as TestCoverageDto, form);
            case ItemType.TestSpecification:
                return TestSpecificationFactory.create(originalItem as TestSpecificationDto, form);
            default:
                throw new Error('ItemFactoryService: Invalid item type');
        }
    }
}
