import { Ecu } from "src/app/models/ecu";
import { GraphQLEcuOutput } from "../models/output/graphql-ecu-output";

export function convertGraphqlEcuToEcu(input: GraphQLEcuOutput): Ecu {
    return {
        id : input.Id,
        name : input.Name,
        parentId : input.ParentId,
        ecuFamily : input.EcuFamily
    }
  }