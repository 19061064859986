import { OrganizationalGroup } from "src/app/models/dto/organizationalGroup";
import { GraphQLOrganizationalGroupOutput } from "../models/output/graphql-organizational-group-output";

  export function convertGraphqOrganizationalGroupToDto(input: GraphQLOrganizationalGroupOutput): OrganizationalGroup {
    return {
      uid: input.Uid,
      name: input.Name,
      description: input.Description
    }
  }
