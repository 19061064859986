import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-preview-changes',
  templateUrl: './preview-changes.component.html',
  styleUrls: ['./preview-changes.component.scss'],
})
export class PreviewChangesComponent implements OnInit, OnChanges {
  @Input() items: any[];
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // this.items.forEach(item => {
    //   Object.keys(item).forEach((key) => {
    //   });
    // });
  }

  checkIn(): void {}
  cancel(): void {}
}
