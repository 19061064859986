import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OrganizationalGroupsApiService } from 'src/app/services/api/organizational-groups-api.service';
import { OrganizationalGroupsActions } from './organizationalGroups.actions';
import { OrganizationalGroupsStateModel } from './organizationalGroups.state.model';

@State<OrganizationalGroupsStateModel>({
  name: 'organizationalGroups',
  defaults: {
    organizationalGroups: [],
    loggedInOrganizationalGroup: undefined,
  },
})
@Injectable()
export class OrganizationalGroupsState {
  constructor(private organizationalGroupsApiService: OrganizationalGroupsApiService) {}

  @Action(OrganizationalGroupsActions.SetAllOrganizationalGroups)
  SetAllOrganizationalGroups(
    ctx: StateContext<OrganizationalGroupsStateModel>
  ) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (
      state.organizationalGroups !== undefined &&
      state.organizationalGroups.length > 0
    )
      return;

    this.organizationalGroupsApiService
      .get()
      .subscribe((organizationalGroups) => {
        ctx.patchState({ organizationalGroups: organizationalGroups });
      });
  }

  @Selector()
  static getAllOrganizationalGroups(state: OrganizationalGroupsStateModel) {
    return state.organizationalGroups;
  }

  @Action(OrganizationalGroupsActions.SetOrganizationalGroupByUsername)
  SetOrganizationalGroupByUsername(
    ctx: StateContext<OrganizationalGroupsStateModel>,
    action: OrganizationalGroupsActions.SetOrganizationalGroupByUsername
  ) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (state.loggedInOrganizationalGroup !== undefined) return;

    this.organizationalGroupsApiService
      .getByUsername(action.username)
      .subscribe((organizationalGroup) => {
        ctx.patchState({
          loggedInOrganizationalGroup: organizationalGroup,
        });
      });
  }

  @Selector()
  static getLoggedInOrganizationalGroup(state: OrganizationalGroupsStateModel) {
    return state.loggedInOrganizationalGroup;
  }
}
