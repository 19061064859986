import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TestCasesActions } from './testCases.actions';
import { TestCasesStateModel } from './testCases.state.model';
import { patch, removeItem } from '@ngxs/store/operators';
import { TestCaseApiService } from 'src/app/services/api/test-case-api.service';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';

@State<TestCasesStateModel>({
  name: 'testCases',
  defaults: {
    testCases: [],
    orgGroupTestCases: [],
    updateNeeded : false
  },
})
@Injectable()
export class TestCasesState {
  constructor(private testCaseApiService: TestCaseApiService) {}

  @Action(TestCasesActions.SetAllTestCases)
  SetAllTestCases(ctx: StateContext<TestCasesStateModel>) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.testCases !== undefined && state.testCases.length > 0) return;

    this.testCaseApiService.getAll('', '').subscribe((testCases) => {
      ctx.patchState({ testCases: testCases });
    });
  }

  @Action(TestCasesActions.SetOrgGroupTestCases)
  SetOrgGroupTestCases(ctx: StateContext<TestCasesStateModel>, action: TestCasesActions.SetOrgGroupTestCases) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.orgGroupTestCases !== undefined && state.orgGroupTestCases.length > 0) 
      return;

    this.testCaseApiService.getAll('', action.orgGroupId )
      .subscribe(result =>{
        ctx.patchState({ orgGroupTestCases: result });
      });
  }

  @Action(TestCasesActions.DeleteTestCase)
  DeleteTestCase(ctx: StateContext<TestCasesStateModel>, action: TestCasesActions.DeleteTestCase){

    this.testCaseApiService.delete(action.testCaseUid, action.regBy)
      .subscribe(result =>{

        ctx.setState(
          patch<TestCasesStateModel>({
            orgGroupTestCases: removeItem<BaseTestItemDto>(x => x.uid === action.testCaseUid),
            testCases: removeItem<BaseTestItemDto>(x => x.uid === action.testCaseUid)
          })
        )
      });
  }
  
  @Action(TestCasesActions.SetUpdateNeeded)
  setUpdateNeeded(ctx: StateContext<TestCasesStateModel>, action: TestCasesActions.SetUpdateNeeded) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      updateNeeded: action.updateNeeded
    });
  }

  @Selector()
  static getAllTestCases(state: TestCasesStateModel) {
    return state.testCases;
  }

  @Selector()
  static getOrgGroupTestCases(state: TestCasesStateModel) {
    return state.orgGroupTestCases;
  }
}
