import { gql } from "apollo-angular";

export const GET_SPECIFICATION_QUERY = gql`query polarionSpecificationByIdAndIrma($SpecificationId: String, $IrmaVersion: Int) {
  polarionSpecificationByIdAndIrma(SpecificationId: $SpecificationId, IrmaVersion: $IrmaVersion) {
      PolarionId
      Title
      Revision
      IrmaVersion
      Asil
      Status
      MaturityStatus
    }
  }
  `

  export const GET_SPECIFICATION_FOR_REQUIREMENT_QUERY = gql`query polarionSpecificationsForRequirement($RequirementId: String, $IrmaVersion: Int) {
    polarionSpecificationsForRequirement(RequirementId: $RequirementId, IrmaVersion: $IrmaVersion) {
      PolarionId
      Title
      Revision
      IrmaVersion
      Asil
      Status
      MaturityStatus
    }
  }
  `


  export const GET_HI_TEST_ENDPOINT = gql`query polarionHItestEndpoint {
    polarionHItestEndpoint
  }
  `
  