import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RequirementDto } from 'src/app/models/dto/polarion/requirementDto';
import { environment } from 'src/environments/environment';
import { RequirementMapper } from '../mappers/requirementMapper';
import { ApolloClientService } from './apollo-client.service';
import { GET_REQUIREMENTS_FOR_SPECIFICATION_QUERY, GET_REQUIREMENT_QUERY } from 'src/app/graphQL/queries/requirement-queries';
import { GraphQLRequirementOutput } from 'src/app/graphQL/models/output/graphql-requirement-output';
import { convertGraphQLRequirementToDto } from 'src/app/graphQL/mappers/requirement.mappers';

@Injectable({
  providedIn: 'root',
})
export class RequirementApiService {
  private apiUrl = environment.polarionDataApiUrl + 'Requirement';

  constructor(private httpClient: HttpClient, private apolloClientService: ApolloClientService) {}


  getByIdAndIrmaVersion(requirementId: string, irmaVersion: number): Observable<RequirementDto>{
      return this.apolloClientService.apolloClient$.pipe(
        switchMap(client =>
          client.query<{polarionRequirementByIdAndIrma : GraphQLRequirementOutput}>({
            query: GET_REQUIREMENT_QUERY,
            variables: {RequirementId: requirementId, IrmaVersion: irmaVersion}
          })
        ),
        map(result => {
          const requirement = result.data.polarionRequirementByIdAndIrma
          if(!requirement)
            return undefined;
  
          return convertGraphQLRequirementToDto(requirement);
        })
      )
    }
    
  getRequirementsForSpecification(specificationId: string, irmaVersion: number): Observable<RequirementDto[]>{
      return this.apolloClientService.apolloClient$.pipe(
        switchMap(client => 
          client.query<{polarionRequirementsForSpecification: GraphQLRequirementOutput[]}>({
            query: GET_REQUIREMENTS_FOR_SPECIFICATION_QUERY,
            variables: {SpecificationId: specificationId, IrmaVersion: irmaVersion}
          })),
          map(result => {
            const requirements = result.data.polarionRequirementsForSpecification
            if(!requirements)
              return undefined;
            
            return requirements.map(req =>{
              return convertGraphQLRequirementToDto(req)
            })
        })
      )
    }
  

  // getById(id: string): Observable<RequirementDto> {
  //   return this.httpClient.get<RequirementDto>(`${this.apiUrl}/?id=${id}`);
  // }

  // getByIdAndIrmaVersion(id: string, irmaVersion: number): Observable<RequirementDto> {
  //   return this.httpClient.get<RequirementDto>(`${this.apiUrl}/${id}/irmaVersion/v${irmaVersion}`)
  //   .pipe(map(data => RequirementMapper.mapOneDto(data)));
  // }

  // getRequirementsForSpecificationByIdAndIrmaVersion(specificationId: string, specificationIrmaVersion: number): Observable<RequirementDto[]> {
  //   return this.httpClient.get<RequirementDto[]>(`${this.apiUrl}/${specificationId}/v${specificationIrmaVersion}/Requirements`)
  //   .pipe(map((items) => RequirementMapper.mapDto(items)));
  // }
}
