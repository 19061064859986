import { TestSuiteDto } from "src/app/models/dto/testSuiteDto";
import { TestSuiteAlternativeDto } from "src/app/models/dto/testSuiteAlternativeDto";
import { TestItemHierarchyLinkDto } from "src/app/models/dto/test-item-hierarchy-link-dto";
import { TestItemHierarchyLinkInput, TestSuiteAlternativeInput, TestSuiteInput } from "../models/input/graphql-test-suite-input";


  export function convertTestSuiteDtoToGraphqlInput(input: TestSuiteDto): TestSuiteInput {
    return {
      Uid: input.uid,
      ItemNo: input.itemNo,
      Name: input.name,
      Version: input.version,
      ModifiedBy: input.modifiedBy,
      ModificationTime: null, //input.ModificationTime ? input.ModificationTime.toISOString() : null,
      Comment: input.comment,
      LockedBy: input.lockedBy,
      LockedTime: null, //input.lockedTime ? input.lockedTime.toISOString() : null,
      CreatedBy: input.createdBy,
      CreationTime: null, // input.creationTime ? new Date(new Date(input.creationTime + 'Z').toLocaleString()).toDateString() : null,
      ApprovalStatus: input.approvalStatus,
      ApprovedForTestLevel: input.approvedForTestLevel,
      Label: input.label,
      PromptForMileage: input.promptForMileage,
      ShowNextTestCaseAutomatically: input.showNextTestCaseAutomatically,
      TestCases: input.testCases?.map(item => {
        return convertTestItemHierarchyLinkDtoToGraphqlInput(item)
      }),
      TestSuites: input.testSuites?.map(item => {
        return convertTestItemHierarchyLinkDtoToGraphqlInput(item)
      }),
      OwnerIds: input.ownerIds,
      UserFunctionIds: input.userFunctionIds,
      OffboardComponentIds: input.offboardComponentIds,
      ProductPropertyIds: input.ownerIds,
      Description: input.description,
      Alternative: convertTestSuiteAlternativeDtoToGraphqlInput(input.alternative),
    }
  }

  export function convertTestSuiteAlternativeDtoToGraphqlInput(input: TestSuiteAlternativeDto ): TestSuiteAlternativeInput  {
    return{
      Uid: input.uid,
      Fpc: input.fpc,
      Tpc: input.tpc,
    }
  }

    export function convertTestItemHierarchyLinkDtoToGraphqlInput(input: TestItemHierarchyLinkDto ): TestItemHierarchyLinkInput  {
      return{
        TestItemUid: input.testItemUid,
        Version: input.version,
        ChildrenTestItemLinks: input.childrenTestItemLinks?.map(item => {
          return convertTestItemHierarchyLinkDtoToGraphqlInput(item)
        }),
        Operator: input.operator,
        Value: input.value
      }
  }
