import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { ItemType } from 'src/app/models/enums/itemType';
import { PreviewChange } from 'src/app/models/previewChange';

@Component({
  selector: 'test-item-action',
  templateUrl: './test-item-action.component.html',
  styleUrls: ['./test-item-action.component.scss']
})
export class TestItemActionComponent{

  @Input() changes: PreviewChange[] = [];
  @Input() testItem: BaseTestItemDto;
  @Input() actionButton:TestItemActionButton;
  @Input() toolTip?: string;
  @Input() itemType: ItemType;
  @Output() checkOutTestItem = new EventEmitter();
  @Output() unlockTestItem = new EventEmitter();
  @Output() submitTestItem = new EventEmitter();
  constructor() { }

  openParentItemsModal(){
    var modal = document.getElementById("parent-items-modal");
    modal.classList.remove("hide");
    modal.classList.add("show");
  }

  openSendToReviewModal(){
    var modal = document.getElementById("send-to-review-modal");
    modal.classList.remove("hide");
    modal.classList.add("show");
  }

  checkOut(){
    this.checkOutTestItem.emit();
  }

  unlock(){
    this.unlockTestItem.emit();
  }

  onSubmit(){
    this.submitTestItem.emit();
  }
}

export interface TestItemActionButton{
  showCancel: boolean;
  enableCancel: boolean;
  showCheckIn: boolean;
  enableCheckIn: boolean;
  showCheckOut: boolean;
  enableCheckOut: boolean;
  showSave: boolean;
  enableSave: boolean;
  showPreviewChanges: boolean;
  enablePreviewChanges: boolean;
  showViewParentItems: boolean;
  stateAdd: boolean;
  stateEdit: boolean;
  stateCheckedOut: boolean;
  stateReadOnly: boolean;
  sendToReview: boolean;
}
