<h6>Approvals</h6>
<p-table [value]="items" [scrollable]="true" dataKey="uid">
  <ng-template pTemplate="header">
    <tr>
      <th>Approver</th>
      <th>Test Level</th>
      <th>Approval status</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{ item.name }}</td>
      <td>{{ item.version }}</td>
      <td>{{ item.regBy }}</td>
    </tr>
  </ng-template>
</p-table>
