import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ResponseDto } from 'src/app/models/dto/responseDto';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { TestAnalysisDto } from 'src/app/models/dto/testAnalysisDto';
import { formField } from 'src/app/models/formField';
import { ApolloClientService } from './apollo-client.service';
import { GraphQLBaseTestItemOutput } from 'src/app/graphQL/models/output/graphql-base-test-item-output';
import { GETALL_TEST_ANALYSIS_QUERY, GET_TEST_ANALYSIS_QUERY, GET_TEST_ANALYSIS_UI_TEMPLATE_QUERY } from 'src/app/graphQL/queries/test-analysis-queries';
import { convertGraphqlResponseToDto, convertGraphqlResponseToDtoSimpleReturnValue } from 'src/app/graphQL/mappers/common.mappers';
import { GraphQLResponseOutput } from 'src/app/graphQL/models/output/graphql-response-output';
import { GraphQLFormFieldOutput } from 'src/app/graphQL/models/output/graphql-form-field-output';
import { convertGraphqlFormFieldToDto, convertGraphqlBaseTestItemToDto } from 'src/app/graphQL/mappers/common.mappers';
import { CREATE_TEST_ANALYSIS_MUTATION, DELETE_TEST_ANALYSIS_MUTATION, UPDATE_TEST_ANALYSIS_MUTATION } from 'src/app/graphQL/mutations/test-analysis-mutations';
import { convertTestAnalysisDtoToGraphqlBaseTestItem } from 'src/app/graphQL/mappers/test-analysis.mappers';
import { GET_ITEMS_WITH_LINK_QUERY } from 'src/app/graphQL/queries/item-queries';
import { ItemTypeMapper } from '../mappers/itemTypeMapper';
import { ItemType } from 'src/app/models/enums/itemType';

@Injectable({
  providedIn: 'root'
})
export class TestAnalysisApiService {

  constructor(private apolloClientService: ApolloClientService) { }

  getAll(regBy: string, orgGroupId?: string): Observable<BaseTestItemDto[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorAllTestAnalysis : GraphQLBaseTestItemOutput[]}>({
          query: GETALL_TEST_ANALYSIS_QUERY,
          variables: {RegBy: regBy, OrgGroup: orgGroupId}
        })
      ),
      map(results => {
        const testAnalyses = results.data.configuratorAllTestAnalysis
        if (!testAnalyses) 
          return undefined

        return testAnalyses.map(item => {
          return convertGraphqlBaseTestItemToDto(item);
        })
      })
    )
  }

  getByUid(uid: string): Observable<ResponseDto> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorTestAnalysisById: GraphQLResponseOutput}>({
          query: GET_TEST_ANALYSIS_QUERY,
          variables: {Uid: uid}
        })
      ),
      map(results => {
        const testAnalysis = results.data.configuratorTestAnalysisById
        if (!testAnalysis)
          return undefined

        return convertGraphqlResponseToDto<TestAnalysisDto>(testAnalysis);
      })
    )
  }

  getUiTemplate(): Observable<formField[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorTestAnalysisUiTemplate: GraphQLFormFieldOutput[]}>({
          query: GET_TEST_ANALYSIS_UI_TEMPLATE_QUERY,
          variables: {}
        })
      ),
      map(results => {
        const testAnalysisUiTemplate = results.data.configuratorTestAnalysisUiTemplate

        if (!testAnalysisUiTemplate)
          return undefined

        return testAnalysisUiTemplate.map(item => {
          return convertGraphqlFormFieldToDto(item);
        })
      })
    )
  }

  getAllWithLinkedRequirement(requirementId: string): Observable<BaseTestItemDto[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorItemsWithLink : GraphQLBaseTestItemOutput[]}>({
          query: GET_ITEMS_WITH_LINK_QUERY,
          variables: {ItemTypeId: ItemTypeMapper.mapReverse(ItemType.TestAnalysis), LinkTypeId: 238, LinkValue: requirementId} //238 is link TA-requirement
        })
      ),
      map(results => {
        const testAnalyses = results.data.configuratorItemsWithLink
        if (!testAnalyses) 
          return undefined

        return testAnalyses.map(item => {
          return convertGraphqlBaseTestItemToDto(item);
        })
      })
    )
  }

  create(testAnalysis: TestAnalysisDto): Observable<ResponseDto> {
    const graphqlTestAnalysisInput = convertTestAnalysisDtoToGraphqlBaseTestItem(testAnalysis)
      
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorCreateTestAnalysis: GraphQLResponseOutput}>({
          mutation: CREATE_TEST_ANALYSIS_MUTATION,
          variables: {TestAnalysis: graphqlTestAnalysisInput }
        })
      ),
      map(result => convertGraphqlResponseToDtoSimpleReturnValue(result.data.configuratorCreateTestAnalysis as GraphQLResponseOutput))
    )
  }

  update(testAnalysis: TestAnalysisDto): Observable<ResponseDto> {
    const graphqlTestAnalysisInput = convertTestAnalysisDtoToGraphqlBaseTestItem(testAnalysis)
      
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorUpdateTestAnalysis: GraphQLResponseOutput}>({
          mutation: UPDATE_TEST_ANALYSIS_MUTATION,
          variables: {TestAnalysis: graphqlTestAnalysisInput }
        })
      ),
      map(result => convertGraphqlResponseToDtoSimpleReturnValue(result.data.configuratorUpdateTestAnalysis as GraphQLResponseOutput))
    )
  }

  delete(uid: string, regBy: string): Observable<any> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate({
          mutation: DELETE_TEST_ANALYSIS_MUTATION,
          variables: {Uid: uid}
        })
      ),
      map(result =>(result.data))
    )
  }
}
