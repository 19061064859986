import { Injectable } from '@angular/core';
import { ApolloClientService } from './apollo-client.service';
import { UserFunction } from 'src/app/models/dto/user-function-dto';
import { EMPTY, map, Observable, switchMap } from 'rxjs';
import { GETALL_USER_FUNCTIONS_HIERARCHY_QUERY, GETALL_USER_FUNCTIONS_QUERY } from 'src/app/graphQL/queries/user-functions-queries';
import { GraphQLUserFunctionHierarchyOutput, GraphQLUserFunctionOutput } from 'src/app/graphQL/models/output/graphql-user-function-output';
import { convertGraphqlUserFunctionHierarchyToDto, convertGraphqlUserFunctionToDto } from 'src/app/graphQL/mappers/user-function.mapper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserFunctionsService {

  constructor(private apolloClientService: ApolloClientService) { }

  getUserFunctions(): Observable<UserFunction[]> {
    if(environment.IsHero == true)
      return EMPTY;
    
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorUserFunctions: GraphQLUserFunctionOutput[]}>({
          query: GETALL_USER_FUNCTIONS_QUERY,
          variables: {}
        })
      ),
      map(results => {
        const userFunctions = results.data.configuratorUserFunctions
        if (!userFunctions)
          return undefined
  
        return userFunctions.map(item => {
          return convertGraphqlUserFunctionToDto(item) as UserFunction;
        })
      })
    )
  }

  getUserFunctionHierarchy(): Observable<UserFunction[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorUserFunctions: GraphQLUserFunctionHierarchyOutput[]}>({
          query: GETALL_USER_FUNCTIONS_HIERARCHY_QUERY,
          variables: {}
        })
      ),
      map(results => {
        const userFunctions = results.data.configuratorUserFunctions
        if (!userFunctions)
          return undefined
  
        return userFunctions.map(item => {
          return convertGraphqlUserFunctionHierarchyToDto(item) as UserFunction;
        })
      })
    )
  }
}
