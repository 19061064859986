import { Injectable } from "@angular/core";
import { Config } from 'src/environments/environment';
import { UserData } from "../models/user-data";
import { BehaviorSubject, Observable, of } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ){}

  private redirectStarted = false;

  private userDataSubject: BehaviorSubject<UserData> = new BehaviorSubject({ sessionUID: localStorage.getItem('sessionId'), userName: localStorage.getItem('userName'), isLoggedIn: !!localStorage.getItem('sessionId')} as UserData)
  public userData$ = this.userDataSubject.asObservable().pipe(distinctUntilChanged());

  public isLoggedIn$ = this.userData$.pipe(
    map(userData => !!userData.sessionUID || !!localStorage.getItem('sessionId'))
  )

  public sessionUid$ = this.userData$.pipe(
    map(userData => userData.sessionUID ?? localStorage.getItem('sessionId'))
  )


  clearSession(): void {
    this.userDataSubject.next({userName: '', sessionUID: '', isLoggedIn: false, companyName: ''})

    // Clear data from local storage
    localStorage.removeItem('sessionId');
    localStorage.removeItem('userName');
    localStorage.removeItem('companyName');
  }


  DoLogin(sessionId: string, userName: string, companyName: string): Observable<any> {

    // bypass Cola
    if (Config.colaConfig.disabled) {
      const dummyUserData = {
        sessionUID: 'x',
        userName: 'x',
        companyName: 'Scania',
        isLoggedIn: true
      }
      this.userDataSubject.next(dummyUserData)

      return of([dummyUserData])
    }

    if (sessionId != '') {
      return of({
        sessionUID: sessionId,
        userName: userName,
        companyName: companyName,
        isLoggedIn: true
      }).pipe(
        tap(data => {
          this.userDataSubject.next(data)
          localStorage.setItem('sessionId', data.sessionUID)
          localStorage.setItem('userName', data.userName)
          localStorage.setItem('companyName', data.companyName)
          this.removeSpecificQueryParams(['sessionId', 'username', 'company']);
        })
      )
    }
    else {
      return this.isLoggedIn$.pipe(
        filter(isLoggedIn => !isLoggedIn),
        map(() => {
          const host = window.location.href
          const loginUrl = `${Config.colaConfig.redirectUrl}?origin=${encodeURIComponent(host)}`
          console.log(`Redirecting to ${loginUrl}`)
          if (!this.redirectStarted)
          {
            this.redirectStarted = true;
            window.location.href = loginUrl;
          }
        })
      )
    }
  }

  /**
   * Removes specified query parameters from route.
   * Used to clear parameters related to authorization after use to prevent loops.
   * @param paramsToRemove Array of query params to remove.
   */
  private removeSpecificQueryParams(paramsToRemove: string[]) {
    // Get the current query parameters
    const queryParams = { ...this.route.snapshot.queryParams };

    // Remove specific parameters
    paramsToRemove.forEach(param => delete queryParams[param]);

    // Construct a new URL without the removed parameters
    const urlTree = this.router.createUrlTree([], { queryParams });

    // Navigate to the new URL
    this.router.navigateByUrl(urlTree);
  }

}
