<div class="filter-section"> 
    <b>Filter items</b>
  
    <div class="item-filters">
      <p-radioButton name="item-filter" [value]="0" [(ngModel)]="approvalTableFilter" inputId="approvalTableFilter1"
        (onClick)="getItems()"></p-radioButton>
      <label for="approvalTableFilter1" class="ml-2">Me as Approver</label>
      
      <p-radioButton name="item-filter" [value]="1" [(ngModel)]="approvalTableFilter" inputId="approvalTableFilter2" 
        (onClick)="getItems()"></p-radioButton>
      <label for="approvalTableFilter2" class="ml-2" >Me as Reviewer</label>
      
      <p-radioButton name="item-filter" [value]="2" [(ngModel)]="approvalTableFilter" inputId="approvalTableFilter3"
      (onClick)="getItems()"></p-radioButton>
      <label for="approvalTableFilter3" class="ml-2">My Group</label>
    </div>
  </div>
  <div class="item-table">
    <p-table [value]="filteredApprovals"
      [scrollable]="true"
      [scrollHeight]="'calc(100vh - 310px)'"
      [paginator]="true"
      [rows]="20"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} approvals"
      [rowsPerPageOptions]="[10, 20, 50]" 
      [sortField]="sortField"
      [sortOrder]="sortOrder"
      dataKey="uid">
      
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 30%; text-align: left !important;" pSortableColumn="itemName">Name<p-sortIcon  class="p-sortIcon"></p-sortIcon></th>
          <th style="width: 10%;" pSortableColumn="itemVersion">Version<p-sortIcon></p-sortIcon></th>
          <th style="width: 10%;" pSortableColumn="id">ID<p-sortIcon></p-sortIcon></th>
          <th style="width: 10%;" pSortableColumn="itemType">Object Type<p-sortIcon></p-sortIcon></th>
          <th style="width: 10%;" pSortableColumn="approver">Approver<p-sortIcon></p-sortIcon></th>
          <th style="width: 10%;" pSortableColumn="reviewers">Reviewers<p-sortIcon></p-sortIcon></th>
          <th style="width: 10%;" pSortableColumn="regTime">Creation Time <p-sortIcon></p-sortIcon></th>
          <th  pSortableColumn="approvalStatus">Status<p-sortIcon></p-sortIcon></th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template #dataTemplate pTemplate="body" ngFor let-approval [ngForOf]="filteredApprovals">
        <tr>
          <td style="width: 40%; text-align: left;">{{ approval.itemName }}</td>
          <td style="width: 10%;">{{ approval.itemVersion }}</td>
          <td style="width: 10%;">{{ approval.id }}</td>
          <td style="width: 10%;">{{ itemType[approval.itemType] }}</td>
          <td style="width: 10%;">{{ approval.regBy }}</td>
          <td style="width: 10%;">
            <ng-container *ngFor="let reviewer of approval.reviewers; let i = index">
              {{ reviewer }}<span *ngIf="i < approval.reviewers.length - 1">, </span>
            </ng-container>
          </td>
          <td style="width: 10%;">{{ approval.regTime| date: "dd.MM.yyyy HH:mm" }}</td>
          <td style="width: 10%;">{{ approvalStatus[approval.approvalStatus] }}</td>
          <td style="width: 80%;">
            <button
            class="sdds-btn sdds-btn-secondary sdds-btn-sm"
            [routerLink]="['/', getRoute(approval.itemType), approval.itemUid]" [queryParams]="{approvalId: approval.id}" (click)="setReviewState()"
            pTooltip="Open approval"
          >
            <i class="pi pi-pencil" style="font-size: initial"></i>
          </button>
          <td style="width: 80%;">
            <button
            *ngIf="!isUserReviewer(approval)"
            class="sdds-btn sdds-btn-secondary sdds-btn-sm"
            [disabled]="disableUserAsReviewerButton(approval)"
            (click) = "addUserToReviewers(approval)"
            pTooltip="Assign myself"
          >
            <i class="pi pi-user" style="font-size: initial" ></i>
          </button>
        </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="7">No items found.</td>
        </tr>
    </ng-template>
    </p-table>
  </div>