import { ProductProperty } from "src/app/models/dto/productProperty";
import { GraphQLProductPropertyOutput } from "../models/output/graphql-product-property-output";

  export function convertGraphqProductPropertyToDto(input: GraphQLProductPropertyOutput): ProductProperty {
    return {
      uid: input.Uid,
      name: input.Name,
      parentUid: input.ParentUid
    }
  }
