<p-table [value]="items" [scrollable]="true" scrollHeight="300px" dataKey="uid">
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Old Value</th>
      <th>New Value</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{ item.changeName }}</td>
      <td>{{ item.oldValue }}</td>
      <td>{{ item.newValue }}</td>
    </tr>
  </ng-template>
</p-table>

<!-- <button class="sdds-btn sdds-btn-secondary" (click)="checkIn()">
  Check In
</button>
<button class="sdds-btn sdds-btn-secondary" (click)="cancel()">Cancel</button> -->
