import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { DataLinkDto } from 'src/app/models/dto/dataLinkDto';
import { ApolloClientService } from './apollo-client.service';
import { GraphQLLinkOutput } from 'src/app/graphQL/models/output/graphql-base-test-item-output';
import { GET_LINKS_BY_TYPEID_QUERY, GET_LINKS_QUERY } from 'src/app/graphQL/queries/link-queries';
import { convertLinkGraphqlToDto } from 'src/app/graphQL/mappers/common.mappers';

@Injectable({
  providedIn: 'root'
})
export class LinksService {
  
  constructor(private apolloClientService: ApolloClientService) { }

  getLinksWhereItemIsLinked(itemUid: string, linkTypeId: number): Observable<DataLinkDto[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorLinks: GraphQLLinkOutput[]}>({
          query: GET_LINKS_QUERY,
          variables: {ItemUid: itemUid, LinkTypeId: linkTypeId}
        })
      ),
      map(results => {
        const links = results.data.configuratorLinks
        if (!links)
          return undefined
          
        return links.map(item => {
          return convertLinkGraphqlToDto(item);
        })
      })
    )
  }


  getAllLinksForLinkType(linkTypeId: number): Observable<DataLinkDto[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorLinksByTypeId: GraphQLLinkOutput[]}>({
          query: GET_LINKS_BY_TYPEID_QUERY,
          variables: {LinkTypeId: linkTypeId}
        })
      ),
      map(results => {
        const links = results.data.configuratorLinksByTypeId
        if (!links)
          return undefined
  
        return links.map(item => {
          return convertLinkGraphqlToDto(item);
        })
      })
    )
  }
}
