import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { convertGraphqlTestLevelToTestLevel } from 'src/app/graphQL/mappers/test-level.mappers';
import { GraphQLTestLevelOutput } from 'src/app/graphQL/models/output/graphql-test-level-output';
import { GET_TEST_LEVELS_QUERY } from 'src/app/graphQL/queries/test-level-queries';
import { TestLevel } from 'src/app/models/dto/testLevel';
import { environment } from 'src/environments/environment';
import { ApolloClientService } from './apollo-client.service';

@Injectable({
  providedIn: 'root',
})
export class TestlevelsService {

  constructor(private apolloClientService: ApolloClientService) {}

  get(): Observable<TestLevel[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorAllTestLevels: GraphQLTestLevelOutput[]}>({
          query: GET_TEST_LEVELS_QUERY,
        })
      ),
      map(results => {
        const testLevels = results.data.configuratorAllTestLevels
        if (!testLevels) 
          return undefined
        return testLevels.map(testLevel => {
          return convertGraphqlTestLevelToTestLevel(testLevel);
        })
      })
    )
  }
}
