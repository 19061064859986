import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteParams, RouteConstants, RouteSegments } from './constants/routeConstants';
import { TestItemListComponent } from './pages/test-item-list/test-item-list.component';
import { TestCaseComponent } from './pages/test-case/test-case.component';
import { TestSuiteComponent } from './pages/test-suite/test-suite.component';
import { TestAnalysisComponent } from './pages/test-analysis/test-analysis.component';
import { TestCoverageComponent } from './pages/test-coverage/test-coverage.component';
import { RmsComparisonComponent } from './pages/rms-comparison/rms-comparison.component';
import { TestSpecificationComponent } from './pages/test-specification/test-specification.component';
import { ReviewComponent } from './pages/review/review.component';

const routes: Routes = [
  {
    path: RouteConstants.BASE,
    // component: ItemListComponent,
    pathMatch: 'full',
    redirectTo: RouteConstants.TEST_ANALYSIS
  },
  {
    path: RouteConstants.TEST_COVERAGE,
    component: TestItemListComponent,
    pathMatch: 'full',
  },
  {
    path: `${RouteSegments.TEST_COVERAGE}/:${RouteParams.TestItemUid}`,
    component: TestCoverageComponent,
    pathMatch: 'full',
  },
  {
    path: `${RouteSegments.TEST_COVERAGE}/${RouteSegments.ADD}`,
    component: TestCoverageComponent,
    pathMatch: 'full',
  },
  {
    path: RouteConstants.RMS_SPECIFICATIONS_COMPARISON,
    component: RmsComparisonComponent,
    pathMatch: 'full',
  },
  {
    path: RouteConstants.TEST_CASES,
    component: TestItemListComponent,
    pathMatch: 'full',
  },
  {
    path: `${RouteSegments.TEST_CASES}/:${RouteParams.TestItemUid}`,
    component: TestCaseComponent,
    pathMatch: 'full',
  },
  {
    path: `${RouteSegments.TEST_CASES}/${RouteSegments.ADD}`,
    component: TestCaseComponent,
    pathMatch: 'full',
  },
  {
    path: RouteConstants.TEST_SUITES,
    component: TestItemListComponent,
    pathMatch: 'full',
  },
  {
    path: `${RouteSegments.TEST_SUITES}/:${RouteParams.TestItemUid}`,
    component: TestSuiteComponent,
    pathMatch: 'full',
  },
  {
    path: `${RouteSegments.TEST_SUITES}/${RouteSegments.ADD}`,
    component: TestSuiteComponent,
    pathMatch: 'full',
  },
  {
    path: RouteConstants.TEST_ANALYSIS,
    component: TestItemListComponent,
    pathMatch: 'full',
  },
  {
    path: `${RouteSegments.TEST_ANALYSIS}/:${RouteParams.TestItemUid}`,
    component: TestAnalysisComponent,
    pathMatch: 'full',
  },
  {
    path: `${RouteSegments.TEST_ANALYSIS}/${RouteSegments.ADD}`,
    component: TestAnalysisComponent,
    pathMatch: 'full',
  },
  {
    path: RouteConstants.TEST_SPECIFICATIONS,
    component: TestItemListComponent,
    pathMatch: 'full',
  },
  {
    path: `${RouteSegments.TEST_SPECIFICATIONS}/:${RouteParams.TestItemUid}`,
    component: TestSpecificationComponent,
    pathMatch: 'full',
  },
  {
    path: `${RouteSegments.TEST_SPECIFICATIONS}/${RouteSegments.ADD}`,
    component: TestSpecificationComponent,
    pathMatch: 'full',
  }
  ,
  {
    path: `${RouteSegments.REVIEW}`,
    component: ReviewComponent,
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
