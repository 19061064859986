import { formField } from "src/app/models/formField";
import { GraphQLFormFieldOutput } from "../models/output/graphql-form-field-output";
import { GraphQLFieldTypeEnum } from "../enums/graphql-field-type-enum";
import { FieldTypeEnum } from "src/app/models/enums/fieldTypeEnum";
import { GraphQLResponseOutput } from "../models/output/graphql-response-output";
import { ResponseDto } from "src/app/models/dto/responseDto";
import { GraphQLBaseTestItemOutput, GraphQLLinkOutput, GraphQLLinkPropertyOutput, GraphQLParentItemBasicInfoOutput } from "../models/output/graphql-base-test-item-output";
import { BaseTestItemDto } from "src/app/models/dto/Base-Test-item-dto";
import { BaseTestItemInput } from "../models/input/graphql-base-test-item-input";
import { ParentItemBasicInfo } from "src/app/models/dto/parent-item-basic-info-dto";
import { DataLinkDto } from "src/app/models/dto/dataLinkDto";
import { DataPropertyDto } from "src/app/models/dto/dataPropertyDto";
import { ItemTypeMapper } from "src/app/services/mappers/itemTypeMapper";
import { FormFieldOption } from "src/app/models/formFieldOption";
import { GraphQLItemUidAndTypeOutput } from "../models/output/graphql-item-uid-and-type-output";
import { GraphQLItemTypeEnum } from "../enums/graphql-item-type-enum";
import { ItemType } from "src/app/models/enums/itemType";
import { ItemUidAndTypeDto } from "src/app/models/dto/itemUidAndTypeDto";


export function convertGraphqlResponseToDto<T>(input: GraphQLResponseOutput): ResponseDto {
  //To-Do: convert string to localized datetime 
  // result.returnValue.creationTime = new Date(new Date(result.returnValue.creationTime + 'Z').toLocaleString());
  // result.returnValue.lockedTime = result.returnValue.lockedTime ? new Date(new Date(result.returnValue.lockedTime + 'Z').toLocaleString()) : result.returnValue.lockedTime;
  return {
    success: input.Success,
    returnValue: JSON.parse(input?.ReturnValue) as T,
    responseMessages: input.ResponseMessages,
  }
}

export function convertGraphqlResponseToDtoSimpleReturnValue(input: GraphQLResponseOutput): ResponseDto {
  return {
    success: input.Success,
    returnValue: input.ReturnValue,
    responseMessages: input.ResponseMessages,
  }
}

export function convertGraphqlFormFieldToDto(input: GraphQLFormFieldOutput): formField {
    return {
      name: input.Name,
      label: input.Label,
      category: input.Category,
      defaultValue: input.DefaultValue,
      order: input.Order,
      fieldType: convertGraphQLFieldTypeToEnum(input.FieldType),
      tooltip: input.Tooltip,
      isReadOnly: input.IsReadOnly,
      isRequired: input.IsRequired,
      isMultiValue: input.IsMultiValue,
      hasHierarchyValues: input.HasHierarchyValues,
      hierarchialOptions: [],
      options: input.ValueList ? input.ValueList.map(x => ConvertPropertyOptions(x)) : []
    }
  }

export function ConvertPropertyOptions(input: string): FormFieldOption{
 return {
  key: input,
  value: input,
  data: undefined
 }
}

export function convertGraphqlBaseTestItemToDto(input: GraphQLBaseTestItemOutput): BaseTestItemDto {
  return {
    uid: input.Uid,
    itemNo: input.ItemNo,
    name: input.Name,
    version: input.Version,
    createdBy: input.CreatedBy,
    creationTime: input.CreationTime ? new Date(new Date(input.CreationTime + 'Z').toLocaleString()) : null,
    comment: input.Comment,
    lockedBy: input.LockedBy,
    lockedTime: input.LockedTime ? new Date(new Date(input.LockedTime + 'Z').toLocaleString()) : null,
    modifiedBy: input.ModifiedBy,
    approvalStatus: input.ApprovalStatus,
    approvedForTestLevel: input.ApprovedForTestLevel,
  }
}

export function convertBaseTestItemDtoToGraphqlInput(input: BaseTestItemDto): BaseTestItemInput {
  return {
    Uid: input.uid,
    ItemNo: input.itemNo,
    Name: input.name,
    Version: input.version,
    CreatedBy: input.createdBy,
    CreationTime: null,
    Comment: input.comment,
    LockedBy: input.lockedBy,
    LockedTime: null,
    ModifiedBy: input.modifiedBy,
    ModificationTime: input.modificationTime ? new Date(new Date(input.modificationTime + 'Z').toLocaleString()) : null,
    ApprovalStatus: input.approvalStatus,
    ApprovedForTestLevel: input.approvedForTestLevel,
  }
}

export function convertGraphQLParentItemBasicInfoOutputToDto(input: GraphQLParentItemBasicInfoOutput): ParentItemBasicInfo {
  return {
    uid: input.Uid,
    name: input.Name,
    typeName: ItemTypeMapper.map(input.TypeId),
    itemNo: input.ItemNo
  }
}

export function convertGraphQLItemUidAndTypeOutputToDto(input: GraphQLItemUidAndTypeOutput): ItemUidAndTypeDto {
  return {
    uid: input.Uid,
    itemType: convertGraphQLItemTypeToEnum(input.ItemType),
  }
}

export function convertLinkGraphqlToDto(input: GraphQLLinkOutput): DataLinkDto {
  return {
    uid: input.Uid,
    itemUid: input.ItemUid,
    typeId: input.TypeId,
    parentUid: input.ParentUid,
    linkedItemKey: input.LinkedItemKey,
    linkedItemVersion: input.LinkedItemVersion,
    linkedItemName: input.LinkedItemName,
    order: input.Order,
    operator: input.Operator,
    value: input.Value,
    validTo: input.ValidTo ? new Date(input.ValidTo ) : null,
    properties: input.Properties?.map(item => {
      return convertLinkPropertyGraphqlToDto(item)
    })
  }
}

export function convertLinkPropertyGraphqlToDto(input: GraphQLLinkPropertyOutput): DataPropertyDto {
  return {
    uid: input.Uid,
    itemUid: input.ItemUid,
    value: input.Value,
    language: input.Language
  }
}

export function convertGraphQLFieldTypeToEnum(input: GraphQLFieldTypeEnum):FieldTypeEnum {

  switch(input){
    case GraphQLFieldTypeEnum.text:
      return FieldTypeEnum.text;
    case GraphQLFieldTypeEnum.chip:
      return FieldTypeEnum.chip;
    case GraphQLFieldTypeEnum.dropdown:
      return FieldTypeEnum.dropdown;
    case GraphQLFieldTypeEnum.multiselect:
      return FieldTypeEnum.multiselect;
    case GraphQLFieldTypeEnum.number:
      return FieldTypeEnum.number;
    case GraphQLFieldTypeEnum.table:
      return FieldTypeEnum.table;
    case GraphQLFieldTypeEnum.toggle:
      return FieldTypeEnum.toggle;
    case GraphQLFieldTypeEnum.tpc:
      return FieldTypeEnum.tpc;
    case GraphQLFieldTypeEnum.userFunction:
      return FieldTypeEnum.userFunction;
  }
}


export function convertGraphQLItemTypeToEnum(input: GraphQLItemTypeEnum):ItemType {

  switch(input){
    case GraphQLItemTypeEnum.unsupported:
      return ItemType.Unsupported;
    case GraphQLItemTypeEnum.testCase:
      return ItemType.TestCase;
    case GraphQLItemTypeEnum.testSuite:
      return ItemType.TestSuite;
    case GraphQLItemTypeEnum.testAnalysis:
      return ItemType.TestAnalysis;
    case GraphQLItemTypeEnum.testCoverage:
      return ItemType.TestCoverage;
    case GraphQLItemTypeEnum.testSpecification:
      return ItemType.TestSpecification;
  }
}