<div class="linked-item-search-table" [ngClass]="{ 'disabled': locked }" >
  <p-table
    [value]="field.options"
    [scrollable]="true"
    scrollHeight="350px"
    selectionMode="single"
    (onRowSelect)="onRowSelect($event)"
    [(selection)]="selectedItem"
    [rowsPerPageOptions]="[10, 20, 50]" 
    [paginator]="true"
    [rows]="20"
    currentPageReportTemplate="{first} to {last} of {totalRecords}"
    [showCurrentPageReport]="true"
    dataKey="key"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 15%" >ItemNo</th>
        <th>Name</th>
        <th *ngIf="isGitLabField"> Project name</th>
      </tr>
      <tr>
        <th> 
          <p-columnFilter type="text" field="data.itemNo" matchMode="contains" showMenu="false" showClearButton="false"></p-columnFilter>
        </th>
        <th *ngIf="!isGitLabField"> 
          <p-columnFilter type="text" field="data.name" matchMode="contains"></p-columnFilter>
        </th>
        <th *ngIf="isGitLabField">
          <p-columnFilter type="text" field="data.name" matchMode="contains"></p-columnFilter>
        </th>
        <th *ngIf="isGitLabField">
          <p-columnFilter type="text" field="data.project.name" matchMode="contains"></p-columnFilter>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr [pSelectableRow]="item">
        <td>{{ item.data.itemNo }}</td>
        <td>{{ item.value }}</td>
        <td *ngIf="isGitLabField">{{ item.data.project?.name  }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>