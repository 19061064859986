export namespace TestCasesActions {
  export class SetAllTestCases {
    static readonly type = '[TestCases] SetAllTestCases';
    constructor() {}
  }
  export class SetOrgGroupTestCases {
    static readonly type = '[TestCases] SetOrgGroupTestCases';
    constructor(public orgGroupId: string) {}
  }

  export class DeleteTestCase {
    static readonly type = '[TestCases] DeleteTestCase';
    constructor(public testCaseUid: string, public regBy: string) {}
  }
  
  export class SetUpdateNeeded {
    static readonly type = '[TestCases] SetUpdateNeeded';
    constructor(public updateNeeded: boolean) {}
  }
}
