import { Component, Input, EventEmitter, OnInit, Output, SimpleChanges } from '@angular/core';
import { RequirementDto } from 'src/app/models/dto/polarion/requirementDto';
import { SpecificationDto } from 'src/app/models/dto/polarion/specificationDto';
import { polarion } from 'src/environments/environment';

@Component({
  selector: 'app-connected-polarion-items',
  templateUrl: './connected-polarion-items.component.html',
  styleUrls: ['./connected-polarion-items.component.scss']
})
export class ConnectedPolarionItemsComponent implements OnInit {
  @Input() mainPolarionItem: RequirementDto | SpecificationDto;
  @Input() connectedPolarionItems: RequirementDto[] | SpecificationDto[];
  @Input() isRequirement: boolean;
  @Output() itemsSelectedEvent = new EventEmitter<RequirementDto[]>();

  public polarionItemUrl = polarion.itemWithRevisionUrl;

  selectedItems: RequirementDto[] | SpecificationDto[] = [];

  mainItemTypeName: string;
  textMessage: string;
  
  constructor() { }

  ngOnInit(): void {
    this.setTexts();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setTexts();
  }

  private setTexts() {
    if(this.isRequirement) {
      this.textMessage = 'Linked requirement exists in the following specifications. Select the specification you want to add to Test Conditions for this Test Analysis. This link is not currently necessary for cybersecurity testing purposes.';
      this.mainItemTypeName = 'Polarion Requirement';
    }
    else {
      this.mainItemTypeName = 'Polarion Specification';
      this.textMessage = 'Linked specification contains the following requirements. Select one requirement you want to link to this Test Analyze.'
    }
  }

  onRowSelect(event) {
    if(this.isRequirement)
      return;

    let selectedRequirement = event.data;
    this.selectedItems = [];
    this.selectedItems.push(selectedRequirement);
  }

  addConnectedItems() {
    this.itemsSelectedEvent.emit(this.selectedItems);
    this.closeModal();
  }

  closeModal() {
    var modal;
    this.selectedItems = [];

    if(this.isRequirement)
      modal = document.getElementById("connected-items-modal-req")
    else 
      modal = document.getElementById("connected-items-modal-spec");

    modal.classList.remove("show");
    modal.classList.add("hide");
  }
}
