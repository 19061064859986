import { TreeNode } from "primeng/api";
import { Fpc } from "src/app/models/dto/fpc";
import { FpcFamily } from "src/app/models/dto/fpc-family";
import { v4 as uuidv4 } from 'uuid';
import { areOfSameFpcFamily } from "../helpers/patternHelper";

export class FpcMapper {
    public static mapToString(fpcTree: TreeNode<Fpc>[]): string {
        let fpcExpressions = [];
        fpcTree.forEach(fpc => {
            let notCondition = fpc.data.notCondition ? '!' : '';
            let fpcExpression = notCondition + fpc.data.fpcCode;

            if(fpc.children?.length > 0)
                fpcExpression = "("  + fpcExpression + " AND " + this.mapToString(fpc.children) + ")";

            fpcExpressions.push(fpcExpression);
        })

        if(fpcExpressions.length > 1)
            return '(' + fpcExpressions.join(" OR ") + ')'
        else
            return fpcExpressions.join(" OR ");
    }

    public static mapToTreeWithoutNames(fpcExpression: string): TreeNode<Fpc>[] {
        let tokens = this.tokenize(fpcExpression);
        let result = this.parseTokens(tokens, []);
        return result;
    }

    public static mapToTreeWithNames(fpcExpression: string, allFpcFamilies: FpcFamily[]): TreeNode<Fpc>[] {
        let tokens = this.tokenize(fpcExpression);
        let result = this.parseTokens(tokens, allFpcFamilies);
        return result;
    }

    private static tokenize(input: string): string[] {
        const tokens: string[] = [];
        let i = 0;
        while (i < input.length) {
            if (input[i] === ' ') {
                i++;
            } else if (input[i] === '(' || input[i] === ')') {
                tokens.push(input[i]);
                i++;
            } else {
                let j = i;
                while (j < input.length && ![' ', '(', ')'].includes(input[j])) {
                    j++;
                }
                tokens.push(input.slice(i, j));
                i = j;
            }
        }
        return tokens;
    }

    private static parseTokens(tokens: string[], allFpcFamilies: FpcFamily[]): TreeNode<Fpc>[] {
        const stack: (TreeNode<Fpc> | string)[] = [];
        const operators: Set<string> = new Set(['AND', 'OR']);

        for (let token of tokens) {
            if (token === ')') {
                const children: TreeNode<Fpc>[] = [];
                let operator = '';
                while (stack.length) {
                    const top = stack.pop();
                    if (typeof top === 'string' && operators.has(top)) {
                        operator = top;
                    } else if (this.isTreeNode(top)) {
                        children.unshift(top as TreeNode);
                    } else if (top === '(') {
                        break;
                    }
                }
                if (operator === 'AND' && children.length > 1) {
                    // For A AND B, B will be a child of A
                    let parent = children[0];
                    for (let i = 1; i < children.length; i++) {
                        parent.children.push(children[i]);
                    }
                    stack.push(parent);
                } else if (operator === 'OR') {
                    // For A OR B, A nad B will be children of the same parent
                    for (let child of children) {
                        stack.push(child);
                    }
                } else {
                    stack.push(children[0]);
                }
            } else {
                stack.push(token.includes('FPC') ? this.buildTreeNode(token, allFpcFamilies) : token);
            }
        }

        let result: TreeNode<Fpc>[] = [];
        while (stack.length) {
            const top = stack.pop();
            if (this.isTreeNode(top)) 
                result.unshift(top as TreeNode);
        }

        return result;
    }

    private static buildTreeNode(fpcString: string, allFpcFamilies: FpcFamily[]): TreeNode<Fpc> {
        let result: TreeNode<Fpc> = {};

        let fpcCode = fpcString.startsWith('!') ? fpcString.slice(1) : fpcString;
        let fpcName = this.getNameForFpcCode(fpcCode, allFpcFamilies);
        let fpc: Fpc = { fpcCode: fpcCode, name: fpcName, notCondition: fpcString.startsWith('!')}

        result.data = fpc;
        result.key = uuidv4().toString(); // key must be unique, but fpcCode duplicates in tree are allowed
        result.label = fpc.name;
        result.children = [];
        result.expanded = true;
        return result;
    }

    private static getNameForFpcCode(fpcCode: string, allFpcFamilies: FpcFamily[]) : string{
        for (let fpcFamily of allFpcFamilies) {
            if(areOfSameFpcFamily(fpcFamily.fpcCode, fpcCode))
                return fpcFamily.variants.find(x => x.fpcCode == fpcCode)?.name;
        }

        return '';
    }

    private static  isTreeNode<T>(obj: any): obj is TreeNode<T> {
        return obj && typeof obj === 'object' && 'data' in obj && 'children' in obj && Array.isArray(obj.children);
    }
}