import { FormGroup } from '@angular/forms';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { TestSpecificationDto } from 'src/app/models/dto/testSpecificationDto';
import { v4 as uuidv4 } from 'uuid';

export class TestSpecificationFactory {

  public static create(originalItem: TestSpecificationDto, form: FormGroup): TestSpecificationDto {
    let result: TestSpecificationDto = {} as TestSpecificationDto;
    result.uid = originalItem ? originalItem.uid : uuidv4().toString();
    result.itemNo = originalItem?.itemNo;

    result.name = form.value[formControlConstants.Name] ?? '';
    result.label = form.value[formControlConstants.Label] ?? '';
    result.ownerIds = form.value[formControlConstants.TSpecOwner]?.map(x => x.key) ?? [];
    result.testAnalyses = form.value[formControlConstants.TSpecTestAnalysis] ?? [];

    //empty properties
    result.comment = '';
    result.lockedBy = '';
    result.createdBy = '';
    result.modifiedBy = '';
    result.approvalStatus = '';
    result.approvedForTestLevel = '';

    return result;
  }
}
