import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { UserFunctionsStateModel } from "./user-functions.state.model";
import { UserFunctionsActions } from "./user-functions.actions";
import { UserFunctionsService } from "src/app/services/api/user-functions.service";
import { UserFunction } from "src/app/models/dto/user-function-dto";

@State<UserFunctionsStateModel>({
    name: 'userFunctions',
    defaults: {
      userFunctions: [],
      userFunctionHierarchy: []
    },
  })
  @Injectable()
  export class UserFunctionsState{
    constructor(private userFunctionsService: UserFunctionsService) {}

    @Action(UserFunctionsActions.SetUserFunctions)
    SetUserFunctions(ctx: StateContext<UserFunctionsStateModel>) {
        const state = ctx.getState();
        //don't update state if we have already downloaded items 
        if(state.userFunctions !== undefined && state.userFunctions.length > 0) return;

        this.userFunctionsService.getUserFunctions().subscribe((userFunctions) => {
            ctx.patchState({userFunctions: userFunctions});
          });
    }

    @Action(UserFunctionsActions.SetUserFunctionHierarchy)
    SetUserFunctionHierarchy(ctx: StateContext<UserFunctionsStateModel>) {
        const state = ctx.getState();
        //don't update state if we have already downloaded items 
        if(state.userFunctionHierarchy !== undefined && state.userFunctionHierarchy.length > 0) return;

        this.userFunctionsService.getUserFunctionHierarchy().subscribe((userFunctionHierarchy) => {
            ctx.patchState({userFunctionHierarchy: userFunctionHierarchy});

            //populate ufs as well
            var onlyUfs = userFunctionHierarchy.map(x => { return {name: x.name, id: x.id, useCases: undefined} as UserFunction});
            ctx.patchState({userFunctions: onlyUfs});
          });
    }

    @Selector()
    static getUserFunctions(state: UserFunctionsStateModel) {
        return state.userFunctions;
    }

    @Selector()
    static getUserFunctionHierarchy(state: UserFunctionsStateModel) {
        return state.userFunctionHierarchy;
    }
  }