<p-toast ></p-toast>
<div class="title">
  <h2 *ngIf="testItem">{{ testItem.itemNo }}: {{ testItem.name }}</h2>
  <i class="pi lockIcon"
    *ngIf="testItem && testItem.lockedBy !== ''"
    pTooltip="Locked by {{ testItem.lockedBy }} 
              Since {{ testItem.lockedTime | date: 'dd.MM.yyyy HH:mm' }}"
    style="font-size: initial"
    [class]="testItem?.lockedBy
              ? 'checkedOutByYou pi-unlock'
              : 'checkedOutBySomeoneElse pi-lock' "
  ></i>
</div>

<div *ngIf="form" class="test-specification-page" [ngClass]="{'hide-form': this.hideForm}">
  <div class="test-specification-form">
    
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div>
          <div *ngIf="testItem && reviewVisable$ | async">
            <app-review-header [testItem]="testItem"></app-review-header>
          </div>
          <p-panel toggler="header" styleClass="panelHeader" [toggleable]="true" class="grid-column-panel">
            <ng-template pTemplate="header">
              <p class="boldText">General Information</p>
            </ng-template>
            <div class="panelContentWrapper">
              <div class="panelContent gridDisplay">
                <div *ngFor="let field of informationFields" class="field-section">
                  <app-dynamic-form-field [field]="field" [form]="form"
                  ></app-dynamic-form-field>
                </div>
              </div>
              <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
                <app-review-comments 
                  [itemUid]="testItem.uid"
                  [itemVersion]="testItem.version"
                  [commentGroup]="ReviewCommentGroup.TSP_General"
                  [approval]="approval">
                </app-review-comments>
              </div>
            </div>
          </p-panel>
        </div>

        <div>
            <p-panel toggler="header" styleClass="panelHeader" *ngIf="testAnalysisLinkField && (testAnalysisLinksLoadedToForm || addPageActive)" [toggleable]="true" class="link-panel">
              <ng-template pTemplate="header">
                <p class="boldText">Test Analyses</p>
              </ng-template>
              <div class="field-section">
                <app-linked-item-list  [field]="testAnalysisLinkField" [form]="form"
                [locked]="!addPageActive && testItem?.lockedBy !== loggedInUsername"></app-linked-item-list>
              </div>
              <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
                <app-review-comments 
                  [itemUid]="testItem.uid"
                  [itemVersion]="testItem.version"
                  [commentGroup]="ReviewCommentGroup.TSP_TestAnalyses"
                  [approval]="approval">
                </app-review-comments>
              </div>
            </p-panel>
        </div>
       
      <test-item-action [testItem]="testItem" [changes]="changes"  (checkOutTestItem)="checkOut()" 
        (unlockTestItem)="unlockItem()" class="footer" [actionButton]="actionButtons"  [toolTip]="checkOutBtnToolTip" [itemType]="itemType" ></test-item-action>
    </form>
  </div>
  <div class="test-specification-properties">
    <app-item-registration-info [item]="testItem"></app-item-registration-info>
  </div>

</div>

