export namespace GitLabScriptsActions {
  export class SetAllGitLabScripts {
    static readonly type = '[GitLabScripts] SetAllGitLabScripts';
    constructor() {}
  }

  export class SetAllGitLabScriptsForProject {
    static readonly type = '[GitLabScripts] SetAllGitLabScriptsForProject';
    constructor(public projectId: number) {}
  }
}
