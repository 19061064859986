<div class="filter-section" *ngIf="path === routes.TEST_SUITES || path === routes.TEST_CASES || path === routes.TEST_ANALYSIS || path === routes.TEST_SPECIFICATIONS || path === routes.TEST_COVERAGE"> 
    <b>Filter items</b>
  
    <div class="item-filters">
      <p-radioButton name="item-filter" [value]="0" [(ngModel)]="itemTableFilter" inputId="itemTableFilter1"
        (onClick)="getItems()"></p-radioButton>
      <label for="itemTableFilter1" class="ml-2">All</label>
      
      <p-radioButton name="item-filter" [value]="1" [(ngModel)]="itemTableFilter" inputId="itemTableFilter2"  *ngIf="path !== routes.TEST_COVERAGE"
        (onClick)="getItems()"></p-radioButton>
      <label for="itemTableFilter2" class="ml-2" *ngIf="path !== routes.TEST_COVERAGE">My Organization</label>
      
      <p-radioButton name="item-filter" [value]="2" [(ngModel)]="itemTableFilter" inputId="itemTableFilter3"
      (onClick)="getItems()"></p-radioButton>
      <label for="itemTableFilter3" class="ml-2">Created By Me</label>
    </div>
  </div>
  <div class="item-table">
    <p-table [value]="items"
      [scrollable]="true"
      [scrollHeight]="'calc(100vh - 310px)'"
      [paginator]="true"
      [rows]="20"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
      [rowsPerPageOptions]="[10, 20, 50]" 
      [sortField]="sortField"
      [sortOrder]="sortOrder"
      dataKey="uid">
      
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 6%; text-align: center !important;" pSortableColumn="itemNo">ItemNo<p-sortIcon field="itemNo" class="p-sortIcon"></p-sortIcon></th>
          <th style="width: 34%; text-align: left !important;" pSortableColumn="name">Name<p-sortIcon field="name" class="p-sortIcon"></p-sortIcon></th>
          <th style="width: 10%;" pSortableColumn="version">Version<p-sortIcon field="version"></p-sortIcon></th>
          <th style="width: 15%;" pSortableColumn="createdBy">Created By<p-sortIcon field="createdBy"></p-sortIcon></th>
          <th style="width: 10%;" pSortableColumn="creationTime">Creation Time <p-sortIcon field="creationTime"></p-sortIcon></th>
          <th style="width: 10%;" pSortableColumn="lockedBy">Locked<p-sortIcon field="lockedBy"></p-sortIcon></th>
          <th style="width: 15%;">Actions</th>
        </tr>
        <tr>
          <th>
            <p-columnFilter type="text" field="itemNo" matchMode="contains" showMenu="false" showClearButton="false"></p-columnFilter><!--Maybe add placeholder 'TSP-' or 'TC-'-->
          </th>
          <th style="text-align: left;">
            <p-columnFilter type="text" field="name" matchMode="contains"></p-columnFilter>
          </th>
          <th></th>
          <th> <p-columnFilter type="text" field="createdBy" matchMode="contains"></p-columnFilter></th>
          <th></th>
          <th></th>
          <th scope="col">
            <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" [routerLink]="addRoute" type="button"
              pTooltip="Add new item" tooltipPosition="bottom">
              <i class="pi pi-plus" style="font-size: initial"></i>
            </button> 
          </th>
        </tr>
      </ng-template>
      <ng-template #dataTemplate pTemplate="body" ngFor let-item [ngForOf]="items" [ngForTrackBy]="trackByFn" let-i="index" let-l="last">
        <tr>
          <td>{{ item.itemNo }}</td>
          <td style="text-align: left;">{{ item.name }}</td>
          <td>{{ item.version }}</td>
          <td>{{ item.createdBy?.split('@').length > 0 ? item.createdBy?.split('@')[0] : item.createdBy}}</td>
          <td>{{ item.creationTime | date: "dd.MM.yyyy HH:mm" }}</td>
          <td>        
            <div (click)="unlockItem(item)">
              <i class="pi pi-lock" *ngIf="item.lockedBy !== ''" pTooltip="Locked by {{ item.lockedBy }} 
                        Since {{ item.lockedTime | date: 'dd.MM.yyyy HH:mm' }}" style="font-size: initial" [class]="
                  item.lockedBy === username
                    ? 'checkedOutByYou pi-unlock'
                    : 'checkedOutBySomeoneElse pi-lock'
                "></i>
            </div>
          </td>
          <td>
            <button
              class="sdds-btn sdds-btn-secondary sdds-btn-sm"
              [routerLink]="item.uid"
            >
              <i class="pi pi-pencil" style="font-size: initial"></i>
            </button>
  
            <button id="delete{{ item.uid }}" class="sdds-btn sdds-btn-secondary sdds-btn-sm removeItem" (click)="canDeleteItem(item)">
              <i class="pi pi-trash" style="font-size: initial"></i>
            </button>      
          </td>
          <!--NoPermissionDelete popup-->
          <div>
            <sdds-modal size="sm"  id="deleteUnauthorized{{ item.uid }}">
              <h5 slot="sdds-modal-headline">Attention</h5>
              <p slot="sdds-modal-body">
                You do not have permission to delete '{{ item.name }}'.
              </p>
              <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md" (click)="closeModal('deleteUnauthorized', item.uid)">
                Back
              </button>
            </sdds-modal> 
          </div>
          <!--NoDelete if linked popup, best if extracted to new component-->
          <div>
            <sdds-modal size="sm"  id="deleteLinked{{ item.uid }}">
              <h5 slot="sdds-modal-headline">Attention</h5>
              <div slot="sdds-modal-body">
                <p>
                  This item is linked to the following items and cannot be deleted.
                </p>
                <p-table [value]="parentItems" [scrollable]="true" scrollHeight="250px" dataKey="uid"><!-- Should be moved to separate component? Duplicate to view-parent-item-->
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 20%;">Type</th>
                      <th style="width: 80%;">Name</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-item>
                    <tr>
                      <td style="width: 20%;">{{ itemType[item.typeName] }}</td>
                      <td style="width: 80%;">
                          <a [routerLink]="['/', getRoute(item.typeName), item.uid]">{{ item.name }}</a>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>            
              <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md" (click)="closeModal('deleteLinked', item.uid)">
                Back
              </button>
            </sdds-modal> 
          </div>

          <!--DeleteLockedItem popup-->
          <div>
            <sdds-modal size="sm"  id="deleteLocked{{ item.uid }}">
              <h5 slot="sdds-modal-headline">Attention</h5>
              <p slot="sdds-modal-body">
                This item is currently checked out by another user and cannot be deleted.
              </p>
              <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md" (click)="closeModal('deleteLocked', item.uid)">
                Back
              </button>
            </sdds-modal> 
          </div>

          <!--DeleteConfirmation popup-->
          <div>
            <sdds-modal size="sm"  id="deleteConfirmation{{ item.uid }}">
              <h5 slot="sdds-modal-headline">Delete confirmation</h5>
              <p slot="sdds-modal-body">
                Are you sure you want to delete '{{ item.itemNo }} : {{ item.name }}'?
              </p>
              <button slot="sdds-modal-actions" data-dismiss-modal (click)="deleteItem(item)"
                class="sdds-btn sdds-btn-danger sdds-btn-md">
                Delete
              </button>
              <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md" (click)="closeModal('deleteConfirmation', item.uid)">
                Cancel
              </button>
            </sdds-modal> 
          </div>
          
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="7">No items found.</td>
        </tr>
    </ng-template>
    </p-table>
  </div>