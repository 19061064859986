import { FormGroup } from '@angular/forms';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { InputTypeNameConstants } from 'src/app/constants/propertyConstants';
import { TestCaseAlternativeDto } from 'src/app/models/dto/testCaseAlternativeDto';
import { TestCaseDto } from 'src/app/models/dto/testCaseDto';
import { v4 as uuidv4 } from 'uuid';

export class TestCaseFactory {

  public static create(originalItem: TestCaseDto, form: FormGroup): TestCaseDto {
    let result: TestCaseDto = {} as TestCaseDto;
    result.uid = originalItem?.uid ?? uuidv4().toString();
    result.itemNo = originalItem?.itemNo;

    result.name = form.value[formControlConstants.Name] ?? '';
    result.label = form.value[formControlConstants.Label] ?? '';
    result.derivedFrom = form.value[formControlConstants.DerivedFrom]?.value ?? '';
    let detailLevel = form.value[formControlConstants.DetailLevel]?.value;
    result.detailLevel = detailLevel ? Number(detailLevel) : undefined;
    result.steps = this.constructSteps(form);
    result.description = form.value[formControlConstants.Description] ?? null;
    
    result.inputTypeName = form.value[formControlConstants.InputTypeName]?.key ?? '';

    let inputTypeValue = form.value[formControlConstants.InputTypeValues]
    result.inputTypeValues = (inputTypeValue == '' || inputTypeValue == null || inputTypeValue == undefined) 
        ? [] : (form.value[formControlConstants.InputTypeValues]?.map(x => x) ?? []);
    
    result.objectUnderTestType = form.value[formControlConstants.ObjectUnderTestType]?.value ?? '';
    result.restrictedViewAccess = form.value[formControlConstants.RestrictedViewAccess] ?? false;
    result.testType = form.value[formControlConstants.TestType]?.value ?? '';
    result.ownerIds = form.value[formControlConstants.TCOwner]?.map(x => x.key) ?? [];
    result.ecuIds = form.value[formControlConstants.TCEcu]?.map(x => x.value) ?? [];
    result.offboardComponentIds = form.value[formControlConstants.TCOffboardComponent]?.map(x => x.key) ?? [];
    result.productPropertyIds = form.value[formControlConstants.TCProductProperty]?.map(x => x.value) ?? [];
    result.userFunctions = form.value[formControlConstants.TCUserFunction] ?? []
    
    result.alternative = {} as TestCaseAlternativeDto;
    result.alternative.uid = originalItem?.alternative?.uid ?? uuidv4().toString();
    
    result.alternative.testGroups = form.value[formControlConstants.TCTestGroup]?.map(x => x.key) ?? [];
    result.alternative.testObjects = form.value[formControlConstants.TCTestObject]?.map(x => x.key) ?? [];
    result.alternative.environment = form.value[formControlConstants.Environment]?.value ?? '';
    result.alternative.fpc = form.value[formControlConstants.FPC] ?? '';
    result.alternative.tpc = form.value[formControlConstants.TPC] ?? '';
    result.alternative.gitLabScript = form.value[formControlConstants.TCGitLabScript]?.map(x => {
       return  {
        name: x.name,
        path: x.path,
        foreignSystemKey: x.foreignSystemKey
      }
    }) ?? [];
    
    //empty properties
    result.comment = '';
    result.lockedBy = '';
    result.createdBy = '';
    result.modifiedBy = '';
    result.approvalStatus = '';
    result.approvedForTestLevel = '';
    
    return result;
  }

  private static constructSteps(form: FormGroup) {
    let inputTypeName = form.value[formControlConstants.InputTypeName]?.key ?? '';
    if(inputTypeName == InputTypeNameConstants.PassFail)
        return form.value[formControlConstants.Steps] ?? [];

    return [];
  }
}
