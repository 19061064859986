export const PropertyNamesConstants = {
  Asil: 'ASIL',
  IrmaVersion: 'IrmaVersion',
  PolarionRequirement: 'PolarionRequirement',
  PolarionRequirementVersion: 'PolarionRequirementVersion',
  ProductProperty: "ProductProperty",
};

export const SpecificationsLabels: Map<string, string> = new Map([
  ['id-47234', 'C-BMS_SyRS_4_Scania'],
  ['id-48679', 'C-BMS_SyRS_5_MAN'],
  ['id-48610', 'C-BMS_SyRS_5_Scania'],
  ['id-51064', 'C-BMS_SyRS_6_MAN'],
  ['id-50094', 'C-BMS_SyRS_6_Scania'],
  ['id-54504', 'C-BMS_SyRS_7_MAN'],
  ['id-53088', 'C-BMS_SyRS_7_Scania'],
]);

export const InputTypeNameConstants = {
  PassFail: 'PassFail',
  YesNo: 'YesNo',
  RatingScale: 'RatingScale',
  Numeric: 'Numeric',
  Text: 'Text',
  SingleSelection: 'SingleSelection',
  MultipleSelection: 'MultipleSelection',
  OrderSelection: 'OrderSelection',
};
 

export const InputTypeOperatorAndValue = {
  PassFail: { operators: ['Ɐ', '=', '≠'], values: ["Passed", "Failed"]},
  YesNo: { operators: ['Ɐ', '=', '≠'], values: ["Yes", "No"]},
  RatingScale: { operators: ['Ɐ', '=', '≠', '>', '<'], values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']},
  Numeric: { operators: ['Ɐ', '=', '≠', '>', '<'], values: []},
  Text: { operators: ['Ɐ', '=', '≠', '>', '<'], values: []},
  SingleSelection: { operators: ['Ɐ', '=', '≠'], values: []},
  MultipleSelection: { operators: ['Ɐ', '=', '⊂'], values: []},
  OrderSelection: { operators: ['Ɐ'], values: []},
};
 