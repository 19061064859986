import { gql } from "apollo-angular";

export const GET_ECUS_QUERY = gql`query configuratorAllEcuss {
    configuratorAllEcus {
        Id
        Name
        ParentId
        EcuFamily
    }
  }
  `