import { Injectable } from '@angular/core';
import { OrganizationalGroupsActions } from 'src/app/store/organizationalGroups/organizationalGroups.actions';
import { OrganizationalGroupsState } from 'src/app/store/organizationalGroups/organizationalGroups.state';
import { OrganizationalGroup } from '../models/dto/organizationalGroup';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  loggedInOrganizationalGroup: OrganizationalGroup;
  constructor(private store: Store) { }

  checkPermission(loggedInUsername : string, ownerGroupUIDs: string[], testedByGroupUIDs?: string[]): Observable<boolean>{
    this.store.dispatch(new OrganizationalGroupsActions.SetOrganizationalGroupByUsername(loggedInUsername));

    return this.store.select<OrganizationalGroup>(OrganizationalGroupsState.getLoggedInOrganizationalGroup).pipe(
      map(orgGroup => {
          this.loggedInOrganizationalGroup = orgGroup;
          if(!testedByGroupUIDs)
            return ownerGroupUIDs.includes(this.loggedInOrganizationalGroup.uid);
          else
            return ownerGroupUIDs.includes(this.loggedInOrganizationalGroup.uid) || testedByGroupUIDs.includes(this.loggedInOrganizationalGroup.uid);
      })
    );
  }

  checkPermissionCreator(loggedInUsername : string, createdBy: string):boolean{
    return loggedInUsername && createdBy ? loggedInUsername.toLowerCase() === createdBy.toLowerCase() : false;
  }
}
