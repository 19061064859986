<p-toast></p-toast>
<div class="title">
  <h2 *ngIf="testItem">{{ testItem.itemNo }}: {{ testItem.name }}</h2>
  <i
    class="pi lockIcon"
    *ngIf="testItem && testItem.lockedBy !== ''"
    pTooltip="Locked by {{ testItem.lockedBy }} 
              Since {{ testItem.lockedTime | date : 'dd.MM.yyyy HH:mm' }}"
    style="font-size: initial"
    [class]="
      testItem?.lockedBy
        ? 'checkedOutByYou pi-unlock'
        : 'checkedOutBySomeoneElse pi-lock'
    "
  ></i>
</div>

<div
  *ngIf="form"
  class="test-suite-page"
  [ngClass]="{ 'hide-form': this.hideForm }"
>
  <div class="test-suite-form">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div>
        <div *ngIf="testItem && approval && reviewVisable$ | async">
          <app-review-header
            [testItem]="testItem"
            [approval]="approval"
            (enableComments)="handleEnableReviewComments($event)"
          ></app-review-header>
        </div>
        <p-panel
          toggler="header"
          styleClass="panelHeader"
          [toggleable]="true"
          class="grid-column-panel"
        >
          <ng-template pTemplate="header">
            <p class="boldText">General Information</p>
          </ng-template>
          <div class="panelContentWrapper">
            <div class="panelContent gridDisplay">
              <div
                *ngFor="let field of informationFields"
                class="field-section"
              >
                <app-dynamic-form-field
                  [field]="field"
                  [form]="form"
                ></app-dynamic-form-field>
              </div>
            </div>
            <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
              <app-review-comments
                [itemUid]="testItem.uid"
                [itemVersion]="testItem.version"
                [commentGroup]="ReviewCommentGroup.TS_General"
                [enableComments]="enableReviewComments"
                [approval]="approval"
              >
              </app-review-comments>
            </div>
          </div>
        </p-panel>
      </div>

      <div>
        <p-panel
          toggler="header"
          styleClass="panelHeader"
          [toggleable]="true"
          class="grid-column-panel"
        >
          <ng-template pTemplate="header">
            <p class="boldText">Options</p>
          </ng-template>
          <div class="panelContentWrapper">
            <div class="panelContent gridDisplay">
              <div *ngFor="let field of optionFields" class="field-section">
                <app-dynamic-form-field
                  [field]="field"
                  [form]="form"
                ></app-dynamic-form-field>
              </div>
            </div>
            <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
              <app-review-comments
                [itemUid]="testItem.uid"
                [itemVersion]="testItem.version"
                [commentGroup]="ReviewCommentGroup.TS_Options"
                [enableComments]="enableReviewComments"
                [approval]="approval"
              >
              </app-review-comments>
            </div>
          </div>
        </p-panel>
      </div>

      <div>
        <p-panel
          toggler="header"
          [toggleable]="true"
          *ngIf="
            testCaseLinkField && (testCaseLinksLoadedToForm || addPageActive)
          "
          class="item-links link-panel"
        >
          <ng-template pTemplate="header">
            <p class="boldText">Test Cases</p>
          </ng-template>
          <div class="panelContent">
            <app-linked-item-hierarchy-list
              [field]="testCaseLinkField"
              [form]="form"
              [items]="testCaseLinkField.options"
              [locked]="
                !addPageActive && testItem?.lockedBy !== loggedInUsername
              "
            ></app-linked-item-hierarchy-list>
          </div>
          <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
            <app-review-comments
              [itemUid]="testItem.uid"
              [itemVersion]="testItem.version"
              [commentGroup]="ReviewCommentGroup.TS_TestCases"
              [enableComments]="enableReviewComments"
              [approval]="approval"
            >
            </app-review-comments>
          </div>
        </p-panel>

        <p-panel
          toggler="header"
          [toggleable]="true"
          *ngIf="
            testSuiteLinkField && (testSuiteLinksLoadedToForm || addPageActive)
          "
          class="item-links link-panel"
        >
          <ng-template pTemplate="header">
            <p class="boldText">Test Suites</p>
          </ng-template>
          <div class="panelContent">
            <app-linked-item-hierarchy-list
              [field]="testSuiteLinkField"
              [form]="form"
              [items]="testSuiteLinkField.options"
              [locked]="
                !addPageActive && testItem?.lockedBy !== loggedInUsername
              "
            ></app-linked-item-hierarchy-list>
          </div>
          <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
            <app-review-comments
              [itemUid]="testItem.uid"
              [itemVersion]="testItem.version"
              [commentGroup]="ReviewCommentGroup.TS_TestSuites"
              [enableComments]="enableReviewComments"
              [approval]="approval"
            >
            </app-review-comments>
          </div>
        </p-panel>
      </div>

      <div>
        <p-panel
          toggler="header"
          [toggleable]="true"
          *ngIf="fpcLinkField && (fpcLinksLoadedToForm || addPageActive)"
          class="item-links link-panel"
        >
          <ng-template pTemplate="header">
            <p class="boldText">FPC conditions</p>
          </ng-template>
          <div class="panelContent">
            <app-fpc
              [field]="fpcLinkField"
              [form]="form"
              [items]="fpcLinkField.options"
              [locked]="!addPageActive && testItem?.lockedBy !== loggedInUsername"
              [fpcsLoaded]="fpcDataLoaded"
            ></app-fpc>
          </div>

          <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
            <app-review-comments
              [itemUid]="testItem.uid"
              [itemVersion]="testItem.version"
              [commentGroup]="ReviewCommentGroup.TS_Fpc"
              [enableComments]="enableReviewComments"
              [approval]="approval"
            >
            </app-review-comments>
          </div>
        </p-panel>
      </div>

      <div>
        <p-panel
          toggler="header"
          styleClass="panelHeader"
          [toggleable]="true"
          [collapsed]="false"
          *ngIf="form"
        >
          <ng-template pTemplate="header">
            <p class="boldText">Description</p>
          </ng-template>
          <div class="panelContentWrapper">
            <div class="panelContent">
              <div class="sdds-on-white-bg m-right">
                <app-text-editor
                  [description]="testItem?.description"
                  [form]="form"
                  [readOnly]="
                    !addPageActive && testItem?.lockedBy !== loggedInUsername
                  "
                ></app-text-editor>
              </div>
            </div>
            <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
              <app-review-comments
                [itemUid]="testItem.uid"
                [itemVersion]="testItem.version"
                [commentGroup]="ReviewCommentGroup.TS_Description"
                [enableComments]="enableReviewComments"
                [approval]="approval"
              >
              </app-review-comments>
            </div>
          </div>
        </p-panel>
      </div>

      <test-item-action
        [testItem]="testItem"
        [changes]="changes"
        (checkOutTestItem)="checkOut()"
        (unlockTestItem)="unlockItem()"
        class="footer"
        [actionButton]="actionButtons"
        [toolTip]="checkOutBtnToolTip"
        [itemType]="itemType"
      ></test-item-action>
    </form>
  </div>
  <div class="test-suite-properties">
    <app-item-registration-info [item]="testItem"></app-item-registration-info>
  </div>
</div>
