import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, TreeNode } from 'primeng/api';
import { LinkValidationService } from 'src/app/services/link-validation.service';
import { formField } from 'src/app/models/formField';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { TestItemHierarchyLinkDto } from 'src/app/models/dto/test-item-hierarchy-link-dto';
import { FieldTypeEnum } from 'src/app/models/enums/fieldTypeEnum';
import { InputTypeNameConstants, InputTypeOperatorAndValue } from 'src/app/constants/propertyConstants';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { RouteConstants } from 'src/app/constants/routeConstants';
import { TestCaseApiService } from 'src/app/services/api/test-case-api.service';

@Component({
  selector: 'app-linked-item-hierarchy-list',
  templateUrl: './linked-item-hierarchy-list.component.html',
  styleUrls: ['./linked-item-hierarchy-list.component.scss'],
  providers: [MessageService]
})
export class LinkedItemHierarchyListComponent implements OnInit {
  @Input() field!: formField;
  @Input() form!: FormGroup;
  @Input() locked: boolean;

  linkedItemsTree: TreeNode<TestItemHierarchyLinkDto>[] = [];
  selectedNode: TreeNode<TestItemHierarchyLinkDto>;

  hasOperatorAndValueOnLink: boolean;
  showVersion: boolean = false;

  operatorField: formField;
  operatorValueField: formField;
  linkOperatorFormGroup: FormGroup = new FormGroup({
    operator: new FormControl('', Validators.required),
    operatorValue: new FormControl([], Validators.required)
  });

  FormControlConstants = formControlConstants;
  RouteConstants = RouteConstants;
  
  constructor(private linkValidationService: LinkValidationService,
    private messageService: MessageService,
    private testCaseSevice: TestCaseApiService
    ) {}

  //todo - implement order

  ngOnInit(): void {
    if (!this.field || !this.form)
      return; 

    this.hasOperatorAndValueOnLink = this.field.name === formControlConstants.TSTestCase;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.field || !this.form)
      return; 

    this.hasOperatorAndValueOnLink = this.field.name === formControlConstants.TSTestCase;
    
    let value = this.form.controls[this.field.name]?.value;
    if (value) 
      this.populateTree(value);
  }

  populateTree(parentLinks: TestItemHierarchyLinkDto[]) {
    this.linkedItemsTree = [];
    parentLinks.forEach(parentLink => {
      let parentNode = this.convertItemLinkDtoToNode(parentLink);
      this.linkedItemsTree.push(parentNode);
      this.attachChildren(parentNode);
    });
    this.showVersion = true
  }

  attachChildren(parentNode:TreeNode<TestItemHierarchyLinkDto>) {
    let childern = parentNode.data.childrenTestItemLinks;
    childern.forEach(child => {
      let childNode = this.convertItemLinkDtoToNode(child);
      if(!parentNode.children)
        parentNode.children = [];
      parentNode.children.push(childNode);
      parentNode.expanded = true;
      this.attachChildren(childNode);
    })
  }

  addItemToRoot(item: BaseTestItemDto) {
    this.linkValidationService.validateLink(item.uid, this.getKeysFromAllNodes(this.linkedItemsTree), this.field.name).then(errorMessage => {
      if(errorMessage) {
        this.showErrorToast(errorMessage);
        return;
      } 

      this.linkedItemsTree = this.linkedItemsTree.concat([]);
      this.linkedItemsTree.push(this.convertItemLinkDtoToNode(this.convertTestItemDtoToItemHierarchyLinkDto(item)));
      
      this.form.patchValue({ [this.field.name]: this.convertNodesToTestCaseLinkDtos(this.linkedItemsTree) });
      if(this.form.value[this.field.name].length != 0)
        this.showVersion = true;
    });  
  }

  addItemToSelectedNode(item: BaseTestItemDto) {
    this.closeModal('add-operator' + this.selectedNode.key);
    
    this.linkValidationService.validateLink(item.uid, this.getKeysFromAllNodes(this.linkedItemsTree), this.field.name).then(errorMessage => {
      if(errorMessage) {
        this.showErrorToast(errorMessage);

        //without this it won't opet the popup again - don't understand why
        this.linkedItemsTree = this.linkedItemsTree.concat([]);
        return;
      } 

      let childItem = this.convertTestItemDtoToItemHierarchyLinkDto(item);

      if(!this.selectedNode.data.childrenTestItemLinks)
        this.selectedNode.data.childrenTestItemLinks = [];
      this.selectedNode.data.childrenTestItemLinks.push(childItem);

      if(this.linkOperatorFormGroup.valid && this.hasOperatorAndValueOnLink){
        childItem.operator = this.linkOperatorFormGroup.controls.operator.value?.key?.toString();
        if(this.linkOperatorFormGroup.controls.operatorValue.value instanceof Array){
          childItem.value = this.linkOperatorFormGroup.controls.operatorValue.value;
        } else  {
          childItem.value.push(this.linkOperatorFormGroup.controls.operatorValue.value?.key === undefined ? this.linkOperatorFormGroup.controls.operatorValue.value: this.linkOperatorFormGroup.controls.operatorValue.value.key.toString());
        }
      }
      
      //make a copy of array because html is stupid
      this.linkedItemsTree = this.linkedItemsTree.concat([]);

      if(!this.selectedNode.children)
        this.selectedNode.children = [];

      let newNode = this.convertItemLinkDtoToNode(childItem);
      this.selectedNode.children.push(newNode);

      this.selectedNode.expanded = false;
      this.selectedNode.expanded = true;

      this.form.patchValue({ [this.field.name]: this.convertNodesToTestCaseLinkDtos(this.linkedItemsTree) });
    });  
  }

  private showErrorToast(message: string) {
    this.messageService.add({severity:'error', summary: 'Not allowed!', detail: message, icon: 'pi-times'});
  }

  removeItem(node) {
    //make a copy of array because html is stupid
    this.linkedItemsTree = this.linkedItemsTree.concat([]);

    if(!node.parent) {
      let treeIndex = this.linkedItemsTree.indexOf(node);
      this.linkedItemsTree.splice(treeIndex, 1);
    }
    else {
      let siblingNodes = node.parent.children;
      siblingNodes.splice(siblingNodes.indexOf(node), 1);

      var siblingsData = node.parent.data.childrenTestItemLinks;
      var toDeleteChild = siblingsData.find(x => x.testItemUid == node.key);
      siblingsData.splice(siblingsData.indexOf(toDeleteChild), 1);
    }
    this.linkedItemsTree = this.linkedItemsTree.concat([]);
    this.form.patchValue({ [this.field.name]: this.convertNodesToTestCaseLinkDtos(this.linkedItemsTree) });
    if(this.form.value[this.field.name].length == 0)
      this.showVersion = false;
  }

  addOrEditLink(itemNode, isAdd: boolean){
    let modelId = "";
    if(isAdd){
      this.selectedNode = itemNode;
      modelId = 'add-operator' + itemNode.key;
    } else {
      this.selectedNode = itemNode.parent;
      modelId = 'edit-operator' + itemNode.node.key;
    }


    if(!this.hasOperatorAndValueOnLink && this.field.name == formControlConstants.TSTestCase)
      return;

    this.testCaseSevice.getByUid(this.selectedNode.key)
        .subscribe(result => {
          if(!result.success)
            return;
          let inputTypeProperty = result.returnValue.inputTypeName;
          let inputTypeValue = result.returnValue.inputTypeValues;

          this.operatorField = ({
            name: 'operator',
            label: "Operator",
            isRequired: true,
            fieldType: FieldTypeEnum.dropdown,
            category: '',
            defaultValue: '',
            order: 0,
            tooltip: '',
            isReadOnly: false,
            isMultiValue: false,
            hasHierarchyValues: false,
          });

          this.operatorValueField = ({
            name: 'operatorValue',
            label: "Value",
            isRequired: true,
            fieldType: FieldTypeEnum.dropdown,
            category: '',
            defaultValue: '',
            order: 1,
            tooltip: '',
            isReadOnly: false,
            isMultiValue: false,
            hasHierarchyValues: false,
          });

          switch(inputTypeProperty){
            case InputTypeNameConstants.PassFail:
              this.operatorField.options = InputTypeOperatorAndValue.PassFail.operators.map(x => ({key: x, value: x}));
              this.operatorValueField.options = InputTypeOperatorAndValue.PassFail.values.map(x => ({key: x, value: x}));
              break;
            case InputTypeNameConstants.YesNo:
              this.operatorField.options = InputTypeOperatorAndValue.YesNo.operators.map(x => ({key: x, value: x}));
              this.operatorValueField.options = InputTypeOperatorAndValue.YesNo.values.map(x => ({key: x, value: x}));
              break;
            case InputTypeNameConstants.RatingScale:
              this.operatorField.options = InputTypeOperatorAndValue.RatingScale.operators.map(x => ({key: x, value: x}));
              this.operatorValueField.options = InputTypeOperatorAndValue.RatingScale.values.map(x => ({key: x, value: x}));
              break;
            case InputTypeNameConstants.Text:
              this.operatorField.options = InputTypeOperatorAndValue.Text.operators.map(x => ({key: x, value: x}));
              this.operatorValueField = ({
                name: 'operatorValue',
                label: "Value",
                isRequired: true,
                fieldType: FieldTypeEnum.text,
                category: '',
                defaultValue: '',
                order: 1,
                tooltip: '',
                isReadOnly: false,
                isMultiValue: false,
                hasHierarchyValues: false,
              });
  
              break;
            case InputTypeNameConstants.Numeric:
              this.operatorField.options = InputTypeOperatorAndValue.Numeric.operators.map(x => ({key: x, value: x}));
              this.operatorValueField = ({
                name: 'operatorValue',
                label: "Value",
                isRequired: true,
                fieldType: FieldTypeEnum.number,
                category: '',
                defaultValue: '',
                order: 1,
                tooltip: '',
                isReadOnly: false,
                isMultiValue: false,
                hasHierarchyValues: false,
              });
              break;
            case InputTypeNameConstants.SingleSelection:
              this.operatorField.options = InputTypeOperatorAndValue.SingleSelection.operators.map(x => ({key: x, value: x}));
              this.operatorValueField.options = inputTypeValue.map(x => ({key: x, value: x}));
              break;
            case InputTypeNameConstants.MultipleSelection:
              this.operatorField.options = InputTypeOperatorAndValue.MultipleSelection.operators.map(x => ({key: x, value: x}));
              this.operatorValueField.fieldType = FieldTypeEnum.multiselect;
              this.operatorValueField.options = inputTypeValue.map(x => ({key: x, value: x}));
              break;
            case InputTypeNameConstants.OrderSelection:
              this.operatorField.options = InputTypeOperatorAndValue.OrderSelection.operators.map(x => ({key: x, value: x}));
              this.operatorValueField.options = inputTypeValue.map(x => ({key: x, value: x}));
              break;
          }
          //todo - first check if empty option is needed
          //add empty option
          this.operatorField.options?.unshift({ key: '', value: '' });
          if(inputTypeProperty !== InputTypeNameConstants.MultipleSelection)
            this.operatorValueField.options?.unshift({ key: '', value: '' });

          if(isAdd){
            this.linkOperatorFormGroup.reset({operator: '', operatorValue: ''});
          } else {
            let item = itemNode.node.data;
            this.linkOperatorFormGroup.get('operator').setValue({ key: item.operator, value: item.operator });
            if(inputTypeProperty === InputTypeNameConstants.Text || inputTypeProperty === InputTypeNameConstants.Numeric || inputTypeProperty === InputTypeNameConstants.MultipleSelection)
              this.linkOperatorFormGroup.get('operatorValue').setValue(item.value);
            else
              this.linkOperatorFormGroup.get('operatorValue').setValue({ key: item.value, value: item.value });
          }
          
          this.showModal(modelId);
      });
  }

  updateLinkOperator(itemToUpdate: TestItemHierarchyLinkDto){
    if(!this.linkOperatorFormGroup.valid){
      this.linkOperatorFormGroup.markAllAsTouched();
      return;
    }

    this.closeModal('edit-operator' + itemToUpdate.testItemUid);

    if(this.hasOperatorAndValueOnLink){
      itemToUpdate.operator = this.linkOperatorFormGroup.controls.operator.value.key.toString();
      itemToUpdate.value = [];
      if(this.operatorValueField.fieldType === FieldTypeEnum.text || this.operatorValueField.fieldType === FieldTypeEnum.number)
        itemToUpdate.value.push(this.linkOperatorFormGroup.controls.operatorValue.value);
      else if (this.operatorValueField.fieldType === FieldTypeEnum.multiselect)
        itemToUpdate.value = this.linkOperatorFormGroup.controls.operatorValue.value;
      else
        itemToUpdate.value.push(this.linkOperatorFormGroup.controls.operatorValue.value.key);
    }

    this.selectedNode.expanded = false;
    this.selectedNode.expanded = true;

    this.form.patchValue({ [this.field.name]: this.convertNodesToTestCaseLinkDtos(this.linkedItemsTree) });
  }

  private convertItemLinkDtoToNode(source: TestItemHierarchyLinkDto) {
    if(!source)
      return undefined; 

    let node: TreeNode<TestItemHierarchyLinkDto> = {
      key:  source.testItemUid,
      label: source.name,
      data: source,
    };

    return node;
  }

  private convertTestItemDtoToItemHierarchyLinkDto(item: BaseTestItemDto) {
    if(!item)
      return undefined; 

    let result: TestItemHierarchyLinkDto = {
      testItemUid: item.uid,
      name: item.name,
      itemNo: item.itemNo,
      version: item.version,
      operator: '',
      value: [],
      childrenTestItemLinks: []
    }

    return result;
  }

  private convertNodesToTestCaseLinkDtos(nodes: TreeNode<TestItemHierarchyLinkDto>[]) {
    let result: TestItemHierarchyLinkDto[] = [];
    nodes?.forEach(node => {
      if(node && node.data) 
        result.push(node.data)
    });
    return result;
  }

  private getKeysFromAllNodes(tree: TreeNode<TestItemHierarchyLinkDto>[]) {
    let result: string[] = [];

    tree.forEach(node => {
      result.push(node.key);
      let children = [];
      node.children?.forEach(child => children = children.concat(this.getKeysFromAllNodes([child])));
      result = result.concat(children);
    })

    return result;
  }

  private showModal(id: string){
    var modal = document.getElementById(id);
    modal.classList.remove("hide");
    modal.classList.add("show");
  }

  private closeModal(id: string){
    var modal = document.getElementById(id);
    modal.classList.remove("show");
    modal.classList.add("hide");
  }
}
