import { gql } from "apollo-angular";


export const GET_TEST_LEVELS_QUERY = gql`query configuratorAllTestLevels {
    configuratorAllTestLevels {
        Id
        Name
    }
  }
  `
  