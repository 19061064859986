export namespace TestSuitesActions {
  export class SetAllTestSuites {
    static readonly type = '[TestSuites] SetAllTestSuites';
    constructor() {}
  }
  export class SetOrgGroupTestSuites {
    static readonly type = '[TestSuites] SetOrgGroupTestSuites';
    constructor(public orgGroupId: string) {}
  }
  export class DeleteTestSuite {
    static readonly type = '[TestSuites] DeleteTestSuite';
    constructor(public testSuiteUid: string, public regBy: string) {}
  }

  export class SetUpdateNeeded {
    static readonly type = '[TestSuites] SetUpdateNeeded';
    constructor(public updateNeeded: boolean) {}
  }
}
