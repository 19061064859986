import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approval-requests',
  templateUrl: './approval-requests.component.html',
  styleUrls: ['./approval-requests.component.scss'],
})
export class ApprovalRequestsComponent implements OnInit {
  items: any[] = [];

  constructor() {}

  ngOnInit(): void {}
}
