import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequirementApiService } from 'src/app/services/api/requirement-api.service';
import { formField } from 'src/app/models/formField';
import { RequirementDto } from 'src/app/models/dto/polarion/requirementDto';
import { SpecificationDto } from 'src/app/models/dto/polarion/specificationDto';
import { SpecificationApiService } from 'src/app/services/api/specification-api.service';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { polarion } from 'src/environments/environment';
import { polarionIdRegexPattern } from 'src/app/services/helpers/patternHelper';

@Component({
  selector: 'app-linked-requirements',
  templateUrl: './linked-requirements.component.html',
  styleUrls: ['./linked-requirements.component.scss'],
})
export class LinkedRequirementsComponent implements  OnInit, OnChanges {
  @Input() field!: formField;
  @Input() form!: FormGroup;
  @Input() locked: boolean;

  public requirementPolarionUrl = polarion.itemWithRevisionUrl;


  items: RequirementDto[] = [];
  formCopy = this.form;//investigate

  addedRequirement: RequirementDto;
  connectedSpecifications: SpecificationDto[];
  
  errorMessage: string;
  errorMessageShown: boolean = false;

  constructor(
    private requirementApiService: RequirementApiService, 
    private specificationApiService: SpecificationApiService, 
    private fb: FormBuilder, 
  ) {}

  ngOnInit(): void {
    this.form.get(this.field.name).valueChanges.subscribe(value => {
        this.createFormCopyWithControls();
        this.setConnectedRequirements();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.field || !this.form)
      return; 

    this.createFormCopyWithControls();
    this.setConnectedRequirements();
  }

  createFormCopyWithControls(){
    const controlName = this.field.name;
    this.formCopy = this.fb.group({})
    this.formCopy.addControl(controlName, this.fb.control('ID-', Validators.pattern(polarionIdRegexPattern())));
    this.formCopy.addControl('irmaVersion', this.fb.control(0, Validators.required))
  }

  private setConnectedRequirements() {
    this.items = this.form.controls[this.field.name]?.value ?? [];
  } 


  addRequirement() {
    let requirementId = this.formCopy.controls[this.field.name].value;
    let irmaVersion = this.formCopy.controls['irmaVersion'].value ?? 0;

    if (requirementId === '' || requirementId === "ID-") 
      return;

    let duplicateRequirement = this.items?.find(x => x.polarionId === requirementId);
    if(duplicateRequirement) {
      this.showErrorMessage("Requirement already added");
      return;
    }

    this.requirementApiService.getByIdAndIrmaVersion(requirementId, irmaVersion)
      .subscribe((requirement) => {
        if (requirement) {
          this.items.push(requirement);
          this.form.patchValue({ [this.field.name]: this.items });

          this.linkConnectedSpecifications(requirement);

          this.formCopy.controls['irmaVersion'].setValue(0);
          this.clearErrorMessage();
        } else {
          this.showErrorMessage("Invalid requirement ID or IRMA verison");
        }
      });
  }

  linkConnectedSpecifications(requirement: RequirementDto) {
    if(!requirement)
      return;

    this.specificationApiService.getSpecificationsForRequirement(requirement.polarionId, requirement.irmaVersion)
      .subscribe(specifications => {
        this.addedRequirement = requirement;
        this.connectedSpecifications = specifications;

        this.openConnectedItemsModal();
      });
  }

  openConnectedItemsModal(){
    var modal = document.getElementById("connected-items-modal-req");
    modal.classList.remove("hide");
    modal.classList.add("show");
  }

  addConnectedSpecifications(specifications: SpecificationDto[]) {
    if(!specifications || specifications.length < 1)
      return;

   var linkedSpecifications  = this.form.controls[formControlConstants.TASpecification].value ?? [];

    specifications.forEach(specification => {
      specification.linkedRequirement = this.addedRequirement;
      if(!linkedSpecifications.find(x => x.polarionId == specification.polarionId))
        linkedSpecifications.push(specification);
    });

     this.form.patchValue({ [formControlConstants.TASpecification]: linkedSpecifications });
   }

  private showErrorMessage(message: string) {
    this.errorMessage = message;
    this.errorMessageShown = true;
  }

  private clearErrorMessage() {
    this.errorMessage = '';
    this.errorMessageShown = false;
  }

  removeItem(item) {
    const index = this.items.indexOf(item);
    this.items.splice(index, 1);

    this.form.patchValue({ [this.field.name]: this.items });
  }
}
