import { Component,EventEmitter,Input,OnChanges,OnInit,Output,SimpleChanges,} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpecificationApiService } from 'src/app/services/api/specification-api.service';
import { MessageService } from 'primeng/api';
import { RequirementApiService } from 'src/app/services/api/requirement-api.service';
import { formField } from 'src/app/models/formField';
import { RequirementDto } from 'src/app/models/dto/polarion/requirementDto';
import { SpecificationDto } from 'src/app/models/dto/polarion/specificationDto';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { polarion } from 'src/environments/environment';
import { polarionIdRegexPattern } from 'src/app/services/helpers/patternHelper';

@Component({
  selector: 'app-specification',
  templateUrl: './specification.component.html',
  styleUrls: ['./specification.component.scss'],
  providers: [MessageService]
})
export class SpecificationComponent implements OnInit, OnChanges {
  @Input() field!: formField;
  @Input() form!: FormGroup;
  @Input() locked: boolean;
  @Output() newItemEvent = new EventEmitter<SpecificationDto>();

  public specificationPolarionUrl = polarion.itemWithRevisionUrl;

  items: SpecificationDto[] = [];
  formCopy :FormGroup;

  addedSpecification: SpecificationDto;
  connectedRequirements: RequirementDto[];
  connectedRequirement: RequirementDto;
  formControlConstants = formControlConstants;

  errorMessage: string;
  errorMessageShown: boolean = false;
  generateTraceabilityReportUrl: string = 'https://api.reports.tms.scania.com/ReviewReport/GetTraceabilityReport?specId=';

  constructor(
    private specificationApiService: SpecificationApiService, 
    private requirementApiService: RequirementApiService, 
    private messageService: MessageService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form.get(this.field.name).valueChanges.subscribe(value => {
        this.createFormCopyWithControls();
        this.setConnectedSpecifications();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.field || !this.form)
      return;

    this.createFormCopyWithControls();
    this.setConnectedSpecifications();
  }
  
  createFormCopyWithControls(){
    this.formCopy = this.fb.group({})
    const controlName = this.field.name;
    this.formCopy.addControl(controlName, this.fb.control('ID-', Validators.pattern(polarionIdRegexPattern())))
    this.formCopy.addControl('irmaVersion', this.fb.control(0, Validators.required))
  }

  private setConnectedSpecifications() {
    this.items = this.form.controls[this.field.name]?.value || [];
  }

  addSpecification() {
    let specificationId = this.formCopy.controls[this.field.name].value;
    let irmaVersion = this.formCopy.controls['irmaVersion'].value ?? 0;

    if (specificationId === '' || specificationId === "ID-") 
      return;

    let duplicateSpecification = this.items.find(specs => specs.polarionId === specificationId);
    if(duplicateSpecification) {
      this.showErrorMessage("Specification already added");
      return;
    }
    
    this.specificationApiService.getByIdAndIrmaVersion(specificationId, irmaVersion)
      .subscribe((specification) => {
        if (specification) { 
          this.items.push(specification);
          this.form.patchValue({ [this.field.name]: this.items });

          this.linkConnectedRequirements(specification);

          this.formCopy.controls['irmaVersion'].setValue(0);
          this.clearErrorMessage();
        } else { //See if Polarion returns error message for invalid ID. https://stackoverflow.com/questions/46019771/catching-errors-in-angular-httpclient
          this.showErrorMessage("Invalid specification ID or IRMA verison");//Would be nice to have indivdual error messages for ID and IRMA. Dont think API allows for it currently
        }
      });
  }

  linkConnectedRequirements(specification: SpecificationDto) {
    if(!specification || this.field.name != formControlConstants.TASpecification)
      return;

    this.requirementApiService.getRequirementsForSpecification(specification.polarionId,  specification.irmaVersion)
      .subscribe(requirements => {
        this.addedSpecification = specification;
        this.connectedRequirements = requirements;
        
        this.openConnectedItemsModal();
      });
  }

  openConnectedItemsModal(){
    var modal = document.getElementById("connected-items-modal-spec");
    modal.classList.remove("hide");
    modal.classList.add("show");
  }

  addConnectedRequirement(requirements: any[]) {
    if(!requirements) return;

    this.connectedRequirement = requirements[0];
    this.addedSpecification.linkedRequirement = this.connectedRequirement;
    let specificationIndex = this.items.findIndex(item => item.polarionId === this.addedSpecification.polarionId);

    if (specificationIndex !== -1) 
      this.items[specificationIndex] = { ...this.items[specificationIndex], ...this.addedSpecification };//Replace item in table with addedSpecification which includes linked requirement
  
    this.form.patchValue({ [this.field.name]: this.items });

    //add connectedRequirement requirement to its form control and table
    var linkedRequirements  = this.form.controls[formControlConstants.TARequirement].value ?? [];
    if(!linkedRequirements.find(x => x.polarionId == this.connectedRequirement.polarionId))
      linkedRequirements.push(this.connectedRequirement);

    this.form.patchValue({ [formControlConstants.TARequirement]: linkedRequirements });

  }

  private showErrorMessage(message: string) {
    this.errorMessage = message;
    this.errorMessageShown = true;
  }

  private clearErrorMessage() {
    this.errorMessage = '';
    this.errorMessageShown = false;
  }

  removeItem(item) {
    const index = this.items.indexOf(item);
    this.items.splice(index, 1);

    this.form.patchValue({ [this.field.name]: this.items });
  }

  specificationSelectedFromTable(value: SpecificationDto) {
    this.newItemEvent.emit(value);
  }

  showGeneratingReportMessage(specificationId : string) {
    this.messageService.add({severity:'custom', summary: 'Generating report...', detail: `Trecability report for specification ${specificationId} will be ready soon.`, icon: 'pi-file'});
  }
}
