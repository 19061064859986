import { RequirementDto } from "src/app/models/dto/polarion/requirementDto";
import { GraphQLRequirementOutput } from "../models/output/graphql-requirement-output";

export function convertGraphQLRequirementToDto(input: GraphQLRequirementOutput): RequirementDto{
    if(input == null)
        return null;
    return {
        polarionId: input.PolarionId,
        title: input.Title,
        irmaVersion: input.IrmaVersion,
        revision: input.Revision,
        asil: input.Asil,
        status: input.Status,
        legislation: input.Legislation,
    }
}