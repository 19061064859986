import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { SystemTypeId } from 'src/app/models/enums/systemTypeId';
import { RequirementMapper } from 'src/app/services/mappers/requirementMapper';
import { SpecificationEvaluation } from 'src/app/models/specificationEvaluation';
import { ApprovalsApiService } from 'src/app/services/api/approvals-api.service';
import { ApprovalDto } from 'src/app/models/dto/approvals/approvalDto';
import { ItemWithApproval } from 'src/app/models/itemWithApproval';
import { TestLevelsActions } from 'src/app/store/testLevels/testLevels.actions';
import { TestLevel } from 'src/app/models/dto/testLevel';
import { TestLevelsState } from 'src/app/store/testLevels/testLevels.state';
import { ApprovalStatus } from 'src/app/models/enums/approvalStatus';
import { MessageService, FilterService} from 'primeng/api';
import { RequirementApiService } from 'src/app/services/api/requirement-api.service';
import { TestAnalysisApiService } from 'src/app/services/api/test-analysis-api.service';

@Component({
  selector: 'app-item-evaluation',
  templateUrl: './item-evaluation.component.html',
  styleUrls: ['./item-evaluation.component.scss'],
  providers: [MessageService]
})
export class ItemEvaluationComponent implements OnInit, OnDestroy {
  @Input() selectedSpecification: SpecificationEvaluation;
  
  evaluatedSpecifications: SpecificationEvaluation[] = [];
  typeId: number = SystemTypeId.RmsSpecificationCoverage;
  rules: any[] = []; //LinkSourceItem
  itemName: string = 'RMS Specification Coverage';
  testLevels: TestLevel[] = [];
  chartShowingCounter = 0;
  filterOptions = [{ label: 'Connected', value: 1 },
                   { label: 'Not connected', value: 0 }];
  // readonly EvaluationRuleMessage = `Evaluation is passed if:
  // - requirement has linked at least one TestAnalyze
  // - and all TestAnalysis fulfil rules of RMS Specification Coverage
  // - and all TestAnalysis are approved`;
  readonly EvaluationRuleMessage = `Evaluation is passed if:
  - requirement has linked at least one TestAnalyze
  - and all TestAnalysis are approved`;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
    private testAnalysisApiService: TestAnalysisApiService,
    private requirementApiService: RequirementApiService,
    private approvalsApiService: ApprovalsApiService,
    public filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.getTestLevels();
    this.registerCustomTableFilter();
  }

  ngOnChanges(changes: SimpleChanges) {
    let sth = this.evaluatedSpecifications.find(x => x.specification == this.selectedSpecification.specification)
    if(sth)
      this.selectedSpecification = sth;
      else
    this.evaluateSpecification();
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      if (subscription && !subscription.closed) subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  getTestLevels() {
    this.store.dispatch(new TestLevelsActions.SetAllTestLevels());

    let testLevelsObservable = this.store.select<TestLevel[]>(
      TestLevelsState.getAllTestLevels
    );
    const subscription = testLevelsObservable.subscribe((items) =>
      this.onTestLevelsLoaded(items)
    );
    this.subscriptions.push(subscription);
  }

  onTestLevelsLoaded(items: TestLevel[]): void {
    this.testLevels = items;
  }

  registerCustomTableFilter() {
    this.filterService.register(
      'customTableFilter',
      (value, filter): boolean => {
        if (filter === undefined || filter === null) 
          return true;
        
        if (value === undefined || value === null)
          return false;

        if(value === 0 && filter === 0)
          return true;
        
        if(value > 0 && filter === 1)
          return true;

        return false;
      }
    );
  }

  evaluateSpecification(): void {
    if(!this.selectedSpecification)
      return;

    let specificationEvaluation : SpecificationEvaluation = {
      specification: this.selectedSpecification.specification,
      requirements: [],
    }
    let requirementsObservable = this.requirementApiService.getRequirementsForSpecification(this.selectedSpecification.specification.polarionId, this.selectedSpecification.specification.irmaVersion);

    const subscription = requirementsObservable.subscribe((requirementsResult) => {
      let requirements = RequirementMapper.mapEvaluation(requirementsResult);
      this.chartShowingCounter = requirements.length;

      if(this.chartShowingCounter == 0) {
        this.selectedSpecification = specificationEvaluation;
        this.evaluatedSpecifications.push(specificationEvaluation);
      }

      requirements.forEach((requirement) => {
        if(!requirement)
          return;
        //let asilExists = asilList.find((x) => x.indexOf(requirement.asil) == 0) !== null;

          let subscriptionGetItems = this.testAnalysisApiService.getAllWithLinkedRequirement(requirement.polarionId)
          .subscribe((testAnalysis) => {
            let items: ApprovalDto[] = testAnalysis.map((x) => {
              return {
                itemUid: x.uid,
                itemVersion: x.version,
              };
            });
            
            let subscriptionApprovals = this.approvalsApiService.post(items).subscribe((approvals) => {
              let itemsWithApprovals: ItemWithApproval[] = [];
              approvals?.forEach((approval) => {
                let testAnalyze = testAnalysis.find((x) =>
                    x.uid === approval.itemUid &&
                    x.version === approval.itemVersion
                );

                if (testAnalyze) {
                  let itemWithApproval: ItemWithApproval = {
                    approvalStatus: approval.approvalStatus,
                    uid: approval.itemUid,
                    name: testAnalyze.name,
                    version: approval.itemVersion,
                  };

                  let testLevel = this.testLevels.find(x => x.id === approval.approvalLevel);
                  if (testLevel) 
                    itemWithApproval.approvalLevel = testLevel.name;

                  itemsWithApprovals.push(itemWithApproval);
                }
              });

              var areAllApproved = itemsWithApprovals.find(x => x.approvalStatus !== ApprovalStatus.Approved) == null;
              // requirement.evaluationPassed = asilExists && testAnalysis.length > 0 && areAllApproved;
              requirement.evaluationPassed = testAnalysis.length > 0 && areAllApproved;

              
                specificationEvaluation.requirements.push({
                requirement: requirement,
                testAnalysis: itemsWithApprovals,
              });

              if(requirements.length == specificationEvaluation.requirements.length)
                specificationEvaluation.requirements.sort((a, b) => (a.requirement.title > b.requirement.title ? 1 : -1))

              if(--this.chartShowingCounter == 0)
              {
                this.selectedSpecification = specificationEvaluation;
                this.evaluatedSpecifications.push(specificationEvaluation);
              }

            });
            this.subscriptions.push(subscriptionApprovals);
          });
          this.subscriptions.push(subscriptionGetItems);
      });
    });
    this.subscriptions.push(subscription);
  }
}
