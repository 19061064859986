import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, of } from 'rxjs';
import { GitLabScript } from 'src/app/models/dto/gitLabScript';
import { GitLabProject } from 'src/app/models/dto/gitLabProject';
import { GitLabScriptDto } from 'src/app/models/dto/gitLabScriptDto';

@Injectable({
  providedIn: 'root'
})
export class GitlabScriptService {
  private readonly _getProjectByIdUrl = 'https://gitlab.scania.com/api/v4/projects/{0}';
  private readonly _getScriptsInProjectUrl = 'https://gitlab.scania.com/api/v4/projects/{0}/repository/tree?pagination=keyset&page={1}&per_page=100&recursive=true';
  private readonly _getScriptByPathUrl = 'https://gitlab.scania.com/api/v4/projects/{0}/repository/files/{1}?ref=master';
  private readonly _getScriptContentUrl = 'https://gitlab.scania.com/api/v4/projects/{0}/repository/blobs/{1}/raw';
  private _token: string;
  private _headers: HttpHeaders;

  constructor(private httpClient: HttpClient) { 
    //todo fetch local token
    this._token = 'HbSRT1zyVQGTFPxQFuR-';
    this._headers = new HttpHeaders();
    this.setHeadersWithToken();
  }

  setToken(newToken: string) {
    this._token = newToken;
    //todo update local token
    this.setHeadersWithToken();
  }

  private setHeadersWithToken() {
    this._headers =  this._headers.set("Authorization", "Bearer " + this._token);
  }

  getAllFromDefaultProject() { 
    return of([]);
    let projectId = this.getDefaultProjectId();
    return this.getScriptsForProjectId(projectId);
  }

  
  //#region FetchingScripts
  getScriptsForProjectId(projectId: number) {
    let subject = new Subject<GitLabScript[]>();
    this.getProject(projectId).subscribe(project => {
      if(!project) {
        subject.next([])
        return;
      }

       this.fetchGitLabScriptsForProject(project).subscribe(result => subject.next(result));
    });

    return subject.asObservable();
  }

  private fetchGitLabScriptsForProject(project: GitLabProject, subject?: Subject<GitLabScript[]>, index: number = 1, gitLabScripts: GitLabScript[] = []) {
    if(!project)
      return of([]);
    
    let url = this._getScriptsInProjectUrl.replace('{0}', project.id).replace('{1}', index.toString());
    if(!subject)
      subject = new Subject<GitLabScript[]>();

    this.makeHttpRequestReturningJsonArray(url).subscribe(result => {
      if(!result || !result.body) {
        subject.next(gitLabScripts);
        return;
      }

      let gitLabItems: GitLabScript[] = result.body.filter(x => x.path.endsWith('.vtt') || x.path.endsWith('.py')).map(x => {
        return {
          name: x.name,
          path: x.path,
          project: project,
          missingForeignSystemKeyMessage: '',
          foreignSystemKey: undefined,
        };
      });
      gitLabScripts = gitLabScripts.concat(gitLabItems);
      
      let nextPage = result.headers.get('x-next-page');
      if(!nextPage || nextPage == '') {
        subject.next(gitLabScripts);
        return;
      }

      this.fetchGitLabScriptsForProject(project, subject, ++index, gitLabScripts);
    });

    return subject.asObservable();
  }

  private getProject(projectId:number) {
    let url = this._getProjectByIdUrl.replace('{0}', projectId.toString());
    let subject = new Subject<GitLabProject>();

    this.makeHttpRequestReturningJsonObject(url).subscribe(result => {
      if(!result || !result.body) {
        subject.next(undefined);
        return;
      }

      let gitLabProject: GitLabProject ={
        id: result.body.id, 
        name: result.body.name
      };
      subject.next(gitLabProject);
    }, error => {
      subject.next(undefined);
    });

    return subject.asObservable();
  }

  //#endregion ForeignSystemKey

  //#region fetchForeignSystemKey
  fetchForeignSystemKeyDto(script: GitLabScript) {
    if(!script)
      return of(script);

    let subject = new Subject<GitLabScript>();

    if(!script.path.endsWith('.vtt')) {
      script.missingForeignSystemKeyMessage = 'Not applicable!';
      return of(script);
    }

    this.getForeignSystemKey(script).subscribe(foreignSystemKey => {
      let editedScript = this.setForeignSystemKeyAndMessage(script, foreignSystemKey);
      subject.next(editedScript);
    });

    return subject.asObservable();
  }

  fetchForeignSystemKey(script: GitLabScript) {
    if(!script)
      return of(script);

    let subject = new Subject<GitLabScript>();

    if(!script.path.endsWith('.vtt')) {
      script.missingForeignSystemKeyMessage = 'Not applicable!';
      return of(script);
    }

    this.getForeignSystemKey(script).subscribe(foreignSystemKey => {
      let editedScript = this.setForeignSystemKeyAndMessage(script, foreignSystemKey);
      subject.next(editedScript);
    });

    return subject.asObservable();
  }

  private getForeignSystemKey(script: GitLabScript) {
    let subject = new Subject<string>();

    let urlPath = this.pathify(script.path);
    let urlToItem = this._getScriptByPathUrl.replace('{0}', script.project.id).replace('{1}', urlPath);

    this.makeHttpRequestReturningJsonArray(urlToItem).subscribe((result: any) => {
      let urlToContent = this._getScriptContentUrl.replace('{0}', script.project.id).replace('{1}', result.body.blob_id);

      this.makeHttpRequestReturningString(urlToContent).subscribe(content => {
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(content,"text/xml");
        let foreignSystemKey = xmlDoc.getElementsByTagName("tcid")[0]?.childNodes[0]?.nodeValue;
        subject.next(foreignSystemKey);
      });
    });

    return subject.asObservable();
  }

  
  private setForeignSystemKeyAndMessage(script: GitLabScript, foreignSystemKey: string) {
    let editedScript = undefined;
    if(!foreignSystemKey) 
      editedScript = {...script, missingForeignSystemKeyMessage: "Couldn't read ForeignSystemKey from script."};
     else if(foreignSystemKey === '00000000-0000-0000-0000-000000000000') 
      editedScript = {...script, missingForeignSystemKeyMessage: "Couldn't find ForeignSystemKey in script or there are multiple ForeignSystemKeys."};
     else 
      editedScript = {...script, foreignSystemKey: foreignSystemKey, missingForeignSystemKeyMessage: ''};
    
    return editedScript;
  }

  //#endregion ForeignSystemKey

  private getDefaultProjectId() {
    //TODO: fetch from user's local storage
    return 14739;
  }


  private pathify(path: string) {
    //replace all occurrences of '/' with '%2F'
    let url = path.replace(/\//g, '%2F');
    return url;
  }

  //#region httpCalls
  private makeHttpRequestReturningJsonArray(url: string) {
    return this.httpClient.get<any[]>(url, { headers: this._headers, responseType: 'json', observe: 'response' });
  }

  private makeHttpRequestReturningJsonObject(url: string) {
    return this.httpClient.get<any>(url, { headers: this._headers, responseType: 'json', observe: 'response' });
  }

  private makeHttpRequestReturningString(url: string) {
    return this.httpClient.get(url,  { headers: this._headers, responseType: 'text', observe: 'body' });
  }
  //#endregion httpCalls
}
