
export enum GraphQLFieldTypeEnum{
    text = 'Text',
    number = 'Number',
    toggle = 'Toggle',
    dropdown = 'Dropdown',
    multiselect = 'Multiselect',
    chip = 'Chip',
    tpc = 'Tpc',
    table = 'Table',
    userFunction = 'UserFunction'
}