import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterService } from 'primeng/api';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { InputTypeNameConstants } from 'src/app/constants/propertyConstants';
import { SpecificationDto } from 'src/app/models/dto/polarion/specificationDto';
import { FieldTypeEnum } from 'src/app/models/enums/fieldTypeEnum';
import { formField } from 'src/app/models/formField';

@Component({
  selector: 'app-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss']
})
export class DynamicFormFieldComponent implements OnInit {
  @Input() field!: formField;
  @Input() form!: FormGroup;
  @Output() newItemEvent = new EventEmitter<SpecificationDto>();

  fieldTypes = FieldTypeEnum;

  itemSuggestions: any[];
  selectedItems: string[] = [];
  alterStyle: boolean = false;

  constructor(private filterService: FilterService) {}

  ngOnInit(): void {
    if(this.field == undefined)
      return;
    
    //subscribe to changes in risk attributes (filterChange doesn't detect if value is deleted)
    if(this.field?.name === formControlConstants.ProbabilityOfFailure 
      || this.field?.name === formControlConstants.ImpactIfFailureAppears)
      this.form.get(this.field.name).valueChanges.subscribe(value => this.calculateTotalRiskLevel())

    if(this.field.isReadOnly 
      || this.field.name === formControlConstants.ItemNo 
      || (this.field.isReadOnly && this.field.name === formControlConstants.InputTypeValues))
      this.form.controls[this.field.name]?.disable();
  }

  filterItem(event) {
    this.filterDefaultItem();
    if(this.field.hasHierarchyValues)
      this.filterParentChildDropdown(event);
    if(event.query)
      this.filterValuesByInput(event.query);
  }

  filterDefaultItem() {
    let filtered = [];
    if(this.field.hasHierarchyValues)
      this.field.hierarchialOptions.forEach(x => filtered.push(x));
    else 
      this.field.options?.forEach(x => filtered.push(x));

    this.itemSuggestions = filtered;
  }

  filterParentChildDropdown(event) {
    let query = event.query;
    let filteredGroups = [];

    for (let parent of this.itemSuggestions) {
      let filteredSubOptions = this.filterService.filter(parent.items, ["label"], query, "contains");
      if (filteredSubOptions && filteredSubOptions.length)
        filteredGroups.push({
          value: parent.value,
          label: parent.label,
          items: filteredSubOptions
        });
    }
    this.itemSuggestions = filteredGroups;
    if(this.field.name === formControlConstants.TSProductProperty || this.field.name === formControlConstants.TCProductProperty){
      let selectedChild = this.form.get(this.field.name).value
      if(selectedChild)
        this.suggestOnlyChildrenOfParent(selectedChild[0])
    }
  }

  filterChange(event): void {
    if(this.field.name === formControlConstants.InputTypeName)
      this.handleInputTypeNameChange();
    
    this.styleCheck();
  }

  suggestOnlyChildrenOfParent(selectedItem: any):void {
    if(!selectedItem)
      return;

    let parent = this.field.hierarchialOptions.find(parent => parent.items.find(child => child.value == selectedItem.value));//.key == selectedItem.key
    if(parent && this.itemSuggestions.length > 0)
      this.itemSuggestions.splice(0, this.field.hierarchialOptions.length, parent);
  }

  private handleInputTypeNameChange() {
    let inputTypeNameValue =  this.form.controls[formControlConstants.InputTypeName]?.value?.value;

    if(inputTypeNameValue === InputTypeNameConstants.SingleSelection
        || inputTypeNameValue === InputTypeNameConstants.MultipleSelection
        || inputTypeNameValue === InputTypeNameConstants.OrderSelection)
      this.form.controls[formControlConstants.InputTypeValues]?.enable();
    else 
      this.form.controls[formControlConstants.InputTypeValues]?.disable();
  }

  private calculateTotalRiskLevel(){
    const impactField = this.form.get(formControlConstants.ImpactIfFailureAppears).value?.value;
    const probabilityField = this.form.get(formControlConstants.ProbabilityOfFailure).value?.value;

    if(!impactField || !probabilityField) {
      this.form.controls[formControlConstants.TotalRiskLevel]?.setValue(undefined);
      return;
    }

    let totalRiskLevel = '';
    if (impactField === 'High' && probabilityField === 'High') 
      totalRiskLevel = 'High';
    else if (impactField === 'Low' && probabilityField === 'Low') 
      totalRiskLevel = 'Low';
    else 
      totalRiskLevel = 'Medium';
    
    this.form.controls[formControlConstants.TotalRiskLevel]?.setValue({key: totalRiskLevel, value: totalRiskLevel});
  }

  filterValuesByInput(query : string){
    if(!query || query == '')
      return
    
    this.itemSuggestions = this.field.options.filter(option => option.value.toLowerCase().includes(query.toLowerCase()));
  }

  onUnselect(){
    if(this.field.name === formControlConstants.TSProductProperty || this.field.name === formControlConstants.TCProductProperty)
      this.resetSuggestions();
    else
      this.styleCheck();
  }

  resetSuggestions(): void {
    this.filterDefaultItem();
  }

  styleCheck(){
    if(this.selectedItems.length >= 3)
      this.alterStyle = true;
    else
      this.alterStyle = false;
  }
}
