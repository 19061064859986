import { GraphQLFpcFamilyOutput, GraphQLFpcOutput } from "../models/output/graphql-fpc-family-output";
import { FpcFamily } from "src/app/models/dto/fpc-family";
import { Fpc } from "src/app/models/dto/fpc";

export function convertGraphqlFpcFamilyToFpcFamily(input: GraphQLFpcFamilyOutput): FpcFamily {
    return {
        fpcCode : input.FpcCode,
        name : input.Name,
        notCondition: false,
        variants : input.Variants.map(item => {
            return convertGraphqlFpcToFpc(item);
        })
    }
  }

  export function convertGraphqlFpcToFpc(input: GraphQLFpcOutput): Fpc {
    return {
        fpcCode : input.FpcCode,
        name : input.Name,
        notCondition: false
    }
  }