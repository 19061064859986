
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { RouteSegments, RouteConstants } from 'src/app/constants/routeConstants';
import { ApprovalTableFilter } from 'src/app/models/enums/approval-table-filter-enum';
import { OrganizationalGroupsState } from 'src/app/store/organizationalGroups/organizationalGroups.state';
import { SystemTypeId } from 'src/app/models/enums/systemTypeId';
import { AuthService } from 'src/app/services/auth.service';
import { ColumnFilterFormElement } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { ParentItemBasicInfo } from 'src/app/models/dto/parent-item-basic-info-dto';
import { Approval } from 'src/app/models/review/approval';
import { ApprovalStatus } from 'src/app/models/enums/approvalStatus';
import { ItemTableFilter } from 'src/app/models/enums/item-table-filter-enum';
import { ItemType } from 'src/app/models/enums/itemType';
import { ReviewService } from 'src/app/services/review/review.service';
import { getRouteBase } from 'src/app/services/helpers/routeHelper';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrl: './review.component.scss',
  providers: [MessageService]
})

export class ReviewComponent implements OnInit {

  items: any[];
  approvals: Approval[];
  filteredApprovals: Approval[];
  selectedItem: any;
  approvalTableFilter: ApprovalTableFilter = ApprovalTableFilter.ApprovalsCreatedByMyGroup;
  itemType = ItemType ;
  approvalStatus = ApprovalStatus;

  parentItems: ParentItemBasicInfo[];
  username: string;
  orgGroupId: string = '';
  path: string = '';
  sortField: string = 'creationTime';
  sortOrder: number = -1;
  currentUserSubscription: Subscription;
  itemGetSubscription: Subscription;
  orgGroupSubscription: Subscription;
  routes = RouteConstants;
  addRoute: string = RouteSegments.ADD;
  evaluationRoute: string = RouteConstants.TEST_COVERAGE;
  itemTableFilter: ItemTableFilter= ItemTableFilter.ItemsCreatedByMyGroup;
  typeIdNames = SystemTypeId;
  parentSubscription: Subscription;

  approvals$: Observable<any>
  approvalsSubscription: Subscription
  
  constructor(
    private authService: AuthService,
    private store: Store,
    private reviewService: ReviewService

  ) { 
    this.approvals = [];
  }

  ngOnInit(): void {
    console.log("Review initialized");
    this.currentUserSubscription = this.authService.userData$.subscribe((result) => {
      this.username = result.userName
      this.getItems();
    });
    this.orgGroupSubscription = this.store.select<any>(OrganizationalGroupsState.getLoggedInOrganizationalGroup)
      .subscribe((result) => {
        if(result != undefined){
          this.orgGroupId = result.uid;
        }
      });

    this.approvals$.subscribe((approvals) => {
      this.approvals = approvals
      this.getItems()
    })

    // filter instantly without pressing Enter
    ColumnFilterFormElement.prototype.onModelChange = function (value) {
      this.filterConstraint.value = value;
      if (this.type || value === '') {
        this.dt._filter();
      }
    }
  }

  getItems() {
    this.filteredApprovals = [];

    if (this.approvalsSubscription) {
      this.approvalsSubscription.unsubscribe()
    }

    switch(this.approvalTableFilter)
    {
        case ApprovalTableFilter.MeAsApprover:
          this.approvals$ = this.reviewService.getApprovalsObservable({RegBy: this.username})
          this.approvalsSubscription = this.approvals$.subscribe((result) => this.filteredApprovals = result)
           // this.filteredApprovals = this.approvals.filter(x => x.regBy?.toLowerCase() === this.username.toLowerCase());
            // console.log("MeAsApprover", this.filteredApprovals);
        break;

        case ApprovalTableFilter.MeAsReviewer:
          this.approvals$ = this.reviewService.getApprovalsObservable({Reviewer: this.username})
          this.approvalsSubscription = this.approvals$.subscribe((result) => this.filteredApprovals = result)
            // this.filteredApprovals = this.approvals.filter(approval => 
            //     approval.reviewers.some(reviewer => reviewer.toLowerCase() === this.username.toLowerCase())
            // );
            console.log("MeAsReviewer", this.filteredApprovals);
        break;

        case ApprovalTableFilter.ApprovalsCreatedByMyGroup:
            // this.filteredApprovals = this.approvals;
            // console.log("ApprovalsCreatedByMyGroup", this.filteredApprovals);
        break;
    }
}

  getRoute(item: ItemType){
    return getRouteBase(item);
  }

  setReviewState(){
    this.reviewService.setReviewOn(true);
  }

  isUserReviewer(approval: Approval): boolean {
    for (const element of approval.reviewers) {
      if (this.username.toLowerCase() === element.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  disableUserAsReviewerButton(approval: Approval): Boolean{
    if(approval.approvalStatus === ApprovalStatus.InReview || approval.approvalStatus === ApprovalStatus.ReadyForReview)
      return false;
    return true;
  }

  addUserToReviewers(approval: Approval) {
    const foundApproval = this.approvals.find(x => x.id === approval.id);
    if (!foundApproval) return;
  
    if (!foundApproval.reviewers.some(x => x.toLowerCase() === this.username.toLowerCase())) {
      foundApproval.reviewers.push(this.username);
    }
  
    this.reviewService.updateApproval(foundApproval);
    console.log("Reviewers after adding a reviewer", foundApproval.reviewers);
  }
  
}
