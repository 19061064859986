export namespace TpcActions {
  export class SetInitialValue {
    static readonly type = '[Tpc] SetInitialValue';
    constructor(public initialValue: string) {}
  }

  export class SetCurrentValue {
    static readonly type = '[Tpc] SetCurrentValue';
    constructor(public currentValue: string) {}
  }
}
