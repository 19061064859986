import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Regexes, StringConstants } from '../constants/tpcConstants';
import { SystemProperty } from '../models/dto/systemProperty';
import { SystemTypeId } from '../models/enums/systemTypeId';

@Injectable({
  providedIn: 'root',
})
export class TpcTranslationService {
  constructor() {}

  translateTree(treeNodes: TreeNode[], systemProperties: SystemProperty[]) {
    treeNodes.forEach((treeNode) => {
      this.translateNode(treeNode, systemProperties);
      this.translateTree(treeNode.children, systemProperties);
    });
  }

  private translateNode(
    node: TreeNode,
    systemProperties: SystemProperty[]
  ): void {
    let expression = node.label;
    if (Regexes.LogicalOperations.test(expression)) {
      let operation = expression.match(Regexes.LogicalOperations);
      expression = expression
        .substring(0, expression.indexOf(operation[0]))
        .trim();

      let matches = expression.match(Regexes.ArrayOfNumbers);
      if (matches.length !== 1) {
        throw Error;
      }

      let identifier = matches[0].toString();
      if (
        expression.substring(0, expression.indexOf(identifier)) !==
        StringConstants.PREFIX
      ) {
        throw Error;
      }

      let value = expression.substring(
        expression.indexOf(identifier) + identifier.length
      );

      let translation = this.getTranslationByIdentifier(
        identifier,
        value,
        systemProperties
      );
      let nodeLabel = node.label;
      let newL = nodeLabel.replace(expression, translation);

      node.data = nodeLabel;
      node.label = newL;
    } else {
      let matches = expression.match(Regexes.ArrayOfNumbers);
      if (matches.length !== 1) {
        throw Error;
      }

      let identifier = matches[0].toString();
      if (
        expression.substring(0, expression.indexOf(identifier)) !==
        StringConstants.PREFIX
      ) {
        throw Error;
      }

      let value = expression.substring(
        expression.indexOf(identifier) + identifier.length
      );

      let translation = this.getTranslationByIdentifier(
        identifier,
        value,
        systemProperties
      );
      let nodeLabel = node.label;
      let newL = nodeLabel.replace(expression, translation);

      node.data = nodeLabel;
      node.label = newL;
    }
  }

  getTranslationByIdentifier(
    identifier: string,
    variant: string,
    systemProperties: SystemProperty[]
  ): string {
    let retVal = '';

    if (identifier === SystemTypeId.TestCase.toString() && variant === 'H') {
      retVal = 'DerivedFrom';
    }

    if (identifier === SystemTypeId.ReviewRole.toString()) {
      retVal = 'ReviewRole';
      //todo get by variant
      let variantProperty = systemProperties.find(
        (x) =>
          x.identifier === SystemTypeId.ReviewRole &&
          x.variant.toLowerCase() === variant.toLowerCase()
      );
      if (variantProperty) {
        retVal = `ReviewRole="${variantProperty.name}"`;
      }
    }

    return retVal;
  }
}
