import { gql } from "apollo-angular";

export const GET_HALF_FPCS_QUERY = gql`query configuratorHalfFpcs($FirstHalf: Boolean) {
    configuratorHalfFpcs(FirstHalf: $FirstHalf) {
        FpcCode
        Name
        Variants{
            FpcCode
            Name
        }
    }
  }
  `