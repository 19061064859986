import { Component, Input, OnInit } from '@angular/core';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';

@Component({
  selector: 'app-item-registration-info',
  templateUrl: './item-registration-info.component.html',
  styleUrls: ['./item-registration-info.component.scss']
})
export class ItemRegistrationInfoComponent implements OnInit {
  @Input() item: BaseTestItemDto;

  constructor() { }

  ngOnInit(): void {
  }

}
