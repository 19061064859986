<p-panel toggler="header" styleClass="panelHeader" [toggleable]="true" >
    <ng-template pTemplate="header">
        <p class="boldText">Compare specifications</p>
    </ng-template>
    <div style="width:100%">

    <div *ngIf="form" class="container" style="padding-left:20px; padding-top:30px;">
      <p-table [formGroup]="form">
        <ng-template pTemplate="header">
          <tr>
            <td class="input-field">
              <sdds-textfield ngDefaultControl required id="specificationOne" type="text"
                (input)="handleInputChangeID1($event)" [placeholder]="'Specification ID #1'"
                [pTooltip]="'e.g. ID-123456 or 123456 \n The earlier specification'" formControlName="specificationOne" >
                <span slot="sdds-label" class="textfield-float-label">
                  Specification ID
                </span></sdds-textfield>
              <div *ngIf="form.controls['specificationOne'].invalid && form.controls['specificationOne'].touched"
                class="sdds-banner sdds-banner-error">
                <i class="pi pi-info-circle sdds-banner-prefix" style="font-size: initial"></i>
                <h6 class="sdds-banner-header">Invalid ID format</h6>
              </div>
            </td>
            <td class="input-field">
              <sdds-textfield ngDefaultControl required id="irmaOne" type="number"
                (input)="handleInputChangeIRMA1($event)" [placeholder]="'Version No.'"
                [pTooltip]="'IRMA version for specification 1'" formControlName="irmaOne">
                <span slot="sdds-label" class="textfield-float-label">
                  IRMA version
                </span></sdds-textfield>
              <div *ngIf="form.controls['irmaOne'].invalid && form.controls['irmaOne'].touched"
                class="sdds-banner sdds-banner-error">
                <i class="pi pi-info-circle sdds-banner-prefix" style="font-size: initial"></i>
                <h6 class="sdds-banner-header">IRMA version is required</h6>
              </div>
            </td>
          </tr>
          <tr>
            <td class="input-field">
              <sdds-textfield ngDefaultControl required id="specificationTwo" type="text"
                (input)="handleInputChangeID2($event)" [placeholder]="'Specification ID #2'"
                [pTooltip]="'e.g. ID-123456 or 123456 \n The later specification'" formControlName="specificationTwo" >
                <span slot="sdds-label" class="textfield-float-label">
                </span></sdds-textfield>
              <div *ngIf="form.controls['specificationTwo'].invalid && form.controls['specificationTwo'].touched"
                class="sdds-banner sdds-banner-error">
                <i class="pi pi-info-circle sdds-banner-prefix" style="font-size: initial"></i>
                <h6 class="sdds-banner-header">Invalid ID format</h6>
              </div>
            </td>
            <td class="input-field">
              <sdds-textfield ngDefaultControl requried id="irmaTwo" type="number"
                (input)="handleInputChangeIRMA2($event)" [placeholder]="'Version No.'"
                [pTooltip]="'IRMA version for specification 2'" formControlName="irmaTwo"></sdds-textfield>
              <div *ngIf="form.controls['irmaTwo'].invalid && form.controls['irmaTwo'].touched"
                class="sdds-banner sdds-banner-error">
                <i class="pi pi-info-circle sdds-banner-prefix" style="font-size: initial"></i>
                <h6 class="sdds-banner-header">IRMA version is required</h6>
              </div>
            </td>
          </tr>
          <tr>
            <td class="td-button">
              <button type="button" [disabled]="form.controls['specificationOne'].invalid || 
              form.controls['irmaOne'].invalid || 
              form.controls['specificationTwo'].invalid || 
              form.controls['irmaTwo'].invalid" class="sdds-btn sdds-btn-secondary sdds-btn-sm"
                (click)="onCompare()">COMPARE</button>
              <button type="button" [disabled]="form.controls['specificationOne'].invalid || 
              form.controls['irmaOne'].invalid || 
              form.controls['specificationTwo'].invalid || 
              form.controls['irmaTwo'].invalid"
              class="sdds-btn sdds-btn-secondary sdds-btn-sm"
              (click)="onSwap()"> SWAP    <i  [class]="'pi pi-sort-alt'" style="font-size: initial"></i></button>
              </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <h5 *ngIf="errorMessage" class="errorMessage">{{errorMessage}}</h5>
    </div>
    <div class="resultsTable" style="padding: 30px 50px 10px;" *ngIf="result">
        <h3>Comparison results
        <i [class]="'pi pi-info-circle'" style="font-size: initial"
        pTooltip="<img src='../../../assets/ComparisonColorToolTip_xsmall.png'>" [escape]="false"
        ></i>
        </h3>
        <h6>Showing {{ result ? result.length : 0 }} results. 
        <i [class]="'pi pi-info-circle'" style="font-size: initial" tooltip-template-url="../assets/ComparisonColorToolTip"
            [pTooltip]="'Showing only differences. If an existing requirement is not listed, it means they have the same IRMA version in both specifications'">
        </i>
        </h6>
    <p-table [value]="result">
      <ng-template pTemplate="header" let-res>
        <tr>
          <th pSortableColumn="requirementPolarionId">Requirement ID <p-sortIcon
              field="requirementPolarionId"></p-sortIcon></th>
          <th pSortableColumn="versionInSpecA">Version in {{comparisonToView.itemOne.polarionId}},
            v{{comparisonToView.itemOne.irmaVersion}} <p-sortIcon field="versionInSpecA"></p-sortIcon></th>
          <th pSortableColumn="versionInSpecB">Version in {{comparisonToView.itemTwo.polarionId}},
            v{{comparisonToView.itemTwo.irmaVersion}} <p-sortIcon field="versionInSpecB"></p-sortIcon></th>
          <th pSortableColumn="highestVersionInTA">Highest version in TA <p-sortIcon
              field="highestVersionInTA"></p-sortIcon></th>
        </tr>
        <tr>
          <th>
            <p-columnFilter type="text" field="requirementPolarionId" matchMode="contains" placeholder="Search Requirement ID" class="p-column-filter"></p-columnFilter>
          </th>
          <th></th>
          <th></th>
          <th pSortableColumn="updateRequired">Sort on color <p-sortIcon field="updateRequired"></p-sortIcon></th>
        </tr>
      </ng-template>
  
      <ng-template pTemplate="body" let-res sortMode="multiple">
        <tr [ngClass]="{
          'positive': !res.updateRequired,
          'negative': res.updateRequired || res.highestVersionInTA == '-',
          'warning': res.updateRequired == undefined || res.info == 'Possible deleted requirement'
         }">
          <td [ngStyle]="{'font-weight': res.versionInSpecB != res.highestVersionInTA ? 'bold' : 'normal'}">{{res.requirementPolarionId}} 
          <i *ngIf="res.updateRequired == null" [class]="'pi pi-question-circle'" [pTooltip]="res.info" style="font-size: initial"></i>
          <i *ngIf="res.updateRequired != null" [class]="res.updateRequired ? 'pi pi-exclamation-circle': 'pi pi-check-circle'" [pTooltip]="res.info" style="font-size: initial"></i></td> 
          <td>{{res.versionInSpecA}}</td>
          <td>{{res.versionInSpecB}}</td>
          <td>{{res.highestVersionInTA}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-panel>
