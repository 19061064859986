import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RouteConstants } from 'src/app/constants/routeConstants';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { ReviewService } from 'src/app/services/review/review.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  username: string;
  currentUserSubscription: Subscription;

  testCoverageRoute: string = RouteConstants.TEST_COVERAGE;
  rmsSpecificationsComparisonRoute: string = RouteConstants.RMS_SPECIFICATIONS_COMPARISON;
  testAnalysisRoute: string = RouteConstants.TEST_ANALYSIS;
  testCasesRoute: string = RouteConstants.TEST_CASES;
  testSuitesRoute: string = RouteConstants.TEST_SUITES;
  testSpecificationsRoute: string = RouteConstants.TEST_SPECIFICATIONS;
  reviewRoute: string = RouteConstants.REVIEW;
  isHero = environment.IsHero;
  reviewEnabled$ = this.reviewService.reviewEnabled$;

  constructor(
    private authService: AuthService,
    private reviewService: ReviewService
  ) {}

  ngOnInit(): void {
    this.currentUserSubscription =
    this.authService.userData$.subscribe((result) => this.username = result.userName);
  }
}
