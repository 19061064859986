import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class TpcService {
  constructor() {}

  convertTranslatedTreeToTpcString(nodes: TreeNode[]): string {
    let list: string[] = [];

    nodes.forEach((child) => {
      let tpcString = '';

      tpcString += child.label;
      if (child.children && child.children.length > 0) {
        tpcString = `(${tpcString} AND ${this.convertTranslatedTreeToTpcString(
          child.children
        )})`;
      }

      list.push(tpcString);
    });

    return list.length > 1 ? `(${list.join(' OR ')})` : list.join(' OR ');
  }

  convertTreeToTpcString(nodes: TreeNode[]): string {
    let list: string[] = [];

    nodes.forEach((child) => {
      let tpcString = '';

      tpcString += child.data;
      if (child.children && child.children.length > 0) {
        tpcString = `(${tpcString} AND ${this.convertTreeToTpcString(
          child.children
        )})`;
      }

      list.push(tpcString);
    });

    return list.length > 1 ? `(${list.join(' OR ')})` : list.join(' OR ');
  }
}
