import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { ApolloClientService } from './apollo-client.service';
import { GraphQLProductPropertyOutput } from 'src/app/graphQL/models/output/graphql-product-property-output';
import { GETALL_PRODUCT_PROPERTIES_QUERY } from 'src/app/graphQL/queries/product-property-queries';
import { convertGraphqProductPropertyToDto } from 'src/app/graphQL/mappers/product-property.mapper';
import { ProductProperty } from 'src/app/models/dto/productProperty';

@Injectable({
  providedIn: 'root'
})
export class ProductPropertiesService {

  constructor(private apolloClientService: ApolloClientService) { }

  get(): Observable<ProductProperty[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorProductProperties: GraphQLProductPropertyOutput[]}>({
          query: GETALL_PRODUCT_PROPERTIES_QUERY,
          variables: {}
        })
      ),
      map(results => {
        const productProperties = results.data.configuratorProductProperties
        if (!productProperties)
          return undefined

        return productProperties.map(item => {
          return convertGraphqProductPropertyToDto(item) as ProductProperty;
        })
      })
    )
  }
}
