import { GraphQLScenarioOutput, GraphQLUseCaseOutput, GraphQLUserFunctionHierarchyOutput, GraphQLUserFunctionOutput } from "../models/output/graphql-user-function-output";
import { Scenario, UseCase, UserFunction } from "src/app/models/dto/user-function-dto";

  export function convertGraphqlUserFunctionToDto(input: GraphQLUserFunctionOutput): UserFunction {
    return {
      id: input.Id,
      name: input.Name,
    }
  }

  export function convertGraphqlUserFunctionHierarchyToDto(input: GraphQLUserFunctionHierarchyOutput): UserFunction {
    return {
      id: input.Id,
      name: input.Name,
      useCases: input.UseCases?.map(item => {
        return convertGraphqlUseCaseToDto(item)
      })
    }
  }


  export function convertGraphqlUseCaseToDto(input: GraphQLUseCaseOutput): UseCase {
    return {
      id: input.Id,
      name: input.Name,
      scenarios: input.Scenarios?.map(item => {
        return convertGraphqlScenarioToDto(item)
      })
    }
  }


  export function convertGraphqlScenarioToDto(input: GraphQLScenarioOutput): Scenario {
    return {
      id: input.Id,
      name: input.Name,
    }
  }
