import { ApprovalStatus } from "src/app/models/enums/approvalStatus";
import { ItemType } from "src/app/models/enums/itemType";
import { Approval } from "src/app/models/review/approval";
import { ReviewComment } from "src/app/models/review/comment";
import { ReviewCommentGroup } from "src/app/models/review/commentGroup";
import { ReviewUser } from "src/app/models/review/user";

export const getDummyComments = (itemUid: string, version: number): ReviewComment[] => {
  return [
    {
      id: 1,
      itemUid,
      version, 
      group: ReviewCommentGroup.TC_General,
      text: "test 1",
      createdBy: "mpazfa",
      createdAt: new Date('2024-09-10 10:23:00'),
      handled: false,
      handledBy: "",
      handeledAt: undefined          
    },
    {
      id: 2,
      itemUid,
      version, 
      group: ReviewCommentGroup.TC_General,
      text: "test 2",
      createdBy: "mpazfa",
      createdAt: new Date('2024-09-10 10:27:00'),
      handled: false,
      handledBy: "",
      handeledAt: undefined
    }
  ]
}

export const getDummyUsers = (): ReviewUser[] => {
  return [
    {
      id: 'mpazfa',
      name: 'Miloš Pavlović',
      company: 'Scania'       
    },
    {
      id: 'tmay57',
      name: 'Tijana Marković',
      company: 'Scania'       
    }
    ,
    {
      id: 'lnebez',
      name:  'Lars Erik Nes',
      company: 'Scania'
    }
  ]
}

export const getDummyApprovals = (): Approval[] => {
  return [ 
    {
    id : 1,
    itemUid : '9083d1c9-41bc-ee11-9161-0050569e7530',
    itemName: 'Test Case 1',
    itemVersion: 1,
    itemType: ItemType.TestCase,
    reviewers: ['MPAZFA', 'SCH8XG', 'ADEJKX'],
    approvalStatus: ApprovalStatus.InReview,
    regBy:'TMAY57',
    regTime: new Date()
  },
  {
    id : 1,
    itemUid : '5e4a0017-25bc-ee11-9161-0050569e7530',
    itemName: 'TC 3',
    itemVersion: 1,
    itemType: ItemType.TestCase,
    reviewers: ['MPAZFA', 'SCH8XG', 'ADEJKX', 'TMAY57'],
    approvalStatus: ApprovalStatus.InReview,
    regBy:'TMAY57',
    regTime: new Date()
  },
  {
    id : 1,
    itemUid : '18449bbb-22bc-ee11-9161-0050569e7530',
    itemName: 'Test Analyze 1',
    itemVersion: 2,
    itemType: ItemType.TestAnalysis,
    reviewers: ['MPAZFA', 'SCH8XG', 'ADEJKX', 'BTIGLQ'],
    approvalStatus: ApprovalStatus.ReadyForReview,
    regBy:'BTIGLQ',
    regTime: new Date()
  },
  {
    id : 1,
    itemUid : '215e5236-6f3f-ef11-aca0-0ec3edf56b63',
    itemName: 'Demo Test Suite',
    itemVersion: 6,
    itemType: ItemType.TestSuite,
    reviewers: ['SCH8XG', 'ADEJKX', 'BTIGLQ'],
    approvalStatus: ApprovalStatus.WaitingForApproval,
    regBy:'MPAZFA',
    regTime: new Date()
  },
  {
    id : 1,
    itemUid : '23449bbb-22bc-ee11-9161-0050569e7530',
    itemName: 'Tijana TestSpec',
    itemVersion: 1,
    itemType: ItemType.TestSpecification,
    reviewers: [ 'SCH8XG', 'ADEJKX'],
    approvalStatus: ApprovalStatus.Approved,
    regBy:'MPAZFA',
    regTime: new Date()
  },{
    id : 6,
    itemUid : '9083d1c9-41bc-ee11-9161-0050569e7530',
    itemName: 'Test Case 1',
    itemVersion: 1,
    itemType: ItemType.TestCase,
    reviewers: ['MPAZFA', 'SCH8XG', 'ADEJKX'],
    approvalStatus: ApprovalStatus.InReview,
    regBy:'TMAY57',
    regTime: new Date()
  },]
}