import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RouteConstants } from 'src/app/constants/routeConstants';
import { ParentItemBasicInfo } from 'src/app/models/dto/parent-item-basic-info-dto';
import { ItemType } from 'src/app/models/enums/itemType';
import { ItemsService } from 'src/app/services/api/items.service';
import { getRouteBase } from 'src/app/services/helpers/routeHelper';

@Component({
  selector: 'view-parent-items',
  templateUrl: './view-parent-items.component.html',
  styleUrls: ['./view-parent-items.component.scss']
})
export class ViewParentItemsComponent implements OnInit, OnDestroy {

  private _itemUid: string;
  @Input() set itemUid(value: string) {
    this._itemUid = value;
    this.loadParentItems();
  }
  get itemUid(): string {
    return this._itemUid;
  }

  parentItems: ParentItemBasicInfo[];
  itemType = ItemType;

  private parentItemsSubscription: Subscription; 

  constructor(private itemsService : ItemsService) { }

  ngOnInit(): void {
  }

  loadParentItems(){
    if(this.itemUid){
      this.parentItemsSubscription = this.itemsService.getParentItems(this.itemUid)
        .subscribe((result) => {
          this.parentItems = result;
        })
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    if(this.parentItemsSubscription)
      this.parentItemsSubscription.unsubscribe();
  }

  getRoute(item: ItemType){
    return getRouteBase(item);
  }

  closeModal(){
    var modal = document.getElementById("parent-items-modal");
    modal.classList.remove("show");
    modal.classList.add("hide");
  }
}
