<p-toast ></p-toast>
<div class="title">
  <h2 *ngIf="testItem">{{ testItem.itemNo }}: {{ testItem.name }}</h2>
  <i class="pi lockIcon"
    *ngIf="testItem && testItem.lockedBy !== ''"
    pTooltip="Locked by {{ testItem.lockedBy }} 
              Since {{ testItem.lockedTime | date: 'dd.MM.yyyy HH:mm' }}"
    style="font-size: initial"
    [class]="testItem?.lockedBy
              ? 'checkedOutByYou pi-unlock'
              : 'checkedOutBySomeoneElse pi-lock' "
  ></i>
</div>

<div *ngIf="form" class="test-analysis-page" [ngClass]="{'hide-form': this.hideForm}">
  <div class="test-analysis-form">

      <form (ngSubmit)="onSubmit()" [formGroup]="form">
          <div>
            <div *ngIf="testItem && reviewVisable$ | async">
              <app-review-header [testItem]="testItem"></app-review-header>
            </div>
              <p-panel toggler="header" styleClass="panelHeader" [toggleable]="true" class="grid-column-panel">
                <ng-template pTemplate="header">
                  <p class="boldText">General Information</p>
                </ng-template>
                <div class="panelContentWrapper">
                  <div class="panelContent gridDisplay">
                    <div *ngFor="let field of informationFields" class="field-section">
                      <app-dynamic-form-field [field]="field" [form]="form"
                      ></app-dynamic-form-field>
                    </div>
                  </div>
                  <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
                    <app-review-comments 
                      [itemUid]="testItem.uid" 
                      [itemVersion]="testItem.version"
                      [commentGroup]="ReviewCommentGroup.TA_General"
                      [approval]="approval"
                    >
                  </app-review-comments>
                  </div>
                </div>
              </p-panel>
          </div>
          <div>
              <p-panel toggler="header" styleClass="panelHeader" [toggleable]="true">
                <ng-template pTemplate="header">
                  <p class="boldText">Requirement info</p>
                </ng-template>
                <div class="panelContentWrapper">
                  <div class="panelContent">
                    <div class="vertical-container">
                      <div *ngIf="requirementInfoRequirementField && (requirementsLoadedToForm || addPageActive)"  class="field-section">
                          <app-linked-requirements [field]="requirementInfoRequirementField" [form]="form" 
                          [locked]="!addPageActive && testItem?.lockedBy !== loggedInUsername"></app-linked-requirements>
                      </div>
                      <div *ngIf="requirementInfoSpecificationField && (specificationsLoadedToForm || addPageActive)" class="field-section">
                          <app-specification [field]="requirementInfoSpecificationField" [form]="form" 
                          [locked]="!addPageActive && testItem?.lockedBy !== loggedInUsername"></app-specification>
                      </div>
                    </div>
                  </div>
                  <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
                    <app-review-comments 
                        [itemUid]="testItem.uid" 
                        [itemVersion]="testItem.version"
                        [commentGroup]="ReviewCommentGroup.TA_Description"
                        [approval]="approval">
                    </app-review-comments>
                  </div>
                </div>
              </p-panel>
          </div>
          <div>
              <p-panel toggler="header" styleClass="panelHeader" [toggleable]="true" class="grid-column-panel">
                <ng-template pTemplate="header">
                  <p class="boldText">Risk level attributes</p>
                </ng-template>
                <div class="panelContentWrapper">
                  <div class="panelContent gridDisplay">
                    <div *ngFor="let field of riskLevelAttributeFields" class="field-section">
                      <app-dynamic-form-field [field]="field" [form]="form"
                      ></app-dynamic-form-field>
                    </div>
                  </div>
                  <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
                    <app-review-comments 
                      [itemUid]="testItem.uid" 
                      [itemVersion]="testItem.version"
                      [commentGroup]="ReviewCommentGroup.TA_Risk"
                      [approval]="approval">
                    </app-review-comments>
                  </div>
                </div>
              </p-panel>
          </div>
          <div>
              <p-panel toggler="header" styleClass="panelHeader" [toggleable]="true" class="grid-column-panel">
                <ng-template pTemplate="header">
                  <p class="boldText">Options</p>
                </ng-template>
                <div class="panelContentWrapper">
                  <div class="panelContent gridDisplay">
                    <div *ngFor="let field of optionFields" class="field-section">
                      <app-dynamic-form-field [field]="field" [form]="form"
                      ></app-dynamic-form-field>
                    </div>
                  </div>
                  <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
                    <app-review-comments 
                      [itemUid]="testItem.uid" 
                      [itemVersion]="testItem.version"
                      [commentGroup]="ReviewCommentGroup.TA_Options"
                      [approval]="approval">
                    </app-review-comments>
                  </div>
                </div>
              </p-panel>
          </div>

          <div>
            <p-panel toggler="header" *ngIf="testCaseLinkField && (testCaseLinksLoadedToForm || addPageActive)" [toggleable]="true" class="link-panel">
              <ng-template pTemplate="header">
                <p class="boldText">Test Cases</p>
              </ng-template>
              <div class="panelContent">
                  <div class="field-section">
                    <app-linked-item-list  [field]="testCaseLinkField" [form]="form"
                    [locked]="!addPageActive && testItem?.lockedBy !== loggedInUsername"></app-linked-item-list>
                  </div>
                <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
                  <app-review-comments 
                    [itemUid]="testItem.uid" 
                    [itemVersion]="testItem.version"
                    [commentGroup]="ReviewCommentGroup.TA_TestCases"
                    [approval]="approval">
                  </app-review-comments>
                </div>
              </div>
            </p-panel>
          </div>

          <div>
            <p-panel toggler="header" styleClass="panelHeader" [toggleable]="true" [collapsed]="false" *ngIf="form">
              <ng-template pTemplate="header">
                <p class="boldText">Description</p>
              </ng-template>
              <div class="panelContentWrapper">
                <div class="panelContent">
                  <div class="sdds-on-white-bg m-right">
                    <app-text-editor [description]="testItem?.description" [form]="form" [readOnly]="!addPageActive && testItem?.lockedBy !== loggedInUsername"></app-text-editor>
                </div>
                </div>
                <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
                  <app-review-comments 
                      [itemUid]="testItem.uid" 
                      [itemVersion]="testItem.version"
                      [commentGroup]="ReviewCommentGroup.TA_Description"
                      [approval]="approval">
                  </app-review-comments>
                </div>
              </div>
            </p-panel>
          </div>
      
          <test-item-action [testItem]="testItem" [itemType]="itemType" [changes]="changes"  (checkOutTestItem)="checkOut()" 
          (unlockTestItem)="unlockItem()" class="footer" [actionButton]="actionButtons" [toolTip]="checkOutBtnToolTip"></test-item-action>
      </form>
  </div>

  <div class="test-analysis-properties">
    <app-item-registration-info [item]="testItem"></app-item-registration-info>
  </div>
</div>