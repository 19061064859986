import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ProductPropertiesActions } from "./productProperties.actions";
import { ProductPropertiesStateModel } from "./productProperties.state.model";
import { ChildItem, ParentChildHierarchy } from "src/app/models/parent-child-hierarchy";
import { ProductPropertiesService } from "src/app/services/api/product-properties.service";
import { ProductProperty } from "src/app/models/dto/productProperty";

@State<ProductPropertiesStateModel>({
    name: 'productProperties',
    defaults: {
      productProperties: [],
      productPropertiesHierarchy: [],
    },
  })
  @Injectable()
  export class ProductPropertiesState{
    constructor(private productPropertiesService: ProductPropertiesService) {}

    @Action(ProductPropertiesActions.SetAllProductProperties)
    SetAllProductProperties(ctx: StateContext<ProductPropertiesStateModel>) {
        const state = ctx.getState();
        //dont update state if we have already downloaded items 
        if(state.productProperties !== undefined && state.productProperties.length > 0) return;

        this.productPropertiesService.get().subscribe((productProperties) => {
            ctx.patchState({productProperties: productProperties});
            ctx.patchState({productPropertiesHierarchy: this.parentChildMapperForProducProperties(productProperties)});
          });
    }

    @Selector()
    static getAllProductProperties(state: ProductPropertiesStateModel) {
        return state.productProperties
    }

    @Selector()
    static getAllProductPropertiesHierarchy(state: ProductPropertiesStateModel) {
        return state.productPropertiesHierarchy
    }


    parentChildMapperForProducProperties(item: ProductProperty[]): ParentChildHierarchy[]{
      let result : ParentChildHierarchy[] = [];
      let children = item.filter(x => x.parentUid != '00000000-0000-0000-0000-000000000000');
      let parents = item.filter(x => x.parentUid === '00000000-0000-0000-0000-000000000000');

      parents.forEach((parent) =>{
        var parentChild : ChildItem[] = [];
        var childs : ProductProperty[] = children.filter(x => x.parentUid == parent.uid)
        childs.forEach((child) =>
          parentChild.push({
              value: child.uid.toString(),
              label: child.name,
              parent: undefined
            })
          )
        
        result.push({
          value: parent.uid.toString(),
          label: parent.name,
          items: parentChild
        })
        parentChild.splice;
      })
      return result;
    }
  }