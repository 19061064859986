import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { RouteSegments } from 'src/app/constants/routeConstants';
import { ItemType } from 'src/app/models/enums/itemType';
import { ReviewUser } from 'src/app/models/review/user';
import { AuthService } from 'src/app/services/auth.service';
import { ReviewService } from 'src/app/services/review/review.service';

@Component({
  selector: 'app-send-to-review-modal',
  templateUrl: './send-to-review-modal.component.html',
  styleUrl: './send-to-review-modal.component.scss'
})
export class SendToReviewModalComponent implements OnInit {

  @Input() itemUid: string
  @Input() version: number
  @Input() itemType: ItemType

  itemSuggestions = []
  options = []
  selectedOptions = []
  users: ReviewUser[] = []
  selectedUsers: ReviewUser[] = []
  currentUserName: string = ""
  users$: Observable<ReviewUser[]> 

  constructor (
    private reviewService: ReviewService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.reviewService.loadUsers()
    this.users$ = this.reviewService.users$.pipe(tap((users) => {
      this.users = users
      this.options = this.users.map((user) => { return {
        value: user.id, 
        name: user.name,
        user: user
       }})
      this.itemSuggestions = this.options
    }))
    this.users$.subscribe((result) => {
      console.log(result)
    })
    this.authService.userData$.subscribe((result) => this.currentUserName = result.userName);
    this.reviewService.getUsersObservable().subscribe((result) => {
      console.log(result)
    }) 

  } 

  filterItem(event) {
    console.log(event.query)
    if(event.query)
      this.filterValuesByInput(event.query);
  }

  filterChange(event) {
    this.selectedUsers = this.selectedOptions.map((option) => option.user)
  }

  onSendClick(event) {
    this.reviewService.sendToReview(this.itemUid, this.version, this.selectedOptions.map(x => x.value), this.currentUserName, this.itemType).subscribe(
      (result) => {
        this.closeSendToReviewModal()
      }
    )
  }

  closeSendToReviewModal(){
    var modal = document.getElementById("send-to-review-modal");
    modal.classList.remove("show");
    modal.classList.add("hide");
    this.router.navigate([RouteSegments.REVIEW])
  }

  private filterValuesByInput(query: string) {
    if(!query || query == '')
      return
    
    this.itemSuggestions = this.options.filter(option => option.value.toLowerCase().includes(query.toLowerCase()) || option.name.toLowerCase().includes(query.toLowerCase()));
  }

}
