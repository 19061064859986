import { Component, Input, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MessageService, TreeNode } from 'primeng/api';
import { LinkValidationService } from 'src/app/services/link-validation.service';
import { formField } from 'src/app/models/formField';
import { Scenario, UseCase, UserFunction, UserFunctionBase } from 'src/app/models/dto/user-function-dto';
import { UserFunctionType } from 'src/app/models/enums/userFunctionType';

@Component({
  selector: 'app-user-functions',
  templateUrl: './user-functions.component.html',
  styleUrl: './user-functions.component.scss',
  providers: [MessageService]
})
export class UserFunctionsComponent {
  @Input() field!: formField;
  @Input() form!: FormGroup;
  @Input() locked: boolean;

  userFunctionsTree: TreeNode<UserFunctionBase>[] = [];
  selectedNode: TreeNode<UserFunctionBase>;
  ufTypes = UserFunctionType;

  constructor(private linkValidationService: LinkValidationService,
    private messageService: MessageService,
    ) {}

  ngOnInit(): void {
    if (!this.field || !this.form)
      return; 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.field || !this.form)
      return; 

    let value = this.form.controls[this.field.name]?.value;
    if (value)
      this.populateTree(value);
  }

  private populateTree(userFunctions: UserFunction[]){
    this.userFunctionsTree = [];

    userFunctions?.forEach(uf => {
      let ufNode = this.convertUserFunctionToNode(uf, UserFunctionType.UserFunction);
      ufNode.children = [];

      uf.useCases?.forEach(uc => {
        let ucNode = this.convertUserFunctionToNode(uc, UserFunctionType.UseCase);
        ucNode.children = [];
        
        uc.scenarios?.forEach(scenario => {
          let scenarioNode = this.convertUserFunctionToNode(scenario, UserFunctionType.Scenario);
          ucNode.children.push(scenarioNode);
        });

        ufNode.children.push(ucNode);
      });

      this.userFunctionsTree.push(ufNode);
    });
  }

  addItemToRoot(uf: UserFunction) {
    this.addUfHierarchyToParent(uf, undefined, UserFunctionType.UserFunction);
  }

  addItemToSelectedNode(uf: UserFunction) {
    if((!this.selectedNode.parent && this.selectedNode.data.id != uf.id) 
       || (this.selectedNode.parent &&  this.selectedNode.parent.data.id != uf.id)) 
        this.showErrorToast('Selected link must be of same User Function family.');
    else
      this.addUfHierarchyToParent(uf, this.selectedNode, UserFunctionType.UserFunction);
  }

  addUfHierarchyToParent(link: UserFunction | UseCase | Scenario, parent: TreeNode, userFunctionType: UserFunctionType) {
    this.linkValidationService.validateLink(link.id.toString(), this.getKeysFromAllNodes(this.userFunctionsTree), this.field.name).then(errorMessage => {
      var child = this.getChildByUfType(link, userFunctionType);

      if(errorMessage && !child) {
        this.showErrorToast(errorMessage);
        return;
      }

      var nextParentNode: TreeNode = undefined;
      if(errorMessage) // does node for link already exist
        nextParentNode = this.tryFindNodeInTreeById(link.id, this.userFunctionsTree);
      else
        nextParentNode = this.addToParent(parent, link, userFunctionType);

      if(child)
        this.addUfHierarchyToParent(child, nextParentNode, this.getNextUfType(userFunctionType));
    });  
  }

  private getChildByUfType(link: UserFunction | UseCase | Scenario, userFunctionType: UserFunctionType) {
    switch(userFunctionType) {
      case UserFunctionType.UserFunction:
        return (link as UserFunction)?.useCases?.[0];
      case UserFunctionType.UseCase:
        return (link as UseCase)?.scenarios?.[0];
      case UserFunctionType.Scenario:
        return undefined;
    }
  }

  private getNextUfType(userFunctionType: UserFunctionType) {
    switch(userFunctionType) {
      case UserFunctionType.UserFunction:
        return UserFunctionType.UseCase;
      case UserFunctionType.UseCase:
        return UserFunctionType.Scenario;
      case UserFunctionType.Scenario:
        return UserFunctionType.Scenario;
    }
  }

  private tryFindNodeInTreeById(id: number, tree: TreeNode[]): TreeNode {
    if(!tree)  
      return undefined;

    for (const node of tree) {
      var nodeFound = this.tryFindNodeById(id, node);
      if(nodeFound)
        return nodeFound;
    }
    return undefined;
}

  private tryFindNodeById(id: number, node: TreeNode): TreeNode {
    if(node.data.id == id)  
      return node;

    if(!node.children)
      return undefined;
    for (const child of node.children) {
      return this.tryFindNodeById(id, child);
    }

    return undefined;
  }

  private addToParent(parent: TreeNode<any>, child: UserFunctionBase, userFunctionType: UserFunctionType) {
    //make a copy of array because html is stupid
    this.userFunctionsTree = this.userFunctionsTree.concat([]);

    let newNode = this.convertUserFunctionToNode(child, userFunctionType);
    if(parent) {
      if(!parent?.children)
        parent.children = [];

      parent.children.push(newNode);

      parent.expanded = false;
      parent.expanded = true;
    }
    else {
      this.userFunctionsTree.push(newNode);
    }

    this.saveValuesToForm();
    return newNode;
  }

  private convertUserFunctionToNode(source: UserFunctionBase, userFunctionType: UserFunctionType): TreeNode<UserFunctionBase> {
    if(!source)
      return undefined; 

    let node: TreeNode<UserFunctionBase> = {
      key: source.id.toString(),
      label: userFunctionType,
      data: source,
    };

    return node;
  }
  
  private getKeysFromAllNodes(tree: TreeNode<UserFunctionBase>[]) {
    let result: string[] = [];

    tree.forEach(node => {
      result.push(node.data.id.toString());
      let children = [];
      node.children?.forEach(child => children = children.concat(this.getKeysFromAllNodes([child])));
      result = result.concat(children);
    })

    return result;
  }

  private showErrorToast(message: string) {
    this.messageService.add({severity:'error', summary: 'Not allowed!', detail: message, icon: 'pi-times'});
  }

  rememberSelectedNode(node){
    this.selectedNode = node;
  }

  saveValuesToForm() {
    this.form.patchValue({ [this.field.name]: this.convertNodesToUserFunctionDtos() });
  }

  private convertNodesToUserFunctionDtos(){
    let result: UserFunction[] = [];
    this.userFunctionsTree.forEach(ufNode => {
      if(ufNode && ufNode.data){

        let uf: UserFunction = {id: ufNode.data.id, name: ufNode.data.name, useCases: []};

        ufNode.children?.forEach(ucNode => {
          let uc: UseCase = {id: ucNode.data.id, name: ucNode.data.name, scenarios: []};
          uf.useCases.push(uc);

          ucNode.children?.forEach(scnNode => {
            let scenario: Scenario = {id: scnNode.data.id, name: scnNode.data.name};
            uc.scenarios.push(scenario);
          });
        });
        result.push(uf)
      }
    });

    return result;
  }

  removeItem(node) {
    //make a copy of array because html is stupid
    this.userFunctionsTree = this.userFunctionsTree.concat([]);

    if(!node.parent) {
      let treeIndex = this.userFunctionsTree.indexOf(node);
      this.userFunctionsTree.splice(treeIndex, 1);
    }
    else {
      let siblingNodes = node.parent.children;
      siblingNodes.splice(siblingNodes.indexOf(node), 1);
    }
    this.userFunctionsTree = this.userFunctionsTree.concat([]);

    this.saveValuesToForm();
  }
}

