export namespace OrganizationalGroupsActions {
  export class SetAllOrganizationalGroups {
    static readonly type = '[OrganizationalGroups] SetAllOrganizationalGroups';
    constructor() {}
  }

  export class SetOrganizationalGroupByUsername {
    static readonly type =
      '[OrganizationalGroups] SetOrganizationalGroupByUsername';
    constructor(public username: string) {}
  }
}
