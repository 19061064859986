import { gql } from "apollo-angular";

export const CREATE_APPROVAL_MUTATION = gql`mutation configuratorCreateApprovals ($Approvals: [CreateApprovalInput]) {
    configuratorCreateApprovals (Approvals: $Approvals) {
        ActionBy
        ActionTime
        ApprovalLevel
        ApproverType
        DueDate
        Id
        ItemName
        ItemTypeId
        ItemUid
        ItemVersion
        NumberOfComments
        ParentId
        ParentTypeId
        RegBy
        RegTime
        RequestComment
        Status
        TypeName
        ValidTo
        Reviewers
    }
  }
  `

  export const UPDATE_APPROVAL_STATUS_MUTATION = gql`mutation configuratorChangeStatus ($ApprovalChangeStatusInput: ApprovalChangeStatusInput) {
    configuratorChangeStatus (ApprovalChangeStatusInput: $ApprovalChangeStatusInput)
  }
  `

  export const CREATE_APPROVAL_COMMENT_MUTATION = gql`mutation configuratorCreateReviewComment ($ReviewComment: CreateReviewCommentInput) {
        configuratorCreateReviewComment (ReviewComment: $ReviewComment) {
            Id
            ItemKey
            ItemVersion
            ParentId
            PropertyKey
            PropertyName
            RegBy
            RegTime
            Status
            Text
            ValidTo
            ValidToRegBy
            CommentGroup
        }
    }
  `

  export const UPDATE_APPROVAL_COMMENT_MUTATION = gql`mutation configuratorUpdateReviewComment ($ReviewComment: UpdateReviewCommentInput) {
    configuratorUpdateReviewComment (ReviewComment: $ReviewComment) {
        Id
        ItemKey
        ItemVersion
        ParentId
        PropertyKey
        PropertyName
        RegBy
        RegTime
        Status
        Text
        ValidTo
        ValidToRegBy
        CommentGroup
    }
}
`


  