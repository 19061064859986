<div class="comments-wrapper">
  @for(comment of comments$ | async; track comment.id) {
  @if(comment.createdBy != '')
  {<div class="comment-createdby">
    Created by: {{comment.createdBy}}
  </div>
  <div class="comment-createdat">
    Created at: {{comment.createdAt | date: "yy/mm/dd HH:mm"}}
  </div>}
  <div class="comment">
    <textarea rows="5" cols="30" pInputTextarea (input)="commentChanged(comment)" [(ngModel)]="comment.text"
      [disabled]="!enableComments">
    </textarea>

    @if(isCommentHandledEnabled(comment)) {
      <div class="sdds-toggle" tabindex="0" style="width: 66rem">
        <input type="checkbox" class="sdds-toggle-input" [(ngModel)]="comment.handled"
          (change)="onCommentHandledToggle(comment, $event)" pTooltip="Handling comment" tooltipPosition="bottom"
          [disabled]="!isCommentHandledEnabled(comment)" />
        <span class="sdds-toggle-switch"></span>
        <label class="sdds-toggle-label">Comment handled</label>
      </div>
    }
    @if(enableComments) {
      <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm" (click)="saveComment(comment)">
        Save
      </button>
    }
  </div>
  }
</div>