<div *ngIf="mainPolarionItem">

    <div>
    <p>{{mainItemTypeName}} <strong>{{mainPolarionItem.polarionId}}: {{mainPolarionItem.title}}</strong></p>
    <p>Irma version: <strong>{{mainPolarionItem.irmaVersion}}</strong></p>
    <p></p>
    <p>{{textMessage}}</p>
    </div>

    <div class="table">
        <p-table [value]="connectedPolarionItems" [(selection)]="selectedItems" (onRowSelect)="onRowSelect($event)" [scrollable]="true" scrollHeight="100rem">
          <ng-template pTemplate="header">
            <tr>
              <th style="flex: 0 0 110rem !important; justify-content: left; text-align: left;">Title</th>
              <th style="flex: 0 0 20rem !important">IRMA version</th>
              <th style="flex: 0 0 15rem !important">ASIL</th>
              <th style="flex: 0 0 20rem !important">Status</th>
              <th style="flex: 0 0 20rem !important" *ngIf="isRequirement">Maturity status</th>
              <th style="flex: 0 0 20rem !important" *ngIf="!isRequirement">Legislation</th>
              <th style="flex: 0 0  !important">Add</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td style="flex: 0 0 110rem !important; justify-content: left; text-align: left;">
                <a [href]="polarionItemUrl.replace('{0}', item.polarionId).replace('{1}', item.revision)">{{ item.polarionId }} - {{ item.title }}</a></td>
              <td style="flex: 0 0 20rem !important">{{ item.irmaVersion }}</td>
              <td style="flex: 0 0 15rem !important">{{ item.asil }}</td>
              <td style="flex: 0 0 20rem !important">{{ item.status }}</td>
              <td style="flex: 0 0 20rem !important" *ngIf="isRequirement">{{ item.maturityStatus }}</td>
              <td style="flex: 0 0 20rem !important" *ngIf="!isRequirement">{{ item.legislation }}</td>
              <td style="flex: 0 0 15rem !important">
                <p-tableCheckbox *ngIf="isRequirement" [value]="item"></p-tableCheckbox>
                <p-tableRadioButton *ngIf="!isRequirement" [value]="item"></p-tableRadioButton>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No items found.</td>
            </tr>
        </ng-template>
        </p-table>
      </div>

    <div class="action-buttons">

        <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm" data-dismiss-modal (click)="addConnectedItems()">
            Save
        </button>

        <button type="button" class="sdds-btn sdds-btn-secondary sdds-btn-sm" data-dismiss-modal (click)="closeModal()">
            Cancel
        </button>

    </div>

</div>