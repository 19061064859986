import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Comparison } from 'src/app/models/dto/polarion/comparison';
import { ComparisonResult } from 'src/app/models/dto/polarion/comparisonResult';
import { ComparisonService } from 'src/app/services/api/comparison.service';
import { cloneDeep } from 'lodash';
import { polarionIdRegexPattern } from 'src/app/services/helpers/patternHelper';

@Component({
  selector: 'app-rms-comparison',
  templateUrl: './rms-comparison.component.html',
  styleUrls: ['./rms-comparison.component.scss']
})
export class RmsComparisonComponent  implements OnInit {
  form: FormGroup  = new FormGroup({});;
  errorMessage: string = null;
  comparison: Comparison;
  comparisonToView: Comparison;
  result: ComparisonResult[];
  
  constructor(private comparisonService: ComparisonService, private fb: FormBuilder) {
    this.comparisonToView = cloneDeep(this.comparison);
  }
  
  ngOnInit(): void {
    this.comparison = {
      itemOne: {
        polarionId: '',
        irmaVersion: '',
      },
      itemTwo: {
        polarionId: '',
        irmaVersion: '',
      }
    }

    this.form = this.fb.group({
      specificationOne: ['ID-', [Validators.required, Validators.pattern(polarionIdRegexPattern())]],
      specificationTwo: ['ID-', [Validators.required, Validators.pattern(polarionIdRegexPattern())]],
      irmaOne: ['', Validators.required],
      irmaTwo: ['', Validators.required]
    });
  }

  handleInputChangeIRMA1(event) {
    this.irmaInputHandling(event);
    if(this.form.controls['irmaOne'].valid)
      this.comparison.itemOne.irmaVersion = event.target.value;
  }

  handleInputChangeIRMA2(event) {
    this.irmaInputHandling(event);
    if(this.form.controls['irmaTwo'].valid)
      this.comparison.itemTwo.irmaVersion = event.target.value;
  }

  private irmaInputHandling(event) {
    const input = event.target.value;
    let filtered = input.replace(/[^0-9]/g, '')
    event.target.value = filtered;
  }

  handleInputChangeID1(event) {
    if(this.form.controls['specificationOne'].valid) 
      this.idInputHandling(event, "One");
  }

  handleInputChangeID2(event) {
    if(this.form.controls['specificationTwo'].valid)
      this.idInputHandling(event, "Two");
  }

  idInputHandling(event, whichSpec: string) {
      let idValue = event.target.value.toString().trim().split('-')
      if (idValue.length == 1 && idValue[0].length > 2) {
        idValue = idValue.toString().substring(idValue[0].length - 6);
        switch (whichSpec) {
          case "One": {
            this.comparison.itemOne.polarionId = "ID-" + idValue;
            break;
          }
          case "Two": {
            this.comparison.itemTwo.polarionId = "ID-" + idValue;
            break;
          }
        }
      }
      else {
        switch (whichSpec) {
          case "One": {
            this.comparison.itemOne.polarionId = "ID-" + idValue[idValue.length - 1];
            break;
          }
          case "Two": {
            this.comparison.itemTwo.polarionId = "ID-" + idValue[idValue.length - 1];
            break;
          }
        }
      }
  }

  onCompare() {
    this.errorMessage = null;
    if (!this.result) {
      this.sendApiCall();
    } else {
      this.result = null
      this.sendApiCall();
    }
  }

  onSwap(){
    this.form.setValue({specificationOne:this.comparison.itemTwo.polarionId,
      specificationTwo:this.comparison.itemOne.polarionId,
      irmaOne: this.comparison.itemTwo.irmaVersion,
      irmaTwo: this.comparison.itemOne.irmaVersion});
    //swap ID
    this.comparison.itemOne.polarionId = this.comparison.itemOne.polarionId + this.comparison.itemTwo.polarionId;
    this.comparison.itemTwo.polarionId = this.comparison.itemOne.polarionId.substring(0, (this.comparison.itemOne.polarionId.length - this.comparison.itemTwo.polarionId.length))
    this.comparison.itemOne.polarionId = this.comparison.itemOne.polarionId.substring(this.comparison.itemTwo.polarionId.length)
    //swap IRMA
    this.comparison.itemOne.irmaVersion = this.comparison.itemOne.irmaVersion + this.comparison.itemTwo.irmaVersion;
    this.comparison.itemTwo.irmaVersion = this.comparison.itemOne.irmaVersion.substring(0, (this.comparison.itemOne.irmaVersion.length - this.comparison.itemTwo.irmaVersion.length))
    this.comparison.itemOne.irmaVersion = this.comparison.itemOne.irmaVersion.substring(this.comparison.itemTwo.irmaVersion.length)
  }

  sendApiCall() {
    this.comparisonToView = cloneDeep(this.comparison);
    this.comparisonService.compareSpecifications(this.comparison.itemOne.polarionId, this.comparison.itemTwo.polarionId, this.comparison.itemOne.irmaVersion,  this.comparison.itemTwo.irmaVersion).subscribe(
      {
        next: (results) => this.result = results?.result,
        error: (err: any) => {
          this.errorMessage = err
        }
      });
  }
}
