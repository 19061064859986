import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, delay, map, Observable, of, switchMap, tap } from "rxjs";
import { Approval } from "src/app/models/review/approval";
import { ReviewComment } from "src/app/models/review/comment";
import { ReviewUser } from "src/app/models/review/user";
import { getDummyApprovals, getDummyComments, getDummyUsers } from "./review.dummy";
import { ItemType } from "src/app/models/enums/itemType";
import { LaunchdarklyService } from "../api/launchdarkly.service";
import { ApolloClientService } from "../api/apollo-client.service";
import { GraphQLResponseOutput } from "src/app/graphQL/models/output/graphql-response-output";
import { CREATE_APPROVAL_COMMENT_MUTATION, CREATE_APPROVAL_MUTATION, UPDATE_APPROVAL_COMMENT_MUTATION, UPDATE_APPROVAL_STATUS_MUTATION } from "src/app/graphQL/mutations/review-mutations";
import { ApprovalChangeStatusInput, ApprovalsContextInput, CreateApprovalInput, CreateReviewCommentInput, GetReviewCommentListInput, UpdateReviewCommentInput } from "src/app/graphQL/models/input/graphql-review-input";
import { GET_APPROVAL_COMMENTS_QUERY, GET_APPROVAL_QUERY, GET_APPROVALS_QUERY, GET_USERS_QUERY } from "src/app/graphQL/queries/review-queries";
import { GraphQlApprovalOutput, GraphQlCommentObjectOutput, GraphQlCommentOutput, GraphQlPagedOutput, GraphQlUserOutput } from "src/app/graphQL/models/output/graphql-review-output";
import { convertGraphQlApprovalOutputToApproval, convertGraphQlReviewCommentOutputToReviewComment, convertGraphQlUserOutputToReviewUser } from "src/app/graphQL/mappers/review.mappers";
import { ItemTypeMapper } from "../mappers/itemTypeMapper";
import { BaseTestItemDto } from "src/app/models/dto/Base-Test-item-dto";

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  // true if review is enabled on a global level from launchDarkly
  reviewEnabled: boolean = false
  reviewEnabled$: Observable<boolean> = this.launchdarklyService.launchDarklyFlags$.pipe(
    map((response) => response && response['review-in-config']),
    tap((reviewInConfig) => this.reviewEnabled = reviewInConfig)
  )

  reviewOnSubject = new BehaviorSubject<boolean>(true)
  reviewOn$ = this.reviewOnSubject.asObservable()

  // Combine reivew enalbled and turned on
  reviewVisable$ = combineLatest([this.reviewEnabled$, this.reviewOn$]).pipe(
    map(([re, ro]) => re && ro)
  );

  comments$: Observable<any>
  comments: ReviewComment[] = []

  users$: Observable<ReviewUser[]>

  private allCommentsHandledSubject = new BehaviorSubject<boolean>(false);
  allCommentsHandled$ = this.allCommentsHandledSubject.asObservable();

  approvalSubject = new BehaviorSubject<Approval>(null);
  approval$ = this.approvalSubject.asObservable();
  approval: Approval;

  approvalsSubject = new BehaviorSubject<Approval[]>(null)
  approvals$ = this.approvalsSubject.asObservable()
  approvals: Approval[] = getDummyApprovals()

  constructor (
    private launchdarklyService: LaunchdarklyService,
    private apolloClientService: ApolloClientService
  ) {
    this.reviewOn$.subscribe((result) => {
      console.log(result)
    })
    this.reviewEnabled$.subscribe((result) => {
      console.log(result)
    })
    this.reviewVisable$.subscribe((result) => {
      console.log(result)
    })
  }

  setReviewOn(value: boolean) {
    this.reviewOnSubject.next(value && this.reviewEnabled);
  }

  loadComments(itemUid: string, version: number) {
    // loaded commments will come from graphql observable and local comment value will be tapped. 
    this.comments$ = this.getApprovalCommentsObservable(itemUid, version)
  }

  clearComments() {
    // this.commentsSubject.next(
    //   this.comments.filter((comment) => !comment.newComment)
    // )

    this.comments = this.comments.filter(comment => !comment.newComment);
    // this.commentsSubject.next(this.comments);
    this.checkAllCommentsHandled();

    console.log('Review service clearComments');
  }

  sendToReview(itemUid: string, version: number, reviewerIds: string[], approverId: string, itemType: ItemType) {
    const newApproval: CreateApprovalInput = {
      ItemUid: itemUid,
      ItemVersion: version,
      ItemTypeId: ItemTypeMapper.mapReverse(itemType),
      ActionBy: approverId,
      RegBy: approverId,
      ApprovalLevel: 0,
      Status: 0,
      ApproverType: "",
      Reviewers: reviewerIds.join(",")
    }
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorCreateApproval: GraphQLResponseOutput}>({
          mutation: CREATE_APPROVAL_MUTATION,
          variables: {Approvals: [newApproval] }
        })
      ),
      map(result => {
        console.log(result)
        return result
      })
    )
    // return this.createApproval(newApproval)
  }

  loadUsers() {     
    this.users$ = this.getUsersObservable()
  }

  // todo: why mixing terminology status and handled? use just handled
  setCommentHandledStatus(commentUid: number, handeled: boolean): Observable<any> {
    const comment = this.comments.find(c => c.id === commentUid);
    if (comment) {
      comment.handled = handeled;
      // this.commentsSubject.next(this.comments);
      this.checkAllCommentsHandled();
    }
    console.log("Review service - setCommentHandledStatus", comment);
    return of(true).pipe(delay(100))
  }


  saveComment(itemUid: string, itemVerion: number, comment: ReviewComment, userId: string): Observable<any> {
    const newComment: CreateReviewCommentInput = {
      ItemKey: itemUid,
      ItemVersion: itemVerion,
      Text: comment.text,
      Status: 0,
      RegBy: userId,
      CommentGroup: comment.group
    }
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorCreateReviewComment: GraphQlCommentOutput}>({
          mutation: CREATE_APPROVAL_COMMENT_MUTATION,
          variables: {ReviewComment: newComment }
        })
      ),
      map(result => {
        console.log(result)
        return result.data.configuratorCreateReviewComment
      })
    )
  }

  updateComment(itemUid: string, itemVerion: number, comment: ReviewComment, userId: string): Observable<any> {
    const newComment: UpdateReviewCommentInput = {
      Id: comment.id,
      ItemKey: itemUid,
      ItemVersion: itemVerion,
      Text: comment.text,
      Status: comment.handled ? 1 : 0,
      RegBy: userId,
      CommentGroup: comment.group 
    }
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorCreateApprovalComment: GraphQLResponseOutput}>({
          mutation: UPDATE_APPROVAL_COMMENT_MUTATION,
          variables: {ReviewComment: newComment }
        })
      ),
      map(result => {
        console.log(result)
        return result
      })
    )
  }

  // createApproval (approval: Approval) {
  //   return of(approval).pipe(
  //     delay(100),
  //     tap((app) => {
  //       this.approvals.push(app)
  //       this.approvalsSubject.next(this.approvals)
  //     })
  //   )
  // }
  updateApprovalStatus (approval: Approval, userName: string) {
    const statusChange: ApprovalChangeStatusInput = {
      ApprovalId: approval.id,
      ApprovalStatus: approval.approvalStatus,
      ActionBy: userName
    }
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorCreateApproval: GraphQLResponseOutput}>({
          mutation: UPDATE_APPROVAL_STATUS_MUTATION,
          variables: {ApprovalChangeStatusInput: statusChange }
        })
      ),
      map(result => {
        console.log(result)
        return result
      })
    )
  }

  getApprovalsObservable (filter: ApprovalsContextInput) {
    const approvalsContextInput: ApprovalsContextInput = filter
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorPagedApprovalList: GraphQlPagedOutput<GraphQlApprovalOutput>}>({
          query: GET_APPROVALS_QUERY,
          variables: {ApprovalsContextInput: approvalsContextInput }
        })
      ),
      map(result => {
        const loadedResults = result.data.configuratorPagedApprovalList
        let approvals: Approval[] = []
        if (loadedResults && loadedResults.Result) {
          approvals = loadedResults.Result.map((item) => convertGraphQlApprovalOutputToApproval(item))
        }
        return approvals
      })
    )
  }

  getApprovalObservable (id: number) {
    const approvalsContextInput: ApprovalsContextInput = {
      Id: id
    }
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorApprovalById: GraphQlApprovalOutput}>({
          query: GET_APPROVAL_QUERY,
          variables: {Id: id }
        })
      ),
      map(result => {
        const loadedResults = result.data.configuratorApprovalById
        let approval: Approval
        if (loadedResults ) {
          approval = convertGraphQlApprovalOutputToApproval(loadedResults)
        }
        return approval
      })
    )
  }

  updateApproval(updatedApproval: Approval) {
    const index = this.approvals.findIndex(approval => approval.id === updatedApproval.id);
    if (index !== -1) {
      this.approvals[index] = updatedApproval;
      this.approvalsSubject.next(this.approvals);
    }
  }

  getApprovalCommentsObservable (itemUid: string, version: number) {
    const getReviewCommentListInput: GetReviewCommentListInput = {
      ItemUid: itemUid,
      Version: version
    }
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorReviewCommentList: GraphQlCommentObjectOutput}>({
          query: GET_APPROVAL_COMMENTS_QUERY,
          variables: {GetReviewCommentListInput: getReviewCommentListInput }
        })
      ),
      map(result => {
        const loadedResults = result.data.configuratorReviewCommentList.Comments
        let comments: ReviewComment[] = []
        if (loadedResults) {
          comments = loadedResults.map((item) => convertGraphQlReviewCommentOutputToReviewComment(item))
        }
        return comments
      })
    )
  }

  getUsersObservable () {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorUsers: GraphQlUserOutput[]}>({
          query: GET_USERS_QUERY
        })
      ),
      map(result => {
        const loadedResults = result.data.configuratorUsers
        let users: ReviewUser[] = []
        if (loadedResults) {
           users = loadedResults.map((item) => convertGraphQlUserOutputToReviewUser(item))
        }
        return users
      })
    )
  }

  private checkAllCommentsHandled() {
    const allHandled = this.comments.every(comment => comment.handled === true);
    this.allCommentsHandledSubject.next(allHandled);
    console.log("Review service - checkAllCommentsHandled - all handeled", allHandled);
  }
// #endRegion
}