<div class="review-header">
  <div class="item-info-container">
    <div class="circle-badge">
      <span class="badge-content">TC-12354</span>
    </div>
    <p>
      {{ testItem?.name }}
    </p>
    <div class="ready-for-review">
      {{ approvalStatus[approval?.approvalStatus] }}
    </div>
  </div>
  <div class="review-info-container">
    <div class="fields-container">
      <div
        class="sdds-on-white-bg m-right approval-info-label"
        style="width: fit-content"
      >
        <sdds-textfield
          ngDefaultControl
          type="text"
          [id]="approval?.regBy"
          formControlName="Approver"
          [placeholder]="approval?.regBy"
          [pTooltip]="'Approver'"
          tooltipPosition="bottom"
          [disabled]="true"
        >
          <span
            slot="sdds-label"
            class="textfield-float-label approver-container"
            style="width: fit-content"
          >
            Approver
          </span>
        </sdds-textfield>
      </div>
      <div class="sdds-on-white-bg m-right">
        <sdds-textfield
          ngDefaultControl
          type="text"
          [id]="'reviewer'"
          [formControlName]="'Rewiewers'"
          [placeholder]="getReviewersString()"
          [pTooltip]="'Reviewer'"
          tooltipPosition="bottom"
          [disabled]="true"
          [ngModel]="approval?.reviewers"
        >
          <span
            slot="sdds-label"
            class="textfield-float-label reviewers-container"
          >
            Reviewer
          </span>
        </sdds-textfield>
      </div>
      <div class="sdds-toggle" tabindex="0">
        <input
          type="checkbox"
          class="sdds-toggle-input"
          [id]="'reviewCompleted'"
          [formControlName]="'Review Completed'"
          [pTooltip]="'Review Completed'"
          tooltipPosition="bottom"
          [checked]="approval.approvalStatus == 4"
          (change)="handleReviewCompleted($event)"
        />
        <span class="sdds-toggle-switch"></span>
        <label class="sdds-toggle-label" [attr.for]="'Review Completed'">
          Review Completed
        </label>
      </div>
    </div>
    <div class="review-buttons-container">
      <button
        class="add-comments-button p-element sdds-btn sdds-btn-primary sdds-btn-sm ng-star-inserted"
        [disabled]="addCommentButtonDisabled"
        (click)="onAddCommentButtonClick()"
      >
        Add Comments
      </button>
      <button
        class="clear-comments-button p-element sdds-btn sdds-btn-primary sdds-btn-sm ng-star-inserted"
        [disabled]="clearButtonDisabled"
        (click)="onClearCommentsButtonClick()"
      >
        Clear Comments
      </button>
      <button
        class="save-comments-button p-element sdds-btn sdds-btn-primary sdds-btn-sm ng-star-inserted"
        [disabled]="saveButtonDisabled"
        (click)="onSaveCommentsButtonClick()"
      >
        Save Comments
      </button>
    </div>
    <app-review-comments
      class="general-comment-wrapper"
      [itemUid]="testItem.uid"
      [itemVersion]="testItem.version"
      [commentGroup]="ReviewCommentGroup.General"
      [enableComments]="enableReviewComment"
      [approval]="approval"
    ></app-review-comments>
    <div></div>
  </div>
</div>
