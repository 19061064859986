export function polarionIdRegexPattern() : RegExp {
    return /^(?:ID-|ID|I|-)?\d{1,6}$/i;
  }

export function irmaVersionRegexPattern() : RegExp {
    return /^(?:[Vv])?\d{3}$/;
}

export function areOfSameFpcFamily(fpcCodeOne: string, fpcCodeTwo: string) : boolean {
  var fpcFamilyNoOne = fpcCodeOne.match(/\d/g).join("");
  var fpcFamilyNoTwo = fpcCodeTwo.match(/\d/g).join("");

  return fpcFamilyNoOne === fpcFamilyNoTwo;
}