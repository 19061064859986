//obsolete
export enum SystemTypeId {
  ApprovalLevels = 4,
  Ecu = 2,
  Sop = 6,
  TestRound = 7,
  OrganizationalGroup = 108,
  TestCase = 109,
  TestSuite = 110,
  TestEnvironment = 116,
  LinkTestSuiteTestCase = 120,
  Type = 128,
  TestCaseAlternative = 133,
  TestSuiteAlternative = 135,
  LinkTestSuiteTestSuite = 170,
  ProductProperty = 180,
  UserFunction = 188,
  LinkTestCaseGroupOwner = 192,
  TestObject = 194,
  LinkTestSuiteGroupOwner = 197,
  LinkTestCaseUserFunction = 199,
  PolarionRequirement = 226,
  Lnk_TCAlternative_PolarionRequirement = 227,
  TestAnalyze = 236,
  TestAnalyzeAlternative = 237,
  LinkTestAnalyzePolarionRequirement = 238,
  LinkTestAnalyzeAlternativeTestCase = 240,
  Variant = 243,
  LinkTestAnalyzeAlternativeOwner = 258,
  LinkTestAnalyzeAlternativeTestedByOrganizationalGroup = 266,
  TestLevel = 267,
  ExcludedForTest = 281,
  LinkTestAnalyzeAlternativeExcludedForTest = 282,
  UseCase = 306,
  Scenario = 307,
  TestItemApproval = 310,
  ReviewRole = 316,
  PythonScript  = 318,
  GitLabScript = 325,
  LinkTestCaseAlternativeGitLabScript = 326,
  OffboardComponent = 340,
  PolarionSpecification = 362,
  LinkTestAnalyzeAlternativePolarionSpecification = 363,
  RmsSpecificationCoverage = 382,
  RmsSpecificationCoverageRule = 383,
  RmsSpecificationCoverageSpecification = 384,
  LinkComparisonPolarionSpecification = 405,
  RmsSpecificationComparison = 406,
  TestSpecification = 410
}
