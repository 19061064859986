import { ItemType } from "src/app/models/enums/itemType";

export class ItemTypeMapper {
    public static map(itemTypeId: number): ItemType {
        switch(itemTypeId) {
            case 109: return ItemType.TestCase;
            case 110: return ItemType.TestSuite;
            case 236: return ItemType.TestAnalysis;
            case 382: return ItemType.TestCoverage;
            case 410: return ItemType.TestSpecification;
            default: return undefined;
        }
    }

    public static mapReverse(itemType: ItemType): number {
        switch(itemType) {
            case ItemType.TestCase: return 109;
            case ItemType.TestSuite: return 110;
            case ItemType.TestAnalysis: return 236;
            case ItemType.TestCoverage: return 382;
            case ItemType.TestSpecification: return 410;
            default: return undefined;
        }
    }
}