import { gql } from "apollo-angular";

export const CREATE_TEST_SUITE_MUTATION = gql`mutation configuratorCreateTestSuite($TestSuite: TestSuiteInput) {
    configuratorCreateTestSuite(TestSuite: $TestSuite) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `

export const UPDATE_TEST_SUITE_MUTATION = gql`mutation configuratorUpdateTestSuite($TestSuite: TestSuiteInput) {
    configuratorUpdateTestSuite(TestSuite: $TestSuite) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `
  

export const DELETE_TEST_SUITE_MUTATION = gql`mutation configuratorDeleteTestSuite($Uid: String!) {
    configuratorDeleteTestSuite(Uid: $Uid)
  }
  `
  