export const environment = {
  IsProduction: false,
  IsHero: false,
  deliveryDatabaseDataApiUrl: 'https://api-ddb-data.tms.test.scania.com/',
  coreDataApiUrl: 'https://api-core-data.tms.test.scania.com/api/',
  approvalsApiUrl: 'https://testlinker-review-dev.scania.com/api/v1/',
  testLinkerDataApiUrl: 'https://api-testlinker-data.tms.test.scania.com/api/',
  polarionDataApiUrl: 'https://api-polarion.tms.test.scania.com/api/',
  environment: 'dev',
  launchDarklyClientId: "664590c02c638010dc55095f",
};

export const polarion = {
  itemWithNewestRevisionUrl : `https://polarion-dev.scania.com/polarion/#/workitem?id={0}`,
  itemWithRevisionUrl : `https://polarion-dev.scania.com/polarion/#/workitem?id={0}&revision={1}`,
};

// export const Config = {
//   colaConfig: {
//     disabled: false,
//     redirectUrl: 'https://start.testtools.dev.scania.com.cn/login'
//   }
// }
 
// export const AbstractionLayerConfig = {    
//   stagingUri: 'https://fabpu7q5onailaaz3eb2jq2lm4.appsync-api.cn-northwest-1.amazonaws.com.cn/graphql',
//   releaseUri: 'https://wjbw5doijnhwfksoq3bwv7cyn4.appsync-api.cn-northwest-1.amazonaws.com.cn/graphql',
// }

export const Config = {
  colaConfig: {
    disabled: false,
    redirectUrl: 'https://testtools.testtools.devtest.gf.aws.scania.com/login'
  }
}

export const AbstractionLayerConfig = {    
  stagingUri: 'https://quobkvexmre7blsb7ltpdz2xxi.appsync-api.eu-north-1.amazonaws.com/graphql',    
  releaseUri: 'https://5oetsllb2jfelbghdyob2uy2r4.appsync-api.eu-north-1.amazonaws.com/graphql'  
}