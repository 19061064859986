import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MessageService, TreeNode } from 'primeng/api';
import { LinkValidationService } from 'src/app/services/link-validation.service';
import { formField } from 'src/app/models/formField';
import { Fpc } from 'src/app/models/dto/fpc';
import { FpcMapper } from 'src/app/services/mappers/fpcMapper';
import { v4 as uuidv4 } from 'uuid';
import { FpcFamily } from 'src/app/models/dto/fpc-family';

@Component({
  selector: 'app-fpc',
  templateUrl: './fpc.component.html',
  styleUrls: ['./fpc.component.scss'],
  providers: [MessageService]
})

export class FpcComponent implements OnInit {
  @Input() field!: formField;
  @Input() form!: FormGroup;
  @Input() locked: boolean;
  @Input() fpcsLoaded: boolean;

  fpcsTree: TreeNode<Fpc>[] = [];
  selectedNode: TreeNode<Fpc>;

  constructor(private linkValidationService: LinkValidationService,
    private messageService: MessageService,
    ) {}

  ngOnInit(): void {
    if (!this.field || !this.form)
      return; 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.field || !this.form)
      return; 

    let value = this.form.controls[this.field.name]?.value;
    if (value) 
      this.populateTree(value);
  }

  populateTree(fpcExpression: string) {
    if(!this.field.options  || this.field.options.length == 0 || !this.fpcsLoaded)
      this.fpcsTree = FpcMapper.mapToTreeWithoutNames(fpcExpression);
    else
      this.fpcsTree = FpcMapper.mapToTreeWithNames(fpcExpression, this.field.options.map(x => {return x.data as FpcFamily}));
  }

  addItemToRoot(fpc: Fpc) {
    //no validation needed for root
    this.fpcsTree = this.fpcsTree.concat([]);
    this.fpcsTree.push(this.convertFpcToNode(fpc));
    
    this.saveValueToForm();
  }

  addItemToSelectedNode(fpc: Fpc) {
    let errorMessage = this.linkValidationService.validateFPC(fpc.fpcCode, this.selectedNode)
    if(errorMessage) {
      this.showErrorToast(errorMessage);
      return;
    } 

    //make a copy of array because html is stupid
    this.fpcsTree = this.fpcsTree.concat([]);

    if(!this.selectedNode.children)
      this.selectedNode.children = [];

    let newNode = this.convertFpcToNode(fpc);
    this.selectedNode.children.push(newNode);

    this.selectedNode.expanded = false;
    this.selectedNode.expanded = true;

    this.saveValueToForm();
  }

  private showErrorToast(message: string) {
    this.messageService.add({severity:'error', summary: 'Not allowed!', detail: message, icon: 'pi-times'});
  }

  rememberSelectedNode(node){
    this.selectedNode = node;
  }

  revertNotCondition(node) {
    node.data.notCondition = !node.data.notCondition;

    this.saveValueToForm();
  }

  saveValueToForm() {
    let fpcExpression = FpcMapper.mapToString(this.fpcsTree);
    this.form.patchValue({ [this.field.name]: fpcExpression });
  }

  removeItem(node) {
    //make a copy of array because html is stupid
    this.fpcsTree = this.fpcsTree.concat([]);

    if(!node.parent) {
      let treeIndex = this.fpcsTree.indexOf(node);
      this.fpcsTree.splice(treeIndex, 1);
    }
    else {
      let siblingNodes = node.parent.children;
      siblingNodes.splice(siblingNodes.indexOf(node), 1);
    }
    this.fpcsTree = this.fpcsTree.concat([]);

    this.saveValueToForm();
  }

  private convertFpcToNode(source: Fpc): TreeNode<Fpc> {
    if(!source)
      return undefined; 

    let node: TreeNode<Fpc> = {
      key: uuidv4().toString(), // key must be unique, but fpcCode duplicates in tree are allowed
      label: source.name,
      data: source,
    };

    return node;
  }
}

