import { TestCoverageDto } from "src/app/models/dto/testCoverageDto";
import { TestCoverageInput } from "../models/input/graphql-test-coverage-inputs";
import { convertSpecificationToGraphQlInput } from "./test-analysis.mappers";

export function convertTestCoverageDtoToGraphqlInput(input: TestCoverageDto): TestCoverageInput {
    return {
        Uid: input.uid,
        ItemNo: input.itemNo,
        Name: input.name,
        Version: input.version,
        ModifiedBy: input.modifiedBy,
        ModificationTime: null,
        Comment: input.comment,
        LockedBy: input.lockedBy,
        LockedTime: null,
        CreatedBy: input.createdBy,
        CreationTime: null,
        ApprovalStatus: input.approvalStatus,
        ApprovedForTestLevel: input.approvedForTestLevel,
        Specifications: input.specifications.map(item =>{
            return convertSpecificationToGraphQlInput(item);
        })
    }
  }