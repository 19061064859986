import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TreeNode } from 'primeng/api';
import { SystemProperty } from 'src/app/models/dto/systemProperty';
import { DerivedFrom } from 'src/app/models/enums/derivedFrom';
import { SystemTypeId } from 'src/app/models/enums/systemTypeId';
import { TpcTranslationService } from 'src/app/services/tpc-translation.service';
import { TpcService } from 'src/app/services/tpc.service';
import { TpcActions } from 'src/app/store/tpc/tpc.actions';

@Component({
  selector: 'app-tpc-property',
  templateUrl: './tpc-property.component.html',
  styleUrls: ['./tpc-property.component.scss'],
})
export class TpcPropertyComponent implements OnInit, OnChanges {
  @Input() property!: any; // PropertyBase<string>;
  @Input() form!: FormGroup;
  @Input() locked: boolean;

  derivedFromMethods: any[];
  derivedFromMethod: any;

  reviewRoles: any[];
  reviewRole: any;

  expression: string;
  translatedExpression: string;

  treeNodes: TreeNode[] = [];

  showSource: boolean = false;

  selectedTpc: TreeNode;
  placeholderNode: TreeNode;

  systemProperties: SystemProperty[] = [];

  constructor(
    private tpcService: TpcService,
    private store: Store,
    private tpcTranslationService: TpcTranslationService
  ) {
    let derivedFromMethods = Object.keys(DerivedFrom)
      .filter((e) => isNaN(+e))
      .map((key) => ({ value: key, name: DerivedFrom[key] }));
    this.derivedFromMethods = derivedFromMethods;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.property && this.property.value && this.property.value !== '') {
      //recreate tree view
      let jsonObj: any = JSON.parse(this.property.value);
      let tpcNodes: TreeNode[] = <TreeNode[]>jsonObj;
      this.treeNodes = tpcNodes;
    }
    this.getSystemProperties();
  }

  ngOnInit(): void {}

  getSystemProperties(): void {
    let propertiesObservable = undefined;
    propertiesObservable.subscribe((properties) =>
      this.onSystemPropertiesLoaded(properties)
    );
  }

  onSystemPropertiesLoaded(properties: SystemProperty[]): void {
    this.systemProperties = properties;
    this.getReviewRoles(properties);

    if (this.property && this.property.value && this.property.value !== '') {
      //edit
      this.tpcTranslationService.translateTree(this.treeNodes, properties);
      this.setExpressionsFromTree();
      this.store.dispatch(
        new TpcActions.SetInitialValue(this.translatedExpression)
      );
    }
  }

  private getReviewRoles(properties: SystemProperty[]) {
    let reviewRoleProperties = properties.filter(
      (x) => x.identifier === SystemTypeId.ReviewRole
    );
    this.reviewRoles = reviewRoleProperties.map((reviewRoleProperty) => ({
      value: reviewRoleProperty.variant,
      name: reviewRoleProperty.name,
    }));
  }

  addAndExpression(selectedTpc: TreeNode): void {
    this.placeholderNode = selectedTpc;
    this.showSource = true;
  }

  addOrExpression(selectedTpc: TreeNode): void {
    if (selectedTpc.parent) {
      this.placeholderNode = selectedTpc.parent;
    } else {
      this.placeholderNode = null;
    }
    this.showSource = true;
  }

  addExpression(): void {
    this.placeholderNode = null;
    this.showSource = true;
  }

  removeExpression(selectedTpc: TreeNode): void {
    let parentNode = selectedTpc.parent;
    if (parentNode) {
      //remove selected node from parent
      const index = parentNode.children.indexOf(selectedTpc);
      parentNode.children.splice(index, 1);
    } else {
      const index = this.treeNodes.indexOf(selectedTpc);
      this.treeNodes.splice(index, 1);
    }

    this.setExpression();

    this.store.dispatch(
      new TpcActions.SetCurrentValue(this.translatedExpression)
    );
  }

  derivedFromClick(event) {
    let value = event.value;
    let newNode: TreeNode = {
      label: `DerivedFrom="${value}"`,
      data: `TPC${SystemTypeId.TestCase}H="${value}"`,
      children: [],
    };

    this.addTreeNode(newNode);
  }

  reviewRoleClick(event) {
    let value = event.value;
    let name = event.option.name;
    let newNode: TreeNode = {
      label: `ReviewRole="${name}"`,
      data: `TPC${SystemTypeId.ReviewRole}${value}`,
      children: [],
    };

    this.addTreeNode(newNode);
  }

  private addTreeNode(newNode: TreeNode): void {
    if (!this.placeholderNode) {
      this.treeNodes.push(newNode);
    } else {
      if (!this.placeholderNode.children) {
        this.placeholderNode.children = [];
      }
      this.placeholderNode.children.push(newNode);

      this.placeholderNode.expanded = true;
    }

    this.showSource = false;
    this.setExpression();
    this.store.dispatch(
      new TpcActions.SetCurrentValue(this.translatedExpression)
    );
  }

  private setExpression() {
    this.setExpressionsFromTree();
    this.form.patchValue({ [this.property.key]: this.expression });
  }

  private setExpressionsFromTree() {
    this.expression = this.tpcService.convertTreeToTpcString(this.treeNodes);

    this.translatedExpression =
      this.tpcService.convertTranslatedTreeToTpcString(this.treeNodes);
  }
}
