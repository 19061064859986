import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { TestObjectsActions } from "./testObjects.actions";
import { TestObjectStateModel } from "./testObjects.state.model";
import { TestObjectApiService } from "src/app/services/api/test-objects-api.service";



@State<TestObjectStateModel>({
    name: 'testObject',
    defaults: {
        testObjects: []
    },
})
@Injectable()
export class TestObjectsState{
    constructor(private testObjectApiService: TestObjectApiService) {}

    @Action(TestObjectsActions.SetAllTestObjects)
    SetAllOffboardComponents(ctx: StateContext<TestObjectStateModel>) {
        const state = ctx.getState();
        //don't update state if we have already downloaded items
        if ( state.testObjects !== undefined && state.testObjects.length > 0) return;
            
        this.testObjectApiService.get().subscribe((testObjects) => {
            ctx.patchState({testObjects: testObjects})
        });
    }
    @Selector()
    static getAllTestObjects(state: TestObjectStateModel){
        return state.testObjects;
    }
}