<div class="form-buttons">
    <div class="left-side-buttons">
        <button *ngIf="actionButton.showCheckOut" type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm" (click)="checkOut()"  [disabled]="!actionButton.enableCheckOut" [pTooltip]="toolTip">
            Check Out
        </button>
        <button *ngIf="actionButton.showSave" type="submit" class="sdds-btn sdds-btn-primary sdds-btn-sm" (click)="onSubmit()" [disabled]="!actionButton.enableSave">
            Save
        </button>
        <button *ngIf="actionButton.showCheckIn" type="submit" class="sdds-btn sdds-btn-primary sdds-btn-sm" (click)="onSubmit()" [disabled]="!actionButton.enableCheckIn">
            Check In
        </button>
        <button *ngIf="actionButton.showCancel" type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm" (click)="unlock()" [disabled]="!actionButton.enableCancel">
            {{actionButton.showSave ? 'Clear' : 'Cancel'}}
        </button>
    </div>

    <div class="right-side-buttons">
        <button *ngIf="actionButton.sendToReview" class="parent-item-button sdds-btn sdds-btn-secondary sdds-btn-sm" id="view-send-to-review-modal" type="button"
            (click)="openSendToReviewModal()">
            Send to review
        </button>
        <button *ngIf="actionButton.showViewParentItems" class="parent-item-button sdds-btn sdds-btn-primary sdds-btn-sm" id="view-parent-items-modal" type="button"
            (click)="openParentItemsModal()">
            View Parent Items
        </button>
        <button  *ngIf="actionButton.showPreviewChanges" type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm"
            [disabled]="!actionButton.enablePreviewChanges" id="preview-changes-modal">
            <i class="pi pi-history" style="font-size: initial"></i>Preview changes
        </button>
    </div>
</div>

<sdds-modal *ngIf="testItem" size="sm" selector="#preview-changes-modal">
    <h5 slot="sdds-modal-headline">Preview changes</h5>
    <p slot="sdds-modal-body">
      <app-preview-changes [items]="changes"></app-preview-changes>
    </p>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md">
    Ok
    </button>
</sdds-modal>


<sdds-modal *ngIf="testItem" size="sm" selector="#view-parent-items-modal" id="parent-items-modal">
    <h5 slot="sdds-modal-headline">Parent Items</h5>
    <p slot="sdds-modal-body">
        <view-parent-items [itemUid]="testItem.uid"></view-parent-items>
    </p>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md" type="button">
    Ok
    </button>
</sdds-modal>

<app-send-to-review-modal *ngIf="testItem" [itemUid]="testItem.uid" [version]="testItem.version" [itemType]="itemType"></app-send-to-review-modal>

  