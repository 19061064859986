import { gql } from "apollo-angular";

export const CREATE_TEST_ANALYSIS_MUTATION = gql`mutation configuratorCreateTestAnalysis($TestAnalysis: TestAnalysisInput) {
    configuratorCreateTestAnalysis(TestAnalysis: $TestAnalysis) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `

export const UPDATE_TEST_ANALYSIS_MUTATION = gql`mutation configuratorUpdateTestAnalysis($TestAnalysis: TestAnalysisInput) {
    configuratorUpdateTestAnalysis(TestAnalysis: $TestAnalysis) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `
  

export const DELETE_TEST_ANALYSIS_MUTATION = gql`mutation configuratorDeleteTestAnalysis($Uid: String!) {
    configuratorDeleteTestAnalysis(Uid: $Uid)
  }
  `
  