export const RouteSegments = {
  TEST_COVERAGE: 'testcoverage',
  RMS_SPECIFICATIONS_COMPARISON: 'rmsspecificationcomparison',
  EVALUATION: 'evaluation',
  TEST_ANALYSIS: 'testanalyses',
  TEST_CASES: 'testcases',
  TEST_SUITES: 'testsuites',
  TEST_SPECIFICATIONS: 'testspecifications',
  ADD: 'add',
  REVIEW: 'review'
};

export const RouteConstants = {
  TEST_COVERAGE: `${RouteSegments.TEST_COVERAGE}`,
  RMS_SPECIFICATIONS_COMPARISON: `${RouteSegments.RMS_SPECIFICATIONS_COMPARISON}`,
  EVALUATION: `${RouteSegments.EVALUATION}`,
  TEST_ANALYSIS: `${RouteSegments.TEST_ANALYSIS}`,
  TEST_CASES: `${RouteSegments.TEST_CASES}`,
  TEST_SUITES: `${RouteSegments.TEST_SUITES}`,
  TEST_SPECIFICATIONS: `${RouteSegments.TEST_SPECIFICATIONS}`,
  REVIEW: `${RouteSegments.REVIEW}`,
  BASE: '',
};

export const RouteParams = {
  TestItemUid: 'testitemuid',
};
