import { gql } from "apollo-angular";

export const CREATE_TEST_COVERAGE_MUTATION = gql`mutation configuratorCreateTestCoverage($TestCoverage: TestCoverageInput) {
    configuratorCreateTestCoverage(TestCoverage: $TestCoverage) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `

export const UPDATE_TEST_COVERAGE_MUTATION = gql`mutation configuratorUpdateTestCoverage($TestCoverage: TestCoverageInput) {
    configuratorUpdateTestCoverage(TestCoverage: $TestCoverage) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `
  

export const DELETE_TEST_COVERAGE_MUTATION = gql`mutation configuratorDeleteTestCoverage($Uid: String!) {
    configuratorDeleteTestCoverage(Uid: $Uid)
  }
  `
  