import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TestlevelsService } from 'src/app/services/api/testlevels.service';
import { TestLevelsActions } from './testLevels.actions';
import { TestLevelsStateModel } from './testLevels.state.model';

@State<TestLevelsStateModel>({
  name: 'testLevels',
  defaults: {
    testLevels: [],
  },
})
@Injectable()
export class TestLevelsState {
  constructor(private testlevelsService: TestlevelsService) {}

  @Action(TestLevelsActions.SetAllTestLevels)
  SetAllTestLevels(ctx: StateContext<TestLevelsStateModel>) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (state.testLevels !== undefined && state.testLevels.length > 0) return;

    this.testlevelsService.get().subscribe((testLevels) => {
      ctx.patchState({ testLevels: testLevels });
    });
  }

  @Selector()
  static getAllTestLevels(state: TestLevelsStateModel) {
    return state.testLevels;
  }
}
