import { TestSpecificationDto } from "src/app/models/dto/testSpecificationDto";
import { TestSpecificationInput } from "../models/input/graphql-test-specification-input";
import { convertBaseTestItemDtoToGraphqlInput } from "./common.mappers";

  export function convertTestSpecificationDtoToGraphqlInput(input: TestSpecificationDto): TestSpecificationInput {
    return {
      Uid: input.uid,
      ItemNo: input.itemNo,
      Name: input.name,
      Version: input.version,
      ModifiedBy: input.modifiedBy,
      ModificationTime: null, //input.ModificationTime,
      Comment: input.comment,
      LockedBy: input.lockedBy,
      LockedTime: null, //input.lockedTime ? input.lockedTime.toISOString() : null,
      CreatedBy: input.createdBy,
      CreationTime: null, //  input.creationTime ? new Date(new Date(input.creationTime + 'Z').toLocaleString()).toDateString() : null,
      ApprovalStatus: input.approvalStatus,
      ApprovedForTestLevel: input.approvedForTestLevel,
      Label: input.label,
      OwnerIds: input.ownerIds,
      TestAnalyses:  input.testAnalyses.map(item => {
        return convertBaseTestItemDtoToGraphqlInput(item)
    }),
    }
  }
