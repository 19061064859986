import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { convertGraphqlEcuToEcu } from 'src/app/graphQL/mappers/ecu.mappers';
import { GraphQLEcuOutput } from 'src/app/graphQL/models/output/graphql-ecu-output';
import { GET_ECUS_QUERY } from 'src/app/graphQL/queries/ecu-queries';
import { Ecu } from 'src/app/models/ecu';
import { ApolloClientService } from './apollo-client.service';

@Injectable({
  providedIn: 'root'
})
export class EcusApiService {
  
  constructor(private apolloClientService: ApolloClientService) { }

    get(): Observable<Ecu[]> {
      return this.apolloClientService.apolloClient$.pipe(
        switchMap(client => 
          client.query<{configuratorAllEcus: GraphQLEcuOutput[]}>({
            query: GET_ECUS_QUERY,
          })
        ),
        map(results => {
          const ecus = results.data.configuratorAllEcus
          if (!ecus) 
            return undefined
          return ecus.map(ecu => {
            return convertGraphqlEcuToEcu(ecu);
          })
        })
      )
    }
}