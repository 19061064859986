import { gql } from "apollo-angular";

export const TRY_LOCK_ITEM_MUTATION = gql`mutation configuratorTryLockItem($ItemUid: String!) {
    configuratorTryLockItem(ItemUid: $ItemUid)
  }
  `
  

  export const UNLOCK_ITEM_MUTATION = gql`mutation configuratorUnlockItem($ItemUid: String!) {
    configuratorUnlockItem(ItemUid: $ItemUid)
  }
  `