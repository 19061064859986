import { RequirementDto } from 'src/app/models/dto/polarion/requirementDto';
import { Requirement } from 'src/app/models/dto/polarion/requirement';
import { RequirementWithEvaluation } from 'src/app/models/requirementWithEvaluation';

export class RequirementMapper {

  public static mapEvaluation(items: RequirementDto[]): RequirementWithEvaluation[] {
    let results: Requirement[] = [];

    if (!items || items.length === 0) return results;

    for (let index = 0; index < items.length; index++) {
      let link = this.mapOne(items[index]);
      if (link) 
        results[index] = link;
    }

    return results;
  }

  public static mapOne(dto: RequirementDto): Requirement {
    let item: Requirement = {
      polarionId: dto.polarionId,
      title: dto.title,
      irmaVersion: dto.irmaVersion,
      asil: dto.asil,
    };
    return item;
  }
}
