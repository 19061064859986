import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { defineCustomElements, addTheme } from '@scania/components';
import { theme as scania } from '@scania/theme-light';
import { AuthService } from './services/auth.service';
import { OrganizationalGroupsActions } from './store/organizationalGroups/organizationalGroups.actions';
import { Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FpcsActions } from './store/fpcs/fpcs.actions';
import { CheckedOutItemService } from './services/checked-out-item.service';
import { MessageService } from 'primeng/api';
import { ItemType } from './models/enums/itemType';

defineCustomElements();
addTheme(scania);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  isLoggedIn = false

  private destroyRef = inject(DestroyRef);
  params$: Observable<ParamMap> = this.route.queryParamMap;
  navigationEnded$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

  constructor(
    private authService: AuthService,
    private checkedOutItemService: CheckedOutItemService,
    private messageService: MessageService,
    private route: ActivatedRoute, 
    private router: Router,
    private store: Store) {}

  ngOnInit(): void {
    combineLatest({
      params: this.params$,
      isLoggedIn: this.authService.isLoggedIn$,
      navigationEnded: this.navigationEnded$, 
    }).pipe(
      filter((result) => !result.isLoggedIn),
      switchMap((result) => {
        const sessionId = result.params.get('sessionId') ?? ""
        const userName = result.params.get('username') ?? ""
        const companyName = result.params.get('company') ?? ""
        return this.authService.DoLogin(sessionId, userName, companyName)
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.authService.userData$.subscribe((result) => {
      this.isLoggedIn = result.isLoggedIn;
      
      if(this.isLoggedIn)
        this.store.dispatch(new OrganizationalGroupsActions.SetOrganizationalGroupByUsername(result.userName));
    });

    //load fpc right away because it takes a while to load them
    this.store.dispatch(new FpcsActions.SetAllFpcs());

    this.checkTypeOfCheckedOutItem();
  }

  private checkTypeOfCheckedOutItem() {
    let subscription = this.checkedOutItemService.checkedItemUidChange.subscribe(itemUid => {
      if(this.checkedOutItemService.exists() && this.checkedOutItemService.getCheckedOutItemType() == ItemType.Unsupported)
        this.messageService.add({ severity: 'info', summary: 'You have a checked out item in Configurator Desktop app and will not be able to edit items in Fenix', sticky: true});
      
      //check only once, not on every check in/out
      subscription.unsubscribe();  
    }
    );
  }
}
