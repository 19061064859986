<div [formGroup]="form" *ngIf="field">
    <div [ngSwitch]="field.fieldType"> 

        <div *ngSwitchCase="fieldTypes.text" style="width: 66rem" class="sdds-on-white-bg m-right">
            <sdds-textfield ngDefaultControl type="text" [id]="field.name" [formControlName]="field.name"
              [placeholder]="field.label">
              <span slot="sdds-label" class="textfield-float-label">
                {{field.label}}
                <i [class]="'pi pi-info-circle'" style="font-size: initial" [escape]="false"
                [pTooltip]="field.tooltip" tooltipPosition="bottom"></i>
              </span> 
            </sdds-textfield>
        </div>    

        <div *ngSwitchCase="fieldTypes.number" style="width: 66rem" class="sdds-on-white-bg m-right">
            <sdds-textfield ngDefaultControl type="number" [id]="field.name" [formControlName]="field.name"
              [placeholder]="field.label">
              <i [class]="'pi pi-info-circle'" style="font-size: initial" [escape]="false"
              [pTooltip]="field.tooltip" tooltipPosition="bottom"></i>
              <!-- pTooltip]="field.tooltip" tooltipPosition="bottom" -->
              <span slot="sdds-label" class="textfield-float-label">
                {{field.label}}
              </span>
            </sdds-textfield>
        </div>    

        <div *ngSwitchCase="fieldTypes.toggle" class="sdds-toggle" tabindex="0" style="width: 66rem">
                <input type="checkbox" class="sdds-toggle-input" [id]="field.name" [formControlName]="field.name"
                [pTooltip]="field.tooltip"  tooltipPosition="bottom"/>
           <!--/-->
                                    
            <span class="sdds-toggle-switch">
            </span>
            <label class="sdds-toggle-label" [attr.for]="field.name">
                {{field.label}}
            </label>
            <i *ngIf="field.tooltip" [class]="'pi pi-info-circle'" style="font-size: initial" [escape]="false"
            [pTooltip]="field.tooltip" tooltipPosition="bottom"></i>  
        </div>
    

        <div *ngSwitchCase="fieldTypes.dropdown" class="sdds-on-white-bg m-right">
            <p class="boldText">{{ field.label }}
                <i *ngIf="field.tooltip" [class]="'pi pi-info-circle'" style="font-size: initial" [escape]="false"
                [pTooltip]="field.tooltip" tooltipPosition="bottom"></i>
                <span *ngIf="field.isRequired">*</span>
            </p>
            <p-autoComplete
                [id]="field.name"
                [formControlName]="field.name"
                [suggestions]="itemSuggestions"
                [group]="field.hasHierarchyValues" 
                [dropdown]="true"
                [autoWidth]="false"
                [multiple]="field.isMultiValue"
                (completeMethod)="filterItem($event)"
                (onSelect)="filterChange($event)"
                (onUnselect)="onUnselect()"
                [disabled]="field.isReadOnly"
                [placeholder]="field.label"
                [ngClass]="{'style1':!alterStyle , 'style2' :alterStyle }"
                [forceSelection]="true"
                field="{{field.hasHierarchyValues ? 'label' :'value'}}">
            </p-autoComplete>
            
        </div>  

        <div *ngSwitchCase="fieldTypes.multiselect" class="p-float-label">
            <p-multiSelect [id]="field.name" [options]="field.options"
                [formControlName]="field.name"  optionLabel="value" optionValue="key" [disabled]="field.isReadOnly"
                [pTooltip]="field.tooltip" tooltipPosition="bottom"></p-multiSelect>
            <label class="multiselect-label" [attr.for]="field.name">{{ field.label }}</label>
        </div>    

        <div *ngSwitchCase="fieldTypes.chip" class="p-float-label">
            <p-chips [id]="field.name" [formControlName]="field.name" 
                [disabled]="field.isReadOnly || form.controls[field.name]?.disabled" 
                [pTooltip]="!form.controls[field.name]?.disabled ? 'Press enter for every input value you want to save' : 'Disabled for selected Input Type Name'"></p-chips>
            <label class="chip-label">{{ field.label }}</label>
        </div>    
    
        <div *ngSwitchCase="fieldTypes.tpc">
            <!-- <app-tpc-property [property]="field" [form]="form" [locked]="field.isReadOnly"></app-tpc-property> -->
        </div>     
    </div>
</div>