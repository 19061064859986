export enum FieldTypeEnum {
    text = 0,
    number = 1,
    toggle = 2,
    dropdown = 3,
    multiselect = 4,
    chip = 5,
    tpc = 6,
    table = 7,
    userFunction = 8
}