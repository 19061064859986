import { RouteConstants } from "src/app/constants/routeConstants";
import { ItemType } from "src/app/models/enums/itemType";

export function getRouteBase(itemType: ItemType){
    switch(itemType){
      case ItemType.TestCase:
        return RouteConstants.TEST_CASES
      case ItemType.TestSuite:
        return RouteConstants.TEST_SUITES
      case ItemType.TestAnalysis:
        return RouteConstants.TEST_ANALYSIS
      case ItemType.TestCoverage:
        return RouteConstants.TEST_COVERAGE
      case ItemType.TestSpecification:
        return RouteConstants.TEST_SPECIFICATIONS
      default:
        return 'unknown route';
    }
  }