import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ParentItemBasicInfo } from 'src/app/models/dto/parent-item-basic-info-dto';
import { ApolloClientService } from './apollo-client.service';
import { GraphQLParentItemBasicInfoOutput } from 'src/app/graphQL/models/output/graphql-base-test-item-output';
import { GET_PARENT_ITEMS_QUERY, TRY_GET_LOCKED_ITEM_UID_AND_TYPE_QUERY } from 'src/app/graphQL/queries/item-queries';
import { convertGraphQLItemUidAndTypeOutputToDto, convertGraphQLParentItemBasicInfoOutputToDto } from 'src/app/graphQL/mappers/common.mappers';
import { TRY_LOCK_ITEM_MUTATION, UNLOCK_ITEM_MUTATION } from 'src/app/graphQL/mutations/item-mutations';
import { GraphQLItemUidAndTypeOutput } from 'src/app/graphQL/models/output/graphql-item-uid-and-type-output';
import { ItemUidAndTypeDto } from 'src/app/models/dto/itemUidAndTypeDto';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {

  constructor(private apolloClientService: ApolloClientService) {}

  getParentItems(itemUid: string): Observable<ParentItemBasicInfo[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorParentItems: GraphQLParentItemBasicInfoOutput[]}>({
          query: GET_PARENT_ITEMS_QUERY,
          variables: {ItemUid: itemUid}
        })
      ),
      map(results => {
        const parentItems = results.data.configuratorParentItems
        if (!parentItems)
          return undefined
  
        return parentItems.map(item => {
          return convertGraphQLParentItemBasicInfoOutputToDto(item);
        })
      })
    )
  }

  getCheckedOutItemUidAndType(): Observable<ItemUidAndTypeDto> {
    let userName = localStorage.getItem('userName');
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorTryGetLockedItemUidAndTypeByUser: GraphQLItemUidAndTypeOutput}>({
          query: TRY_GET_LOCKED_ITEM_UID_AND_TYPE_QUERY,
          variables: {User: userName}
        })
      ),
      map(results => {
        const itemUidAndType = results?.data?.configuratorTryGetLockedItemUidAndTypeByUser;
        if(!itemUidAndType)
          return undefined;

        var result = convertGraphQLItemUidAndTypeOutputToDto(itemUidAndType);
        return result;
      })
    )
  }

  checkout(itemUid: string): Observable<boolean> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorTryLockItem: boolean}>({
          mutation: TRY_LOCK_ITEM_MUTATION,
          variables: {ItemUid: itemUid }
        })
      ),
      map(results => {
        return results.data.configuratorTryLockItem;
      })
    )
  }

  unlock(itemUid: string): Observable<void> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate({
          mutation: UNLOCK_ITEM_MUTATION,
          variables: {ItemUid: itemUid }
        })
      ),
      map(results => {
        return;
        //return results.data;
      })
    )
  }
}
