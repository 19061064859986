import { gql } from "apollo-angular";

export const CREATE_TEST_CASE_MUTATION = gql`mutation configuratorCreateTestCase($TestCase: TestCaseInput) {
    configuratorCreateTestCase(TestCase: $TestCase) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `

export const UPDATE_TEST_CASE_MUTATION = gql`mutation configuratorUpdateTestCase($TestCase: TestCaseInput) {
    configuratorUpdateTestCase(TestCase: $TestCase) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `
  

export const DELETE_TEST_CASE_MUTATION = gql`mutation configuratorDeleteTestCase($Uid: String!) {
    configuratorDeleteTestCase(Uid: $Uid)
  }
  `
  