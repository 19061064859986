import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { RouteSegments } from 'src/app/constants/routeConstants';
import { OffboardComponent } from 'src/app/models/dto/offboardComponent';
import { OrganizationalGroup } from 'src/app/models/dto/organizationalGroup';
import { ParentChildHierarchy } from 'src/app/models/parent-child-hierarchy';
import { PreviewChange } from 'src/app/models/previewChange';
import { formField } from 'src/app/models/formField';
import { ItemsService } from 'src/app/services/api/items.service';
import { OffboardComponentsActions } from 'src/app/store/offboardComponents/offboardComponents.actions';
import { OffboardComponentsState } from 'src/app/store/offboardComponents/offboardComponents.state';
import { OrganizationalGroupsActions } from 'src/app/store/organizationalGroups/organizationalGroups.actions';
import { OrganizationalGroupsState } from 'src/app/store/organizationalGroups/organizationalGroups.state';
import { ProductPropertiesActions } from 'src/app/store/productPropeties/productProperties.actions';
import { ProductPropertiesState } from 'src/app/store/productPropeties/productProperties.state';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { enableAllFields, disableAllFields, createFormGroup } from 'src/app/services/helpers/formHelper';
import { ChangesService } from 'src/app/services/changes.service';
import { TestItemActionButton } from 'src/app/components/shared/test-item-action/test-item-action.component';
import { ButtonService } from 'src/app/services/button.service';
import { TestSuiteApiService } from 'src/app/services/api/test-suite-api.service';
import { TestSuiteDto } from 'src/app/models/dto/testSuiteDto';
import { TestCasesActions } from 'src/app/store/testCases/testCases.actions';
import { TestSuitesActions } from 'src/app/store/testSuites/testSuites.actions';
import { TestCasesState } from 'src/app/store/testCases/testCases.state';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { TestSuitesState } from 'src/app/store/testSuites/testSuites.state';
import { TestItemHierarchyLinkDto } from 'src/app/models/dto/test-item-hierarchy-link-dto';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionService } from 'src/app/services/permission.service';
import { environment } from 'src/environments/environment';
import { ItemFactory } from 'src/app/services/item-factory/item.factory';
import { ItemType } from 'src/app/models/enums/itemType';
import { ReviewCommentGroup } from 'src/app/models/review/commentGroup';
import { ReviewService } from 'src/app/services/review/review.service';
import { UserFunctionsActions } from 'src/app/store/user-functions/user-functions.actions';
import { UserFunction } from 'src/app/models/dto/user-function-dto';
import { UserFunctionsState } from 'src/app/store/user-functions/user-functions.state';
import { FpcFamily } from 'src/app/models/dto/fpc-family';
import { FpcsState } from 'src/app/store/fpcs/fpcs.state';
import { Approval } from 'src/app/models/review/approval';
import { CheckedOutItemService } from 'src/app/services/checked-out-item.service';

@Component({
  selector: 'app-test-suite',
  templateUrl: './test-suite.component.html',
  styleUrls: ['./test-suite.component.scss'],
  providers: [MessageService]
})
export class TestSuiteComponent implements OnInit {

  @Input() testSuiteUid: string = '';
  
  fields: formField[];
  alternativeViewShown: boolean = false;//can be removed?
  testItem: TestSuiteDto;
  loggedInUsername: string;
  addPageActive: boolean = false;
  form: FormGroup;

  hasChange: boolean = false;
  changes: PreviewChange[] = [];
  showPreviewChanges: boolean = false;

  subject = new Subject<String>();

  private subscriptions: Subscription[] = [];
  currentUserSubscription: Subscription;
  valueSubscription: Subscription;
  formInitialValue: string;

  informationFields: formField[];
  optionFields: formField[];
  testCaseLinkField: formField;
  testSuiteLinkField: formField;
  testCaseLinksLoadedToForm: boolean;
  testSuiteLinksLoadedToForm: boolean;
  fpcLinkField: formField;
  fpcLinksLoadedToForm: boolean;
  fpcDataLoaded: boolean = false;
  actionButtons: TestItemActionButton;
  checkOutBtnToolTip: string;

  hideForm: boolean = false;
  isHero = environment.IsHero;

  readonly ReviewCommentGroup = ReviewCommentGroup;
  reviewVisable$ = this.reviewService.reviewVisable$

  approval: Approval
  approvalId: number
  enableReviewComments: boolean;

  itemType = ItemType.TestSuite

  constructor(private testSuiteApiService: TestSuiteApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private itemsService: ItemsService,
    public checkOutItemService: CheckedOutItemService,
    private store: Store,
    private changesService: ChangesService,
    private messageService: MessageService,
    private buttonService: ButtonService,
    private permissionService: PermissionService,
    private reviewService: ReviewService) {}

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.queryParamMap.subscribe(params => {
      this.approvalId = parseInt(params.get("approvalId")) 
    });
    
    this.route.paramMap.subscribe(params => {
      // reload the item data when the route id changes
      let itemUidRouteParameter = params.get("testitemuid");

      this.actionButtons = this.buttonService.setDefaultInit()

      this.addPageActive = itemUidRouteParameter === RouteSegments.ADD;
      if (this.addPageActive) {
        this.actionButtons = this.buttonService.setAddButtons()
        this.hasChange = true;
        this.testSuiteUid = '';
        this.loadData();
      }
      else {
        this.testSuiteUid = itemUidRouteParameter;
        this.testSuiteApiService.getByUid(this.testSuiteUid).subscribe(response => { 
          if (!response.success) {
            this.hideForm = true;
            this.clearValidationErrors();
            if(response.responseMessages?.length > 0)
              this.messageService.add({ severity: 'error', summary: response.responseMessages[0], sticky: true});
          }
          else
          {
            // const originalString = response.returnValue
            // const formattedString = this.formatJsonString(originalString);
            // this.testItem = JSON.parse(formattedString)// as TestSuiteDto;
            this.testItem = response.returnValue;
            this.loadData();
            if (this.testItem) {
              this.loadReviewData()
            }
          }
        })
      }
    });
  }

  loadData(){
    this.currentUserSubscription = this.authService.userData$.subscribe(result => this.loggedInUsername = result.userName);
    this.setButtonState();
    this.testSuiteApiService.getUiTemplate().subscribe(result => {
      this.fields = result.sort(x => x.order);
      this.loadFields();
      this.form = createFormGroup(this.fields, ItemType.TestSuite);
      this.populateDropdownValues().subscribe(x => {
        if(this.testItem) {
          this.populatePropertiesToFormGroupFromDto();
          this.populateLinksToFormGroupFromDto()
          this.setControlState();
          this.formInitialValue = JSON.stringify(this.form.value);
          
          //if item is locked start tracking changes
          if (this.testItem.lockedBy == this.loggedInUsername) 
            this.onChanges();
        }
      });
    });
  }

  loadReviewData() {
    this.reviewService.getApprovalObservable(this.approvalId).subscribe(
      (result) => {
        this.approval = result
      }
    )
    // load commments
    this.reviewService.loadComments(this.testItem?.uid, this.testItem?.version)
  }

  setButtonState(){
    this.buttonState()
    //subscribe to change of checked out item
    let subscription = this.checkOutItemService.checkedItemUidChange.subscribe(itemUid => this.buttonState());
    this.subscriptions.push(subscription);
  }

  buttonState(){
    this.checkOutBtnToolTip = '';
    if(this.addPageActive)
      return;
    if(this.checkOutItemService.exists() && this.testItem.lockedBy != this.loggedInUsername){
      this.actionButtons = this.buttonService.setReadonlyButtons();
      this.checkOutBtnToolTip = 'You have another item checked out. Check in or cancel that item first';
    }
    
    if(/*this.checkOutItemService.exists() && */this.testItem.lockedBy == this.loggedInUsername)//Issue when checked out item and f5
      this.actionButtons = this.buttonService.setCheckedOutButtons();
    
    if(!this.checkOutItemService.exists() && this.testItem.lockedBy != '' && this.testItem.lockedBy != this.loggedInUsername){
      this.actionButtons = this.buttonService.setReadonlyButtons();
      this.checkOutBtnToolTip = 'This item is already checked out by another user';
    }
    
    if(!this.checkOutItemService.exists() && this.testItem.lockedBy == '')
      this.actionButtons = this.buttonService.setEditButtons();

    this.permissionService.checkPermission(this.loggedInUsername, this.testItem.ownerIds).subscribe(hasPermission => {
      if(!hasPermission) {
        this.actionButtons = this.buttonService.setReadonlyButtons();
        this.checkOutBtnToolTip = 'You do not have permission to edit this item';
      }
    })
  }

  setControlState(){
    if(this.actionButtons.stateReadOnly || this.actionButtons.stateEdit)
      disableAllFields(this.fields, this.form);

    if(this.actionButtons.stateAdd || this.actionButtons.stateCheckedOut)
      enableAllFields(this.fields, this.form);
  }

  populatePropertiesToFormGroupFromDto(){
    for (var property in this.testItem) {
      const controlName = property.toLowerCase();
      const matchingControl = Object.keys(this.form.controls).find(control => control.toLowerCase() === controlName);
      if (matchingControl && matchingControl != formControlConstants.Description) 
        this.form.get(matchingControl).setValue(this.testItem[property]);
    }

    for (var property in this.testItem.alternative){
      const controlName = property.toLowerCase();
      const matchingControl = Object.keys(this.form.controls).find(control => control.toLowerCase() === controlName);
      if(matchingControl)
        this.form.get(matchingControl).setValue(this.testItem.alternative[property])
    }

    this.fpcLinksLoadedToForm = true;
  }

  populateLinksToFormGroupFromDto(){
    var values = [];
    this.fields.forEach(field => {
      switch (field.name){
        case formControlConstants.TSOwner: {
          this.testItem.ownerIds?.forEach(ownerId => {
            var match = field.options?.find(value => value.key == ownerId.toLowerCase())
            values.push(match)
          })
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case formControlConstants.TSOffboardComponent: {
          this.testItem.offboardComponentIds?.forEach(offboardComponentId => {
            var match = field.options?.find(value => value.key == offboardComponentId.toLowerCase())
            values.push(match)
          })
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case formControlConstants.TSProductProperty: {
          this.testItem.productPropertyIds?.forEach(productPropertyId => {
            const matches = this.matchHierarchyDataFromDto(productPropertyId, field)
            values.push(...matches);
          });
          this.form.controls[field.name].patchValue(values);
          break;
        }
        case formControlConstants.TSUserFunction: {
          this.testItem.userFunctionIds?.forEach(userFunctionsId => {
            var match = field.options?.find(value => value.key == userFunctionsId);
            values.push(match);
          })
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case formControlConstants.TSTestCase: {
          this.store.select<BaseTestItemDto[]>(TestCasesState.getAllTestCases).subscribe(testCases => {
            this.testItem.testCases?.forEach(testCase => {
              this.updateNameOfItemAndChildren(testCase, testCases);
              values.push(testCase);
            });
            this.form.controls[field.name].patchValue(values);
            this.testCaseLinksLoadedToForm = true;
          });
          break;
        }
        case formControlConstants.TSTestSuite: {
          this.store.select<BaseTestItemDto[]>(TestSuitesState.getAllTestSuites).subscribe(testSuites => {
            this.testItem.testSuites?.forEach(testSuite => {
              this.updateNameOfItemAndChildren(testSuite, testSuites);
              values.push(testSuite);
            });
            this.form.controls[field.name].patchValue(values);
            this.testSuiteLinksLoadedToForm = true;
          });
          break;
        }
      }
      values = [];
    })
  }

  updateNameOfItemAndChildren(itemLinkToUpdate: TestItemHierarchyLinkDto, allItemLinksWithNames: BaseTestItemDto[]) {
    var testItemInfo = allItemLinksWithNames.find(x => x.uid == itemLinkToUpdate.testItemUid);
    if(testItemInfo){
      itemLinkToUpdate.version = testItemInfo.version;
      itemLinkToUpdate.name = testItemInfo.name;
      itemLinkToUpdate.itemNo = testItemInfo.itemNo;
    }
    itemLinkToUpdate.childrenTestItemLinks?.forEach(child => this.updateNameOfItemAndChildren(child, allItemLinksWithNames));
  }

  matchHierarchyDataFromDto(valueUid: String, field: formField){
    return field.hierarchialOptions
            ?.map(x => x.items.find(value => value.value === valueUid.toLowerCase()))
            .filter(match => match !== undefined);
  }
  
  populateDropdownValues() {
    this.store.dispatch(new OrganizationalGroupsActions.SetAllOrganizationalGroups());
    this.store.dispatch(new ProductPropertiesActions.SetAllProductProperties());
    this.store.dispatch(new OffboardComponentsActions.SetAllOffboardComponents());
    this.store.dispatch(new ProductPropertiesActions.SetAllProductProperties());
    this.store.dispatch(new TestCasesActions.SetAllTestCases());
    this.store.dispatch(new TestSuitesActions.SetAllTestSuites())
    this.store.dispatch(new UserFunctionsActions.SetUserFunctions())

    let organizationalGroupsObservable = this.store.select<OrganizationalGroup[]>(OrganizationalGroupsState.getAllOrganizationalGroups);
    let productPropertiesObservable = this.store.select<ParentChildHierarchy[]>(ProductPropertiesState.getAllProductPropertiesHierarchy);
    let offboardComponentsObservable = this.store.select<OffboardComponent[]>(OffboardComponentsState.getAllOffboardComponents)
    let testCaseObservable = this.store.select<BaseTestItemDto[]>(TestCasesState.getAllTestCases)
    let testSuiteObservable = this.store.select<BaseTestItemDto[]>(TestSuitesState.getAllTestSuites)
    let fpcObservable = this.store.select<FpcFamily[]>(FpcsState.getAllFpcs);
    let userFunctionsObservable = this.store.select<UserFunction[]>(UserFunctionsState.getUserFunctions)

    const subscription = combineLatest([organizationalGroupsObservable, productPropertiesObservable, offboardComponentsObservable, testCaseObservable, testSuiteObservable, userFunctionsObservable])
    .subscribe(([organizationalGroups, productProperties, offboardComponents, testCases, testSuites, userFunctions]) =>{
      if (!organizationalGroups || organizationalGroups.length == 0 || !productProperties
        || !offboardComponents || offboardComponents.length == 0
        || !testCases || !testSuites || !userFunctions) 
        return;

        if(!environment.IsHero && (productProperties.length == 0 || userFunctions.length == 0))
          return;

        if(!environment.IsHero && !environment.IsProduction && (testCases.length == 0 || testSuites.length == 0))
          return;

        var ownerField = this.fields.find(x => x.name === formControlConstants.TSOwner);
        if(ownerField)
          ownerField.options = organizationalGroups.map(x => { return {key: x.uid, value: x.name}});

        var productPropertyField = this.fields.find(x => x.name === formControlConstants.TSProductProperty);
        if(productPropertyField){
          productProperties.forEach(x => {
            x.items.sort((a, b) => a.label.localeCompare(b.label));
          })
          productPropertyField.hierarchialOptions = productProperties.sort((a, b) => a.label.localeCompare(b.label));
        }

        var offboardComponentField = this.fields.find(x => x.name === formControlConstants.TSOffboardComponent);
        if(offboardComponentField)
          offboardComponentField.options = offboardComponents.map(x => { return {key: x.uid, value: x.name}});

        var testCaseField = this.fields.find(field => field.name === formControlConstants.TSTestCase)
        if(testCaseField)
          testCaseField.options = testCases.map(x => {return {key: x.uid, value: x.name, data: x}})
        
        var testSuiteField = this.fields.find(field => field.name === formControlConstants.TSTestSuite)
        if(testSuiteField)
          testSuiteField.options = testSuites.map(x => {return {key: x.uid, value: x.name, data: x}})
        
        var userFunctionField = this.fields.find(x => x.name === formControlConstants.TSUserFunction);
        if(userFunctionField)
          userFunctionField.options = userFunctions.map(x => { return {key: x.id.toString(), value: 'UF ' + x.id + ': ' + x.name }});

        setTimeout(() => {
          this.subject.next("done");
        },5);  
    });

     //load fpcs separately because they are slow
     const fpcSubscription = fpcObservable.subscribe(fpcs => {
      if(!fpcs || fpcs.length == 0)
        return;

      var fpcField = this.fields.find(x => x.name === formControlConstants.FPC);
      if(fpcField) {
          fpcField.options = fpcs.map(x => { return { key: x.fpcCode, value: x.name, data: x }})
      
      this.fpcDataLoaded = true;
     }
    });
    
    this.subscriptions.push(fpcSubscription);
    this.subscriptions.push(subscription);
    return this.subject.asObservable();
  }

  checkOut(): void {
    if (!this.testSuiteUid)
      return;

    this.itemsService.checkout(this.testSuiteUid).subscribe(result => {
      if (result === false) {
        this.messageService.add({ severity: 'error', summary: 'Item check out failed', sticky: false});
        return;
      }

      this.actionButtons = this.buttonService.setCheckedOutButtons()
      enableAllFields(this.fields, this.form);
        
      this.testItem.lockedBy = this.loggedInUsername;
      this.testItem.lockedTime = new Date();   

      this.formInitialValue = JSON.stringify(this.form.value);
      this.onChanges();
      this.checkOutItemService.set(this.testItem?.uid, ItemType.TestSuite);
      this.store.dispatch(new TestSuitesActions.SetUpdateNeeded(true));
      this.hasChange = false;
    });
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(formValue => {
      this.hasChange =  this.formInitialValue !== JSON.stringify(this.form.value);

      let currentChanges = this.changesService.getDifferences(
        JSON.parse(this.formInitialValue),
        formValue,
        this.fields,
      );
      this.changes = currentChanges;
    });
  }

  unlockItem(): void {    
    this.clearValidationErrors();

    if (this.addPageActive || this.testItem.lockedBy == '') {
      this.resetChanges();
      return;
    } 

    this.itemsService.unlock(this.testItem.uid).subscribe(() => {
      this.actionButtons = this.buttonService.setEditButtons()
      this.testItem.lockedBy = '';
      this.testItem.lockedTime = null;
      disableAllFields(this.fields, this.form);
      this.resetChanges();

      this.checkOutItemService.set();
      this.store.dispatch(new TestSuitesActions.SetUpdateNeeded(true));
    });
  }

  onSubmit(): void {
    if(!this.validateForSubmit())
      return;

    let requestDto = ItemFactory.createItem(this.testItem, this.form, ItemType.TestSuite) as TestSuiteDto;

    if(this.addPageActive){
      this.testSuiteApiService.create(requestDto).subscribe(response => {
        if(response.success)
          this.navigateToList();
        else
        this.showValidationErrors(response.responseMessages);
      });
    } else {
      this.testSuiteApiService.update(requestDto).subscribe(response => {
        if(response.success) {
          this.unlockItem();
          this.navigateToList();
        }
        else {
          this.showValidationErrors(response.responseMessages);
        }
      });
    }
  }

  validateForSubmit() {
    this.clearValidationErrors();
  
    if(this.form.invalid){
      this.messageService.add({ severity: 'error', summary: 'Not all required fields are populated', sticky: true});
      return false;
    }
  
    if(!this.addPageActive && !this.hasChange) {
      this.messageService.add({ severity: 'error', summary: 'You made no changes', sticky: true});
      return false;
    }
  
    return true;
  }

  showValidationErrors(errors: string[]) {
    this.clearValidationErrors();
    this.messageService.add({ severity: 'error', summary: 'Item not saved!', sticky: true});

    errors.forEach(errorMessage => {
      this.messageService.add({ severity: 'error', summary: errorMessage, sticky: true });
    });
  }

  clearValidationErrors() {
    this.messageService.clear();
  }

  private navigateToList(): void {
    window.location.href = `/#/${RouteSegments.TEST_SUITES}`
    window.location.reload();
  }

  resetChanges() {
    this.form.reset(this.formInitialValue ? JSON.parse(this.formInitialValue) : {});
    this.hasChange = false;
    this.changes = [];
  }

  loadFields(){
    this.generalInformationFields();
    this.optionsFields();
    this.testCaseLinkField = this.fields.find(x => x.category === "Test Cases");
    this.testSuiteLinkField = this.fields.find(x => x.category === "Test Suites");
    this.fpcLinkField = this.fields.find(x => x.category === "FPC conditions" && !this.isHero);
  }
  
  generalInformationFields(){
    if(this.fields)
      this.informationFields = this.fields.filter(x => x.category === "General information");
    else
      this.informationFields = [];
  }

  optionsFields(){//apply to property
    if(this.fields)
      this.optionFields = this.fields.filter(x => x.category === "Options" && (x.label != 'TPC Expression' && x.label != 'ShowNextTestCaseAutomatically'/*last one for for executor web*/)
         && (this.isHero ? (x.label !== 'Product Properties' && x.label !== 'UF') : true));
    else
      this.optionFields = [];
  }

  handleEnableReviewComments(enable: boolean) {
    this.enableReviewComments = enable;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      if (subscription && !subscription.closed) 
        subscription.unsubscribe();
    }
    this.subscriptions = [];
  }
}
