<sdds-modal size="sm" selector="#view-send-to-review-modal" id="send-to-review-modal">
  <h5 slot="sdds-modal-headline">Send to review</h5>
  <p slot="sdds-modal-body">
    <p-autoComplete
        id="reviewers"
        [suggestions]="itemSuggestions"
        [dropdown]="true"
        [autoWidth]="false"
        [multiple]="true"
        [group]="false"
        (completeMethod)="filterItem($event)"
        (onSelect)="filterChange($event)"
        placeholder="Select reviewer"
        pTooltip="Select user to review"
        tooltipPosition="bottom"
        [forceSelection]="true"
        field="name"
        [(ngModel)]="selectedOptions"
    >
    </p-autoComplete><!-- [(ngModel)]="selectedItems"-->    
    <p-table [value]="selectedUsers" [tableStyle]="{ 'margin-top': '40px' }" >
      <ng-template pTemplate="header">
          <tr>
              <th>Full Name</th>
              <th>User Id</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
          <tr>
              <td>{{ user.name }}</td>
              <td>{{ user.id }}</td>
          </tr>
      </ng-template>
    </p-table>
  </p>
  <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md" type="button">
    Cancel
  </button>
  <button slot="sdds-modal-actions" class="sdds-btn sdds-btn-primary sdds-btn-md" type="button" (click)="onSendClick($event)">
    Send
  </button>
</sdds-modal>
