<div *ngIf="expression" class="form-row">
  {{ translatedExpression }}
</div>

<button
  *ngIf="!locked && !expression"
  type="button"
  class="sdds-btn sdds-btn-secondary sdds-btn-sm"
  (click)="addExpression()"
>
  <i class="pi pi-plus" style="font-size: initial"></i> Add expression
</button>

<div *ngIf="treeNodes && treeNodes.length > 0" class="form-row">
  <p-tree
    [value]="treeNodes"
    selectionMode="single"
    [(selection)]="selectedTpc"
  >
    <ng-template let-node pTemplate="default">
      {{ node.label }}
      <!-- <span (click)="addNodeExpression(node)">
        <i class="pi pi-plus" style="font-size: initial"></i>
      </span> -->
      <button
        *ngIf="!locked"
        type="button"
        class="sdds-btn sdds-btn-secondary sdds-btn-sm node-button"
        (click)="addAndExpression(node)"
      >
        AND
      </button>

      <button
        *ngIf="!locked"
        type="button"
        class="sdds-btn sdds-btn-secondary sdds-btn-sm node-button"
        (click)="addOrExpression(node)"
      >
        OR
      </button>
      <button
        *ngIf="!locked"
        type="button"
        class="sdds-btn sdds-btn-secondary sdds-btn-sm removeItem node-button"
        (click)="removeExpression(node)"
      >
        <i class="pi pi-trash" style="font-size: initial"></i>
      </button>
    </ng-template>
  </p-tree>
</div>

<div *ngIf="showSource" style="display: flex; padding: 20px">
  <div style="flex: 1">
    <label> Derived From </label>

    <p-listbox
      id="derivedFrom"
      [options]="derivedFromMethods"
      [(ngModel)]="derivedFromMethod"
      optionLabel="name"
      optionValue="value"
      (onClick)="derivedFromClick($event)"
    ></p-listbox>
  </div>
  <!-- <div style="flex: 1">
    <label> Test Types </label>

    <p-listbox
      id="testType"
      [options]="testTypes"
      [(ngModel)]="testType"
      optionLabel="name"
      optionValue="value"
    ></p-listbox>
  </div> -->
  <div style="flex: 1">
    <label> Review Roles </label>

    <p-listbox
      id="reviewRoles"
      [options]="reviewRoles"
      [(ngModel)]="reviewRole"
      optionLabel="name"
      optionValue="value"
      (onClick)="reviewRoleClick($event)"
    ></p-listbox>
  </div>
</div>
