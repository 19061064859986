import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { ApolloClientService } from './apollo-client.service';
import { Sop } from 'src/app/models/sop';
import { convertGraphqlSopToDto } from 'src/app/graphQL/mappers/sop.mapper';
import { GETALL_SOP_QUERY } from 'src/app/graphQL/queries/sop-queries';
import { environment } from 'src/environments/environment';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SopApiService {
  constructor(private apolloClientService: ApolloClientService) { }

  get(): Observable<Sop[]> {
    if(environment.IsHero == false){
      return this.apolloClientService.apolloClient$.pipe(
        switchMap(client => 
          client.query<{configuratorSop: string[]}>({
            query: GETALL_SOP_QUERY,
          })
        ),
        map(results => {
          const sop = results.data.configuratorSop
          if (!sop)
            return undefined
  
          return sop.map(item => {
            return convertGraphqlSopToDto(item);
          })
        })
      )
    }
    return EMPTY;
  }
}
