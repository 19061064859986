import { Component, Input, SecurityContext, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HtmlEditorService, ImageService, LinkService, RichTextEditorComponent, TableService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { formControlConstants } from 'src/app/constants/formControlConstants';
interface Tools {
  items?: string[];
  setting?: string;
}

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService]
})
export class TextEditorComponent{
  @Input() description?:string;
  @Input() form!: FormGroup;
  @Input() readOnly: boolean;
  @ViewChild('RTE', { static: true }) RTE: RichTextEditorComponent;
  public insertImageSettings = {
    saveFormat: "Base64"
  }
  constructor(private sanitizer: DomSanitizer) {
  }

  tools: Tools = {
    items: ['Formats','|', 'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontSize', 'FontColor', '|', 
    'Alignments', 'OrderedList', 'UnorderedList', 'Outdent', 'Indent', '|',
    'CreateTable', 'Image', 'Undo', 'Redo']
  };

  ngOnInit(){
    if (this.description)
      this.description = this.sanitizer.sanitize(SecurityContext.HTML, this.description);
    if (this.description && this.form.controls[formControlConstants.Description])
      this.form.controls[formControlConstants.Description].patchValue(this.description);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('description' in changes && this.description && this.form.controls[formControlConstants.Description]) 
      this.form.controls[formControlConstants.Description].patchValue(this.sanitizer.sanitize(SecurityContext.HTML, this.description));
  }

  public onChange(event: any){
    if(!this.RTE) return;
    this.form.controls[formControlConstants.Description].patchValue(this.sanitizer.sanitize(SecurityContext.HTML,this.RTE.getHtml()));//will call sanitize often. Too much? Handle elsewhere?
  }

  get isValid() {
  
    let formControl = this.form.controls[formControlConstants.Description];

    if(!formControl)
    return false;
  
    if (formControl.disabled) {
      return true;
    }
    return formControl.valid;
  }
}