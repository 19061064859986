import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf(environment.testLinkerDataApiUrl) != -1)
    {
        // send cloned request with header to the next handler.
        return this.authService.userData$.pipe(            
            map(userData => {
                if (userData.sessionUID) {
                    return req.clone({
                        headers: req.headers.set('Authorization', userData.sessionUID!)
                    })
                } {
                    return req
                }
            }),
            switchMap(authReq => next.handle(authReq).pipe(catchError(x=> this.handleAuthError(x))))
        )
    }
    // send cloned request with header to the next handler.
    return next.handle(req)
  }
 
    handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            this.authService.clearSession();
        }
        return of(null)//throwError(err.message);
    }
}
