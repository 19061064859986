import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { RouteSegments } from 'src/app/constants/routeConstants';
import { OffboardComponent } from 'src/app/models/dto/offboardComponent';
import { OrganizationalGroup } from 'src/app/models/dto/organizationalGroup';
import { RequirementDto } from 'src/app/models/dto/polarion/requirementDto';
import { TestCaseDto } from 'src/app/models/dto/testCaseDto';
import { ParentChildHierarchy } from 'src/app/models/parent-child-hierarchy';
import { PreviewChange } from 'src/app/models/previewChange';
import { TestObject } from 'src/app/models/testObject';
import { formField } from 'src/app/models/formField';
import { ItemsService } from 'src/app/services/api/items.service';
import { TestCaseApiService } from 'src/app/services/api/test-case-api.service';
import { EcuActions } from 'src/app/store/ecu/ecu.actions';
import { EcuState } from 'src/app/store/ecu/ecu.state';
import { TestCasesActions } from 'src/app/store/testCases/testCases.actions';
import { OffboardComponentsActions } from 'src/app/store/offboardComponents/offboardComponents.actions';
import { OffboardComponentsState } from 'src/app/store/offboardComponents/offboardComponents.state';
import { OrganizationalGroupsActions } from 'src/app/store/organizationalGroups/organizationalGroups.actions';
import { OrganizationalGroupsState } from 'src/app/store/organizationalGroups/organizationalGroups.state';
import { ProductPropertiesActions } from 'src/app/store/productPropeties/productProperties.actions';
import { ProductPropertiesState } from 'src/app/store/productPropeties/productProperties.state';
import { TestObjectsActions } from 'src/app/store/testObjects/testObjects.actions';
import { TestObjectsState } from 'src/app/store/testObjects/testObjects.state';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { enableAllFields, disableAllFields, createFormGroup } from 'src/app/services/helpers/formHelper';
import { ChangesService } from 'src/app/services/changes.service';
import { TestItemActionButton } from 'src/app/components/shared/test-item-action/test-item-action.component';
import { ButtonService } from 'src/app/services/button.service';
import { InputTypeNameConstants } from 'src/app/constants/propertyConstants';
import { GitLabScriptsActions } from 'src/app/store/gitLabScripts/gitLabScripts.actions';
import { GitLabScript } from 'src/app/models/dto/gitLabScript';
import { GitLabScriptsState } from 'src/app/store/gitLabScripts/gitLabScripts.state';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionService } from 'src/app/services/permission.service';
import { environment } from 'src/environments/environment';
import { ItemFactory } from 'src/app/services/item-factory/item.factory';
import { ItemType } from 'src/app/models/enums/itemType';
import { ReviewService } from 'src/app/services/review/review.service';
import { ReviewCommentGroup } from 'src/app/models/review/commentGroup';
import { FpcFamily } from 'src/app/models/dto/fpc-family';
import { FpcsState } from 'src/app/store/fpcs/fpcs.state';
import { FieldTypeEnum } from 'src/app/models/enums/fieldTypeEnum';
import { LaunchdarklyService } from 'src/app/services/api/launchdarkly.service';
import { Approval } from 'src/app/models/review/approval';
import { CheckedOutItemService } from 'src/app/services/checked-out-item.service';
import { UserFunctionsState } from 'src/app/store/user-functions/user-functions.state';
import { UserFunction } from 'src/app/models/dto/user-function-dto';
import { UserFunctionsActions } from 'src/app/store/user-functions/user-functions.actions';

@Component({
  selector: 'test-case',
  templateUrl: './test-case.component.html',
  styleUrls: ['./test-case.component.scss'],
  providers: [MessageService]
})
export class TestCaseComponent implements OnInit {

  @Input() testCaseUid: string = '';
  
  fields: formField[];
  alternativeViewShown: boolean = false;//can be removed?
  testItem: TestCaseDto;
  loggedInUsername: string;
  addPageActive: boolean = false;
  form: FormGroup;

  stepsLoadedToForm : boolean = false;
  stepsApplicable: boolean = true;
  linkedRequirementDto: RequirementDto;

  hasChange: boolean = false;
  changes: PreviewChange[] = [];
  showPreviewChanges: boolean = false;

  subject = new Subject<String>();
  private subscriptions: Subscription[] = [];
  currentUserSubscription: Subscription;
  valueSubscription: Subscription;
  formInitialValue: string;
  gitLabScriptLinksLoadedToForm: boolean = false;
  gitLabScriptLinkField: formField;

  informationFields: formField[];
  optionFields: formField[];
  fpcLinkField: formField;
  fpcLinksLoadedToForm: boolean;
  fpcDataLoaded: boolean = false;
  userFunctionLinkField: formField;
  userFunctionLinksLoadedToForm: boolean = false;
  actionButtons: TestItemActionButton;
  checkOutBtnToolTip: string;
  hideForm: boolean = false;

  isHero = environment.IsHero;

  readonly ReviewCommentGroup = ReviewCommentGroup;
  reviewVisable$ = this.reviewService.reviewVisable$;
  approvals$ = this.reviewService.approvals$
  approval: Approval
  approvalId: number
  enableReviewComments: boolean;
  clearReviewComments:boolean;

  itemType = ItemType.TestCase

  constructor(private testCaseApiService: TestCaseApiService,
    private authService: AuthService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private itemsService: ItemsService,
    public checkOutItemService: CheckedOutItemService,
    private store: Store,
    private changesService: ChangesService,
    private buttonService: ButtonService,
    private permissionService: PermissionService,
    private reviewService: ReviewService, 
    private launchdarklyService: LaunchdarklyService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.approvalId = parseInt(params.get("approvalId")) 
    });
    this.route.paramMap.subscribe(params => {
      // reload the item data when the route id changes
      let itemUidRouteParameter = params.get("testitemuid");

      this.actionButtons = this.buttonService.setDefaultInit()
      
      this.addPageActive = itemUidRouteParameter === RouteSegments.ADD;
      if (this.addPageActive) {
        this.actionButtons = this.buttonService.setAddButtons()
        this.hasChange = true;
        this.testCaseUid = '';
        this.loadData();
      }
      else {
        this.testCaseUid = itemUidRouteParameter;
        this.testCaseApiService.getByUid(this.testCaseUid).subscribe(response => {
          if (!response.success) {
            this.hideForm = true;
            this.clearValidationErrors();
            if(response.responseMessages?.length > 0)
              this.messageService.add({ severity: 'error', summary: response.responseMessages[0], sticky: true});
          }
          else
          {
            this.testItem = response.returnValue;
            this.loadData();
            if (this.testItem) {
              this.loadReviewData()
            }
          }
        })
      }
    });


    this.launchdarklyService.launchDarklyFlags$.subscribe((response) => {
      console.log(response)
    })
  }

  handleEnableReviewComments(enable: boolean) {
    this.enableReviewComments = enable;
  }

  handleClearingReviewComments(clear: boolean){
    this.clearReviewComments = clear;
  }

  loadData(){

    this.currentUserSubscription = this.authService.userData$.subscribe((result) => this.loggedInUsername = result.userName);
    this.setButtonState();
    this.testCaseApiService.getUiTemplate().subscribe((result) => {
      this.fields = result.sort(x => x.order);
      this.loadFields();
      this.form = createFormGroup(this.fields, ItemType.TestCase);
      this.populateDropdownValues().subscribe(x => {
        if(this.testItem) {
          this.populatePropertiesToFormGroupFromDto();
          this.populateLinksToFormGroupFromDto();
          this.setControlState();
          console.log("Test-Case stranica", this.testItem);
          // todo: check what is the purpose of this. It is commented now

          this.formInitialValue = JSON.stringify(this.form.value);

          //if item is locked start tracking changes
          if (this.testItem.lockedBy == this.loggedInUsername) 
            this.onChanges();
        }

        this.stepsLoadedToForm = true;
        this.setStepsApplicable();

        //track changes because of inputTypeName and steps
        if(this.addPageActive)
          this.onChanges();

      });
    });
  }

  loadReviewData() {
    this.reviewService.getApprovalObservable(this.approvalId).subscribe(
      (result) => {
        this.approval = result
      }
    )
    // load commments
    this.reviewService.loadComments(this.testItem?.uid, this.testItem?.version)
  }

  setButtonState(){
    this.buttonState()
    //subscribe to change of checked out item
    let subscription = this.checkOutItemService.checkedItemUidChange.subscribe(itemUid => this.buttonState());
    this.subscriptions.push(subscription);
  }

  buttonState(){//Put into service? Currently duplicated in TA/TC/TS
    this.checkOutBtnToolTip = '';
    if(this.addPageActive)
      return;
    if(this.checkOutItemService.exists() && this.testItem.lockedBy != this.loggedInUsername){
      this.actionButtons = this.buttonService.setReadonlyButtons();
      this.checkOutBtnToolTip = 'You have another item checked out. Check in or cancel that item first';
    }
    
    if(/*this.checkOutItemService.exists() && */this.testItem.lockedBy == this.loggedInUsername)//Issue when checked out item and f5
      this.actionButtons = this.buttonService.setCheckedOutButtons();
    
    if(!this.checkOutItemService.exists() && this.testItem.lockedBy != '' && this.testItem.lockedBy != this.loggedInUsername){
      this.actionButtons = this.buttonService.setReadonlyButtons();
      this.checkOutBtnToolTip = 'This item is already checked out by another user';
    }
    
    if(!this.checkOutItemService.exists() && this.testItem.lockedBy == '')
      this.actionButtons = this.buttonService.setEditButtons();

    this.permissionService.checkPermission(this.loggedInUsername, this.testItem.ownerIds).subscribe(hasPermission => {
      if(!hasPermission) {
        this.actionButtons = this.buttonService.setReadonlyButtons();
        this.checkOutBtnToolTip = 'You do not have permission to edit this item';
      }
    })
  }

  setControlState(){
    if(this.actionButtons.stateReadOnly || this.actionButtons.stateEdit)
      disableAllFields(this.fields, this.form);

    if(this.actionButtons.stateAdd || this.actionButtons.stateCheckedOut)
      enableAllFields(this.fields, this.form);
  }

  populatePropertiesToFormGroupFromDto(){
    for (var property in this.testItem) {
      const controlName = property.toLowerCase();
      const matchingControl = Object.keys(this.form.controls).find(control => control.toLowerCase() === controlName);
      if (matchingControl && matchingControl != formControlConstants.Description && this.testItem[property]){
        if (matchingControl == formControlConstants.DerivedFrom || matchingControl == formControlConstants.DetailLevel 
          || matchingControl == formControlConstants.TestType || matchingControl == formControlConstants.ObjectUnderTestType 
          || matchingControl == formControlConstants.InputTypeName){
            this.form.get(matchingControl).setValue({key: this.testItem[property], value: this.testItem[property]});
        } else {
          this.form.get(matchingControl).setValue(this.testItem[property]);
        }
      } 
    }

    for (var property in this.testItem.alternative){
      const controlName = property.toLowerCase();
      const matchingControl = Object.keys(this.form.controls).find(control => control.toLowerCase() === controlName);
      if(matchingControl && this.testItem.alternative[property]){
        if(matchingControl == formControlConstants.Environment)
          this.form.get(matchingControl).setValue({key: this.testItem.alternative[property], value: this.testItem.alternative[property]});
        else
          this.form.get(matchingControl).setValue(this.testItem.alternative[property])
      }
    }

    this.fpcLinksLoadedToForm = true;
  }

  populateLinksToFormGroupFromDto(){
    var values = [];
    this.fields.forEach(field => {
      switch (field.name){
        case formControlConstants.TCOwner: {
          this.testItem.ownerIds?.forEach(ownerId => {
            if(field.options.length > 0){
              var match = field.options.find(value => value.key == ownerId.toLowerCase())
              values.push(match)
            }
          })
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case(formControlConstants.TCTestGroup): {
          if(this.testItem.alternative.testGroups)
          this.testItem.alternative.testGroups.forEach(ownerId => {
            if(field.options.length > 0){
              var match = field.options.find(value => value.key == ownerId.toLowerCase())
              values.push(match)
            }
          })
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case formControlConstants.TCEcu: {
          if(this.testItem.ecuIds){
            var items = field.hierarchialOptions?.map(option => option.items).reduce((acc, val) => acc.concat(val), []);
            this.testItem.ecuIds?.forEach(ecuId => {
              
              var match = items.find(child => child.value == ecuId.toLowerCase());
              values.push(match)
            });
          }
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case formControlConstants.TCOffboardComponent: {
          this.testItem.offboardComponentIds?.forEach(offboardComponentId => {
            var match = field.options?.find(value => value.key == offboardComponentId.toLowerCase())
            values.push(match)
          })
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case formControlConstants.TCProductProperty: {
          this.testItem.productPropertyIds?.forEach(productPropertyId => {
            const matches = this.matchHierarchyDataFromDto(productPropertyId, field)
            values.push(...matches);
          });
          this.form.controls[field.name].patchValue(values);
          break;
        }
        case formControlConstants.TCUserFunction: {
          //ufs with names are loaded from backend already
          this.store.select<UserFunction[]>(UserFunctionsState.getUserFunctionHierarchy).subscribe(userFunctions => {
            this.testItem.userFunctions?.forEach(userFunction => {
              values.push(userFunction);
            });
            this.form.controls[field.name].patchValue(values);
            this.userFunctionLinksLoadedToForm = true;
          });
          break;
        }
        case formControlConstants.TCTestObject: {
          this.testItem.alternative.testObjects?.forEach(testObjectId => {
            var match = field.options?.find(value => value.key == testObjectId.toLowerCase())
            values.push(match)
          })
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case formControlConstants.TCGitLabScript: {
          this.testItem.alternative.gitLabScript?.forEach(gitLabScript => {
            var match = field.options?.find(value => value.key == gitLabScript.path)?.data;
            if(!match) {
              match = gitLabScript as GitLabScript;
            }
            values.push(match);
          })
          this.gitLabScriptLinksLoadedToForm = true;
          this.form.controls[field.name].patchValue(values);
          break;
        }
      }
      values = [];
    })
  }

  matchHierarchyDataFromDto(valueUid: String, field: formField){
    return field.hierarchialOptions
            ?.map(x => x.items.find(value => value.value === valueUid.toLowerCase()))
            .filter(match => match !== undefined);
  }

  populateDropdownValues() {
    this.store.dispatch(new OrganizationalGroupsActions.SetAllOrganizationalGroups());
    this.store.dispatch(new ProductPropertiesActions.SetAllProductProperties());
    this.store.dispatch(new OffboardComponentsActions.SetAllOffboardComponents());
    this.store.dispatch(new EcuActions.SetAllEcus());
    this.store.dispatch(new ProductPropertiesActions.SetAllProductProperties());
    this.store.dispatch(new TestObjectsActions.SetAllTestObjects())
    this.store.dispatch(new GitLabScriptsActions.SetAllGitLabScripts())
    this.store.dispatch(new UserFunctionsActions.SetUserFunctionHierarchy());

    let organizationalGroupsObservable = this.store.select<OrganizationalGroup[]>(OrganizationalGroupsState.getAllOrganizationalGroups);
    let productPropertiesObservable = this.store.select<ParentChildHierarchy[]>(ProductPropertiesState.getAllProductPropertiesHierarchy);
    let offboardComponentsObservable = this.store.select<OffboardComponent[]>(OffboardComponentsState.getAllOffboardComponents);
    let ecusObservable = this.store.select<ParentChildHierarchy[]>(EcuState.getEcuHierarchy);
    let testObjectObservable = this.store.select<TestObject[]>(TestObjectsState.getAllTestObjects);
    let gitLabScriptObservable = this.store.select<GitLabScript[]>(GitLabScriptsState.getAllGitLabScripts);
    let fpcObservable = this.store.select<FpcFamily[]>(FpcsState.getAllFpcs);
    let userFunctionsObservable = this.store.select<UserFunction[]>(UserFunctionsState.getUserFunctionHierarchy);

    const subscription = combineLatest([organizationalGroupsObservable, productPropertiesObservable, offboardComponentsObservable, ecusObservable, testObjectObservable, gitLabScriptObservable, userFunctionsObservable])
    .subscribe(([organizationalGroups, productProperties, offboardComponents, ecus, testObjects, gitLabScripts, userFunctions]) =>{
      if (!organizationalGroups || organizationalGroups.length == 0 || !productProperties 
        || !offboardComponents || offboardComponents.length == 0 || !ecus
        || !testObjects || testObjects.length == 0 || !gitLabScripts || !userFunctions) 
        return;

      // if(!environment.IsHero && productProperties.length == 0)
      //   return;
     
      var ownerFields = this.fields.filter(field => field.name === formControlConstants.TCOwner || field.name === formControlConstants.TCTestGroup)
      if(ownerFields)
        ownerFields.forEach(ownerField => {
          ownerField.options = organizationalGroups.map(x => { return {key: x.uid, value: x.name}});;
        })

      var productPropertyField = this.fields.find(x => x.name === formControlConstants.TCProductProperty);
      if(productPropertyField){
        productProperties.forEach(x => {
          x.items.sort((a, b) => a.label.localeCompare(b.label));
        })
        productPropertyField.hierarchialOptions = productProperties.sort((a, b) => a.label.localeCompare(b.label));
      }

      var offboardComponentField = this.fields.find(x => x.name === formControlConstants.TCOffboardComponent);
      if(offboardComponentField)
        offboardComponentField.options = offboardComponents.map(x => { return {key: x.uid, value: x.name}});

      var ecuField = this.fields.find(x => x.name === formControlConstants.TCEcu);
      if(ecuField)
        ecuField.hierarchialOptions = ecus;
      
      var testObjectField = this.fields.find(x => x.name === formControlConstants.TCTestObject);
      if(testObjectField)
        testObjectField.options = testObjects.map(x => { return {key: x.uid, value: x.name}});

      var gitLabScriptField = this.fields.find(x => x.name === formControlConstants.TCGitLabScript);
      if(gitLabScriptField)
        gitLabScriptField.options = gitLabScripts.sort((a, b) => a.name.localeCompare(b.name)).map(x => { return {key: x.path, value: x.name, data: x}});

      var userFunctionField = this.fields.find(x => x.name === formControlConstants.TCUserFunction);
      if(userFunctionField)
        userFunctionField.options = userFunctions.map(x => { return { key: x.id.toString(), value: x.name, data: x }});

      setTimeout(() => {
        this.subject.next("done");
      },5);  
    })

    //load fpcs separately because they are slow
    const fpcSubscription = fpcObservable.subscribe(fpcs => {
      if(!fpcs || fpcs.length == 0)
        return;

      var fpcField = this.fields.find(x => x.name === formControlConstants.FPC);
      if(fpcField) {
          fpcField.options = fpcs.map(x => { return { key: x.fpcCode, value: x.name, data: x }});
      
      this.fpcDataLoaded = true;
     }
    });

    //load userFunctions separately because they are slow
    const ufSubscription = userFunctionsObservable.subscribe(userFunctions => {
      if(!userFunctions || userFunctions.length == 0)
        return;

      var userFunctionField = this.fields.find(x => x.name === formControlConstants.TCUserFunction);
      if(userFunctionField)
        userFunctionField.options = userFunctions.map(x => { return { key: x.id.toString(), value: x.name, data: x }});
    });
    
    this.subscriptions.push(fpcSubscription);
    this.subscriptions.push(ufSubscription);
    this.subscriptions.push(subscription);
    return this.subject.asObservable();
  }

  checkOut(): void {
    if (!this.testCaseUid)
      return;

    this.itemsService.checkout(this.testCaseUid).subscribe(result => {
      if (result === false) {
        this.messageService.add({ severity: 'error', summary: 'Item check out failed', sticky: false});
        return;
      }

      this.actionButtons = this.buttonService.setCheckedOutButtons()
      enableAllFields(this.fields, this.form);
        
      this.testItem.lockedBy = this.loggedInUsername;
      this.testItem.lockedTime = new Date();   

      this.formInitialValue = JSON.stringify(this.form.value);
      this.onChanges();

      this.checkOutItemService.set(this.testItem?.uid, ItemType.TestCase);
      this.store.dispatch(new TestCasesActions.SetUpdateNeeded(true));
      this.hasChange = false;
    });
  }

  onChanges(): void {
    let subscription = this.form.valueChanges.subscribe(formValue => {
      this.hasChange =  this.formInitialValue !== JSON.stringify(this.form.value);

      if(!this.addPageActive) {
        let currentChanges = this.changesService.getDifferences(
          JSON.parse(this.formInitialValue),
          formValue,
          this.fields,
        );
        this.changes = currentChanges;
      }
      this.setStepsApplicable();
    });

    this.subscriptions.push(subscription);
  }

  setStepsApplicable() {
    var inputTypeName = this.form.controls[formControlConstants.InputTypeName]?.value;
    if(inputTypeName.value == InputTypeNameConstants.PassFail)
      this.stepsApplicable = true;
    else 
      this.stepsApplicable = false;
  }

  unlockItem(): void {    
    this.clearValidationErrors();

    if (this.addPageActive || this.testItem.lockedBy == '') {
      this.resetChanges();
      return;
    } 

    this.itemsService.unlock(this.testItem.uid).subscribe(() => {
      this.actionButtons = this.buttonService.setEditButtons()
      this.testItem.lockedBy = '';
      this.testItem.lockedTime = null;
      disableAllFields(this.fields, this.form);
      this.resetChanges();

      this.checkOutItemService.set();
      this.store.dispatch(new TestCasesActions.SetUpdateNeeded(true));
    });
  }

  onSubmit(): void {
    if(!this.validateForSubmit())
      return;

    let requestDto = ItemFactory.createItem(this.testItem, this.form, ItemType.TestCase) as TestCaseDto;

    if(this.addPageActive){
      this.testCaseApiService.create(requestDto).subscribe(response => {
        if(response.success)
          this.navigateToList();
        else
          this.showValidationErrors(response.responseMessages);
      });
    } else {
      this.testCaseApiService.update(requestDto).subscribe(response => {
        if(response.success) {
          this.unlockItem();
          this.navigateToList();
        } else {
          this.showValidationErrors(response.responseMessages);
        }
      });
    }
  }

  validateForSubmit() {
    this.clearValidationErrors();

    // if(this.form.invalid){
    //   this.messageService.add({ severity: 'error', summary: 'Not all required fields are populated', sticky: true});
    //   return false;
    // }

    if(!this.addPageActive && !this.hasChange) {
      this.messageService.add({ severity: 'error', summary: 'You made no changes', sticky: true});
      return false;
    }

    return true;
  }

  showValidationErrors(errors: string[]) {
    this.clearValidationErrors();
    this.messageService.add({ severity: 'error', summary: 'Item not saved!', sticky: true});

    errors.forEach(errorMessage => {
      this.messageService.add({ severity: 'error', summary: errorMessage, sticky: true });
    });
  }

  clearValidationErrors() {
    this.messageService.clear();
  }

  private navigateToList(): void {
    window.location.href = `/#/${RouteSegments.TEST_CASES}`
    window.location.reload();
  }

  resetChanges() {
    this.form.reset(this.formInitialValue ? JSON.parse(this.formInitialValue) : {});
    this.hasChange = false;
    this.changes = [];
  }

  loadFields() {
    this.generalInformationFields();
    this.optionsFields()
    this.fpcLinkField = this.fields.find(x => x.category === "FPC conditions" && !this.isHero);
    this.gitLabScriptLinkField = this.fields?.find(x => x.category === "Scripts");
    this.userFunctionLinkField = this.fields.find(x => x.fieldType === FieldTypeEnum.userFunction);
  }
  
  generalInformationFields() {
    if(this.fields){
      this.informationFields = this.fields.filter(x => x.category === "General information" && (this.isHero ? x.label !== 'Restricted View Access' : true));
      this.informationFields.find(x => x.label === "InputTypeName")?.options?.pop();//Remove orderSelection (for executor web)
    } else
      this.informationFields = [];
  }

  optionsFields() {//apply to property
    if(this.fields){
      this.optionFields = this.fields.filter(x => x.category === "Options" &&(x.label != 'UF' && x.label != 'TPC Expression') && (this.isHero ? x.label !== 'Product Properties' : true));//UF filter
    }
    else
      this.optionFields = [];
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      if (subscription && !subscription.closed) 
        subscription.unsubscribe();
    }
    this.subscriptions = [];
  }
}
