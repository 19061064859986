import { Approval } from "src/app/models/review/approval";
import { GraphQlApprovalOutput, GraphQlCommentOutput, GraphQlUserOutput } from "../models/output/graphql-review-output";
import { ItemTypeMapper } from "src/app/services/mappers/itemTypeMapper";
import { ReviewComment } from "src/app/models/review/comment";
import { ReviewCommentGroup } from "src/app/models/review/commentGroup";
import { ReviewUser } from "src/app/models/review/user";

export function convertGraphQlApprovalOutputToApproval(input: GraphQlApprovalOutput): Approval {
  return {
    id: input.Id,
    // parentId?: number;
    // parentTypeid?: number;
    itemUid: input.ItemUid,
    itemName: input.ItemName,
    itemVersion: input.ItemVersion,
    itemType: ItemTypeMapper.map(input.ItemTypeId),
    reviewers: input.Reviewers,
    approvalStatus: input.Status,
    approvalLevel: null, // todo: input.ApprovalLevel,
    approvalDate: input.DueDate ? new Date(new Date(input.DueDate + 'Z').toLocaleString()) : null,
    comments: [],
    regBy: input.RegBy,
    regTime: input.RegTime ? new Date(new Date(input.RegTime + 'Z').toLocaleString()) : null,
  }
}

export function convertGraphQlReviewCommentOutputToReviewComment(input: GraphQlCommentOutput): ReviewComment {
  return {
    id: input.Id,
    itemUid: input.ItemKey, // id of the item which is commented
    version: input.ItemVersion, // item version which is commented
    group: input.CommentGroup, // group of fields within the item
    text: input.Text,
    createdBy: input.RegBy,
    createdAt: input.RegTime ? new Date(new Date(input.RegTime + 'Z').toLocaleString()) : null,
    handled: input.Status == 1,
    handledBy: 'todo',
    handeledAt: undefined,
  
    newComment: false // local commments, still not saved 
  }
}

export function convertGraphQlUserOutputToReviewUser(input: GraphQlUserOutput): ReviewUser {
  return {
    id: input.Username,
    name: input.Name,
    company: ""
  }
}

