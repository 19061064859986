import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GitLabScriptsActions } from './gitLabScripts.actions';
import { GitLabScriptsStateModel } from './gitLabScripts.state.model';
import { GitlabScriptService } from 'src/app/services/api/gitlab-script.service';
import { GitLabScript } from 'src/app/models/dto/gitLabScript';

@State<GitLabScriptsStateModel>({
  name: 'gitLabScripts',
  defaults: {
    gitLabScripts: [],
  },
})
@Injectable()
export class GitLabScriptsState {
  constructor(private gitLabScriptsService: GitlabScriptService) {}

  @Action(GitLabScriptsActions.SetAllGitLabScripts)
  SetAllGitLabScripts(ctx: StateContext<GitLabScriptsStateModel>) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (state.gitLabScripts !== undefined && state.gitLabScripts.length > 0) 
      return;

    this.gitLabScriptsService.getAllFromDefaultProject().subscribe(gitLabScripts => {
      ctx.patchState({ gitLabScripts: gitLabScripts });
    });
  }

  @Action(GitLabScriptsActions.SetAllGitLabScriptsForProject)
  SetAllGitLabScriptsForProject(ctx: StateContext<GitLabScriptsStateModel>,
    action: GitLabScriptsActions.SetAllGitLabScriptsForProject) {
      ctx.setState({ gitLabScripts: [] });
      this.gitLabScriptsService.getScriptsForProjectId(action.projectId).subscribe(gitLabScripts => {
        ctx.patchState({ gitLabScripts: gitLabScripts });
      });
  }

  @Selector()
  static getAllGitLabScripts(state: GitLabScriptsStateModel) {
    return state.gitLabScripts;
  }
}
