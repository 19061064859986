import { FormGroup } from '@angular/forms';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { SpecificationDto } from 'src/app/models/dto/polarion/specificationDto';
import { TestCoverageDto } from 'src/app/models/dto/testCoverageDto';
import { v4 as uuidv4 } from 'uuid';

export class TestCoverageFactory {

  public static create(originalItem: TestCoverageDto, form: FormGroup): TestCoverageDto {
    let result: TestCoverageDto = {} as TestCoverageDto;
    result.uid = originalItem ? originalItem.uid : uuidv4().toString();
    result.itemNo = originalItem?.itemNo;

    result.name = form.value[formControlConstants.Name] ?? '';

    result.specifications = [] as SpecificationDto[];

    form.value[formControlConstants.TestCoverageSpecification]?.forEach(element => {
      var spec : SpecificationDto = {
        polarionId: element.polarionId,
        title : element.title,
        revision : element.revision,
        irmaVersion : element.irmaVersion,
        asil : element.asil
      };
      
      result.specifications.push(spec);
    });

    //empty properties
    result.comment = '';
    result.lockedBy = '';
    result.createdBy = '';
    result.modifiedBy = '';
    result.approvalStatus = '';
    result.approvedForTestLevel = '';

    return result;
  }
}
