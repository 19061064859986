import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';
 
//For syncfusion
registerLicense('ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Xd0RjW39cdXRURmBY');//Current. Lets replace this with self generated license
// registerLicense('');//Branka
//registerLicense('');//Eric
//registerLicense('');//Merin
//registerLicense('');//Milos
//registerLicense('');//Tijana

if (environment.IsProduction) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));