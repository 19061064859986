import { Component, Input, OnInit } from '@angular/core';
import { Subscribable, combineLatest, map, tap } from 'rxjs';
import { ReviewComment } from 'src/app/models/review/comment';
import { ReviewCommentGroup } from 'src/app/models/review/commentGroup';
import { ReviewService } from 'src/app/services/review/review.service';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { UserData } from 'src/app/models/user-data';
import { Approval } from 'src/app/models/review/approval';


@Component({
  selector: 'app-review-comments',
  templateUrl: './review-comments.component.html',
  styleUrls: ['./review-comments.component.scss']
})
export class ReviewCommentsComponent implements OnInit {

  @Input() commentGroup: ReviewCommentGroup
  @Input() itemUid: string
  @Input() itemVersion: number
  @Input() enableComments: boolean
  @Input() approval?: Approval


  newCommnet: ReviewComment
  comments: ReviewComment[] = []
  commentHandledEnabled: boolean = false

  comments$ = combineLatest([this.reviewService.comments$, this.authService.userData$]).pipe(
    map(([comments, userData]: [ReviewComment[], UserData]) => {
      return [comments.filter((comment) => comment.group == this.commentGroup), userData];
    }),
    map(([comments, userData]: [ReviewComment[], UserData]) => {
      comments.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
      if (comments.length == 0 || comments[comments.length - 1].createdBy != userData.userName) {
        comments.push(cloneDeep(this.newCommnet));
      }
      return [comments, userData];
    }),
    tap(([comments, userData]: [ReviewComment[], UserData]) => {
      this.comments = comments;
    }),
    map(([comments, userData]: [ReviewComment[], UserData]) => comments)
  );

  newCommentButtonDisabled = true
  private clearCommentsSubscription!: Subscription;
  private currentUser: UserData

  constructor (
    private reviewService: ReviewService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.newCommnet = {
      id: 0,
      itemUid: this.itemUid,
      version: this.itemVersion, 
      group: this.commentGroup,
      text: '',
      createdBy: '',
      createdAt: new Date(),
      handled: false,
      handledBy: '',
      handeledAt: undefined,
      newComment: true
    }
    this.authService.userData$.subscribe((userData) => this.currentUser = userData)
  }

  clearComments(){
    try {
      this.comments = this.comments.filter(comment => !comment.newComment);
    } catch (error) {
      console.error('Error clearing comments:', error);
    }
  }

  addNewCommentField () {

  }

  commentChanged (comment: ReviewComment) {
    // const newCommentFieldToAdd = this.comments.filter((comment) => !comment.text && comment.newComment).length == 0
    // if (newCommentFieldToAdd) {
    //   this.comments.push(cloneDeep(this.newCommnet))
    // }
  }

  onCommentHandledToggle(comment: ReviewComment, event: Event): void {
    const target = event.target as HTMLInputElement;
    const status = target.checked;
    comment.handled = status
    this.reviewService.setCommentHandledStatus(comment.id, status).subscribe();
    this.reviewService.updateComment(this.itemUid, this.itemVersion, comment, this.currentUser.userName).subscribe((result) => {
      console.log(result)
    })
  }

  isCommentHandledEnabled(comment: ReviewComment): boolean {
    if (this.approval && this.approval.regBy) {
      return this.approval.regBy.toLowerCase() == this.currentUser.userName.toLocaleLowerCase()
    }
    return false
  }

  saveComment(comment: ReviewComment) {
    if (!comment.createdBy) {
      comment.createdBy = this.currentUser.userName;
    }
    if (!comment.createdAt) {
      comment.createdAt = new Date();
    }
  
    if (comment.id) {
      this.reviewService.updateComment(this.itemUid, this.itemVersion, comment, this.currentUser.userName).subscribe((result) => {
        console.log(result)
      })
    } else {
      this.reviewService.saveComment(this.itemUid, this.itemVersion, comment, this.currentUser.userName).subscribe((result) => {
        comment.id = result.Id
      })
    }
  }
}
