import { Specification } from "src/app/models/dto/polarion/specification";
import { SpecificationDto } from "src/app/models/dto/polarion/specificationDto";
import { GraphQLSpecificationOutput } from "../models/output/graphql-specification-output";
import { convertGraphQLRequirementToDto } from "./requirement.mappers";

export function convertGraphQLSpecificationToDto(input: GraphQLSpecificationOutput): SpecificationDto{
    return {
        polarionId: input.PolarionId,
        title: input.Title,
        irmaVersion: input.IrmaVersion,
        revision: input.Revision,
        asil: input.Asil,
        status: input.Status,
        maturityStatus: input.MaturityStatus,
        linkedRequirement: convertGraphQLRequirementToDto(input.LinkedRequirements?.[0])//null
    }
}
export function convertGraphQLSpecificationToType(input: GraphQLSpecificationOutput): Specification{
    if(input == null)
        return null;
    return {
        polarionId: input.PolarionId,
        irmaVersion: input.IrmaVersion.toString(),
    }
}