import { FormGroup } from '@angular/forms';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { TestSuiteAlternativeDto } from 'src/app/models/dto/testSuiteAlternativeDto';
import { TestSuiteDto } from 'src/app/models/dto/testSuiteDto';
import { v4 as uuidv4 } from 'uuid';

export class TestSuiteFactory {

  public static create(originalItem: TestSuiteDto, form: FormGroup): TestSuiteDto {
    let result: TestSuiteDto = {} as TestSuiteDto;
    result.uid = originalItem?.uid ?? uuidv4().toString();
    result.itemNo = originalItem?.itemNo;

    result.name = form.value[formControlConstants.Name] ?? '';
    result.label = form.value[formControlConstants.Label] ?? '';
    result.promptForMileage = form.value[formControlConstants.PromptForMileage] ?? false;
    result.showNextTestCaseAutomatically = form.value[formControlConstants.ShowNextTestCaseAutomatically] ?? false;
    result.ownerIds = form.value[formControlConstants.TSOwner]?.map(x => x.key) ?? [];
    result.userFunctionIds = form.value[formControlConstants.TSUserFunction]?.map(x => x.key) ?? [];
    result.offboardComponentIds = form.value[formControlConstants.TSOffboardComponent]?.map(x => x.key) ?? [];
    result.productPropertyIds = form.value[formControlConstants.TSProductProperty]?.map(x => x.value) ?? [];
    result.testCases = form.value[formControlConstants.TSTestCase] ?? [];
    result.testSuites = form.value[formControlConstants.TSTestSuite] ?? [];
    result.description = form.value[formControlConstants.Description] ?? '';

    result.alternative = {} as TestSuiteAlternativeDto;
    result.alternative.uid = originalItem?.alternative?.uid ?? uuidv4().toString();

    result.alternative.fpc = form.value[formControlConstants.FPC] ?? '';
    result.alternative.tpc = form.value[formControlConstants.TPC] ?? '';
    
    //empty properties
    result.comment = '';
    result.lockedBy = '';
    result.createdBy = '';
    result.modifiedBy = '';
    result.approvalStatus = '';
    result.approvedForTestLevel = '';

    return result;
  }
}
