import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { OffboardComponentsApiService } from "src/app/services/api/offboard-components-api.service";
import { OffboardComponentsActions } from "./offboardComponents.actions";
import { OffboardComponentsStateModel } from "./offboardComponents.state.model";

@State<OffboardComponentsStateModel>({
    name: 'offboardComponents',
    defaults: {
        offboardComponents: []
    },
})
@Injectable()
export class OffboardComponentsState{
    constructor(
private offboardComponentsApiService: OffboardComponentsApiService) {}

    @Action(OffboardComponentsActions.SetAllOffboardComponents)
    SetAllOffboardComponents(ctx: StateContext<OffboardComponentsStateModel>) {
        const state = ctx.getState();
        //don't update state if we have already downloaded items
        if ( state.offboardComponents !== undefined && state.offboardComponents.length > 0) return;
            
        this.offboardComponentsApiService.get().subscribe((offboardComponent) => {
            ctx.patchState({offboardComponents: offboardComponent})
        });
    }
    @Selector()
    static getAllOffboardComponents(state: OffboardComponentsStateModel){
        return state.offboardComponents;
    }
}