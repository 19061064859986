import { Injectable } from '@angular/core';
import { TestItemActionButton } from '../components/shared/test-item-action/test-item-action.component';
import { environment } from 'src/environments/environment';
import { ReviewService } from './review/review.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ButtonService {
  
  actionButtons: TestItemActionButton;
  reviewOnSubscription: Subscription;
  reviewEnabled: boolean;

  constructor(private reviewService: ReviewService) {
    this.reviewOnSubscription = this.reviewService.reviewEnabled$.subscribe(reviewEnabled => {
      this.reviewEnabled = reviewEnabled;
   });
  }

  ngOnDestroy() {
    if (this.reviewOnSubscription) {
      this.reviewOnSubscription.unsubscribe();
    }
  }

  setDefaultInit(){
    return this.actionButtons = {
      showCancel: false,
      enableCancel: false,
      showCheckIn: false,
      enableCheckIn: false,
      showCheckOut: false,
      enableCheckOut: false,
      showSave: false,
      enableSave : false,
      showPreviewChanges: false,
      enablePreviewChanges: false,
      showViewParentItems: false,
      stateAdd: false,
      stateEdit: false,
      stateCheckedOut: false,
      stateReadOnly: false,
      sendToReview: true,
    }
  }
  setAddButtons(){
    return this.actionButtons = {
      showCancel: true,
      enableCancel: true,
      showCheckIn: false,
      enableCheckIn: false,
      showCheckOut: false,
      enableCheckOut: false,
      showSave: true,
      enableSave : true,
      showPreviewChanges: false,
      enablePreviewChanges: false,
      showViewParentItems: false,
      stateAdd: true,
      stateEdit: false,
      stateCheckedOut: false,
      stateReadOnly: false,
      sendToReview: this.reviewEnabled
    }
  }
  setEditButtons(){
    return this.actionButtons = {
      showCancel: false,
      enableCancel: false,
      showCheckIn: false,
      enableCheckIn: false,
      showCheckOut: true,
      enableCheckOut: true,
      showSave: false,
      enableSave : false,
      showPreviewChanges: false,
      enablePreviewChanges: false,
      showViewParentItems: true,
      stateAdd: false,
      stateEdit: true,
      stateCheckedOut: false,
      stateReadOnly: false,
      sendToReview: this.reviewEnabled
    }
  }
  setCheckedOutButtons(){
    return this.actionButtons = {
      showCancel: true,
      enableCancel: true,
      showCheckIn: true,
      enableCheckIn: true,
      showCheckOut: false,
      enableCheckOut: false,
      showSave: false,
      enableSave : false,
      showPreviewChanges: environment.IsHero || environment.IsProduction ? false : true,
      enablePreviewChanges: true,
      showViewParentItems: true,
      stateAdd: false,
      stateEdit: false,
      stateCheckedOut: true,
      stateReadOnly: false,
      sendToReview: this.reviewEnabled
    }
  }
  setReadonlyButtons(){
    return this.actionButtons = {
      showCancel: false,
      enableCancel: false,
      showCheckIn: false,
      enableCheckIn: false,
      showCheckOut: true,
      enableCheckOut: false,
      showSave: false,
      enableSave : false,
      showPreviewChanges: false,
      enablePreviewChanges: false,
      showViewParentItems: true,
      stateAdd: false,
      stateEdit: false,
      stateCheckedOut: false,
      stateReadOnly: true,
      sendToReview: this.reviewEnabled
    }
  }
  isButtonsStateMatch(buttonsState: TestItemActionButton): boolean {
    return (
      buttonsState.showCancel === this.actionButtons.showCancel &&
      buttonsState.enableCancel === this.actionButtons.enableCancel &&
      buttonsState.showCheckIn === this.actionButtons.showCheckIn &&
      buttonsState.enableCheckIn === this.actionButtons.enableCheckIn &&
      buttonsState.showCheckOut === this.actionButtons.showCheckOut &&
      buttonsState.enableCheckOut === this.actionButtons.enableCheckOut &&
      buttonsState.showSave === this.actionButtons.showSave &&
      buttonsState.enableSave === this.actionButtons.enableSave &&
      buttonsState.showPreviewChanges === this.actionButtons.showPreviewChanges &&
      buttonsState.enablePreviewChanges === this.actionButtons.enablePreviewChanges &&
      buttonsState.showViewParentItems === this.actionButtons.showViewParentItems &&
      buttonsState.sendToReview === this.actionButtons.sendToReview
    );
  }
}
