import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formField } from 'src/app/models/formField';
import { TreeNode } from 'primeng/api';
import { FpcFamily } from 'src/app/models/dto/fpc-family';
import { Fpc } from 'src/app/models/dto/fpc';
import { Scenario, UseCase, UserFunction, UserFunctionBase } from 'src/app/models/dto/user-function-dto';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { UserFunctionType } from 'src/app/models/enums/userFunctionType';

@Component({
  selector: 'app-linked-hierarchy-list-search',
  templateUrl: './linked-hierarchy-list-search.component.html',
  styleUrls: ['./linked-hierarchy-list-search.component.scss']
})
export class LinkedHierarchyListSearchComponent implements OnInit {
  @Input() field!: formField;
  @Output() itemSelected = new EventEmitter();

  itemsTree: TreeNode<FpcFamily | UserFunction>[] = [];
  isUf: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.isUf = this.field.name === formControlConstants.TCUserFunction;
    this.populateTree();
  }

  populateTree() {
    this.itemsTree = [];

    if(this.isUf)
      this.populateUserFunctionTree();
    else
      this.populateFpcTree();
  }

  private populateUserFunctionTree() {
    this.itemsTree = [];
    this.field.options.forEach(parentLink => {
      let parentNode = this.convertUserFunctionToNode(parentLink.data as UserFunction);
      this.itemsTree.push(parentNode);
      this.attachUseCases(parentNode);
    })};

  private populateFpcTree() {
    this.field.options.forEach(parentLink => {
      let parentNode = this.convertFpcFamilyToNode(parentLink.data as FpcFamily);
      this.itemsTree.push(parentNode);
      this.attachFpcChildren(parentNode);
    });
  }

  private attachFpcChildren(parentNode:TreeNode<FpcFamily>) {
    let childern = parentNode.data.variants;
    childern?.forEach(child => {
      let childNode = this.convertFpcVariantToNode(child);
      if(!parentNode.children)
        parentNode.children = [];
      parentNode.expanded = true;
      parentNode.children.push(childNode);
    })
  }

  private attachUseCases(parentNode:TreeNode<UserFunction>) {
    let childern = parentNode.data.useCases;
    childern?.forEach(child => {
      let childNode = this.convertUseCaseOrScenarioToNode(child);
      childNode.label = UserFunctionType.UseCase;
      this.attachScenarios(childNode, child.scenarios);
      if(!parentNode.children)
        parentNode.children = [];
      parentNode.children.push(childNode);
    })
  }

  private attachScenarios(parentNode:TreeNode<UseCase>, scenarios: Scenario[]) {
    let childern = scenarios;
    childern?.forEach(child => {
      let childNode = this.convertUseCaseOrScenarioToNode(child);
      childNode.label = UserFunctionType.Scenario;
      if(!parentNode.children)
        parentNode.children = [];
      parentNode.children.push(childNode);
    })
  }

  private convertUseCaseOrScenarioToNode(source: UseCase | Scenario) {
    if(!source)
      return undefined; 

    let node: TreeNode<UserFunction> = {
      key: source.id.toString(),
      label: source.name,
      data:  { id: source.id, name: source.name, useCases: undefined } as UserFunction,
    };

    return node;
  }

  private convertUserFunctionToNode(source: UserFunction) {
    if(!source)
      return undefined; 

    let node: TreeNode<UserFunction> = {
      key: source.id.toString(),
      label: UserFunctionType.UserFunction,
      data: source,
    };

    return node;
  }

  private convertFpcFamilyToNode(source: FpcFamily) {
    if(!source)
      return undefined; 

    let node: TreeNode<FpcFamily> = {
      key:  source.fpcCode,
      label: source.name,
      data: source,
    };

    return node;
  }

  private convertFpcVariantToNode(source: Fpc) {
    if(!source)
      return undefined; 

    let node: TreeNode<FpcFamily> = {
      key:  source.fpcCode,
      label: source.name,
      data:  { fpcCode: source.fpcCode, name: source.name, variants: undefined } as FpcFamily,
    };

    return node;
  }

  onRowSelect(event) {
    if(!this.isUf) {
      if(!event.node.parent)
        return;
      
      var nameWithFpcFamily = event.node.parent.data.name + ' - '  + event.node.data.name;
      var fpc: Fpc = {fpcCode: event.node.data.fpcCode, name: nameWithFpcFamily, notCondition: false};
      this.itemSelected.emit(fpc);
    } else {
      var selectedUfHierarchy = this.convertToUserFunctionHierarchy(event.node);
        this.itemSelected.emit(selectedUfHierarchy);
    }
  }

  private convertToUserFunctionHierarchy(node: TreeNode<any>): UserFunction {
    var selectedUfBase = this.convertNodeToUserFunctionBase(node);
    if(node.label == UserFunctionType.UserFunction)
      return selectedUfBase as UserFunction;

    if (node.label == UserFunctionType.UseCase) {
      var selectedUf = this.convertNodeToUserFunctionBase(node.parent) as UserFunction;
      selectedUf.useCases = [selectedUfBase];
      return selectedUf;
    }
    
    if(node.label == UserFunctionType.Scenario) {
      var selectedUc = this.convertNodeToUserFunctionBase(node.parent) as UseCase;
      var selectedUf = this.convertNodeToUserFunctionBase(node.parent?.parent) as UserFunction;
      selectedUc.scenarios = [selectedUfBase];
      selectedUf.useCases = [selectedUc];
      return selectedUf;
    }

    return undefined;
  }

  private convertNodeToUserFunctionBase(node: TreeNode<any>) {
    if(!node)
      return undefined; 

    let uf: UserFunctionBase = {
      id:  node.data?.id,
      name: node.data?.name,
    };

    return uf;
  }
  
}
