import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PreviewChange } from '../models/previewChange';
import { formField } from '../models/formField';
import { formControlConstants } from '../constants/formControlConstants';

@Injectable({
  providedIn: 'root',
})
export class ChangesService {
  constructor(private store: Store) {}

  getDifferences( //insert description as parameter?
    initialValue: any,
    currentValue: any,
    fields: formField[],
    //description?: string
  ): PreviewChange[] {
    let currentChanges: PreviewChange[] = [];
    Object.keys(initialValue).forEach(key => {
      let hasChange = initialValue[key] != currentValue[key];
      if (!hasChange)
        return;

      let fieldName;
      let oldValue = '';
      let newValue = '';

      let field = fields.find((x) => x.label === key);
      if (field) {
        fieldName = field.label;
        if (field.label === formControlConstants.TPC) {
          oldValue = this.store.selectSnapshot(state => state.tpc.initialValue); // get initial translated value
          newValue = this.store.selectSnapshot(state => state.tpc.currentValue);
        } else {
          oldValue = initialValue[key];
          newValue = currentValue[key];
        }
      }

      currentChanges.push({
        changeName: fieldName,
        oldValue: oldValue,
        newValue: newValue,
      });
    });

    return currentChanges;
  }
}
