<p-toast position="bottom-center"></p-toast>
<p-treeTable [value]="linkedItemsTree" [scrollable]="true" *ngIf="field && field.name">
  <ng-template pTemplate="header">
      <tr>
        <th style="width: 5%"></th>
          <th style="width: 65%">{{ field.label }}</th>
          <th style="width: 10%" >
            <p *ngIf="showVersion">Version</p>
          </th>
          <th style="width: 20%">
            <button 
              [disabled]="locked"
              type="button"
              class="sdds-btn sdds-btn-secondary sdds-btn-sm addButton"
              id="displayRootSearch{{ field.name }}">
              <i class="pi pi-plus" style="font-size: initial"></i>
            </button>
        </th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr [ttRow]="rowNode">
          <td style="width: 70%">
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              <p-chip *ngIf="rowData.operator" class="operator-value-chip"> {{ rowData.operator }} {{ rowData.value }}</p-chip>
              <a *ngIf="field.name === FormControlConstants.TSTestCase" [routerLink]="['/', RouteConstants.TEST_CASES, rowData?.testItemUid]">{{ rowData.itemNo }} : {{ rowData.name }}</a>
              <a *ngIf="field.name === FormControlConstants.TSTestSuite" [routerLink]="['/', RouteConstants.TEST_SUITES, rowData?.testItemUid]">{{ rowData.itemNo }} : {{ rowData.name }}</a>
              <a *ngIf="field.name !== FormControlConstants.TSTestCase && field.name !== FormControlConstants.TSTestSuite">{{ rowData?.data?.name }}</a>
          </td>
          <td *ngIf="showVersion" style="width: 10%">
            <p>{{ rowData?.version }}</p>
          </td>
          <td class="link-actions" style="width: 20%">
            <button *ngIf="!locked"
              type="button"
              class="sdds-btn sdds-btn-secondary sdds-btn-sm"
              (click)="addOrEditLink(rowNode.node, true)"
              id="displaySearch{{ rowNode.node.key }}">
              <i class="pi pi-plus" style="font-size: initial"></i>
            </button>

            <button *ngIf="!locked && !form.disabled && hasOperatorAndValueOnLink && rowNode.parent"
              type="button"
              class="sdds-btn sdds-btn-secondary sdds-btn-sm edit-button"
              (click)="addOrEditLink(rowNode, false)"
              id="edit{{ rowNode.node.key }}">
              <i class="pi pi-pencil" style="font-size: initial"></i>
            </button>

            <button *ngIf="!locked"
              type="button"
              class="sdds-btn sdds-btn-secondary sdds-btn-sm removeItem"
              (click)="removeItem(rowNode.node)">
              <i class="pi pi-trash" style="font-size: initial"></i>
            </button>
          </td>

          <sdds-modal size="sm" selector="#displaySearch{{ rowNode.node.key }}" id="add-operator{{rowNode.node.key}}" prevent>
            <h5 slot="sdds-modal-headline">{{ field.label }}</h5>
            <div slot="sdds-modal-body">
              <div *ngIf="field && field.options.length > 0; else noItems">
                <div *ngIf="hasOperatorAndValueOnLink" class="link-operator-value">
                  <span class="p-float-label">
                    <app-dynamic-form-field [field]="operatorField" [form]="linkOperatorFormGroup" >
                    </app-dynamic-form-field>
                  </span>
                  <span class="p-float-label">
                    <app-dynamic-form-field [field]="operatorValueField" [form]="linkOperatorFormGroup">
                    </app-dynamic-form-field>
                  </span>
                </div>
                Select item(s) from the list
                <app-linked-item-list-search
                  [field]="field" [locked]="hasOperatorAndValueOnLink && !linkOperatorFormGroup.valid" (itemSelected)="addItemToSelectedNode($event)">
                </app-linked-item-list-search>
              </div>
              <ng-template #noItems>
                Items not found
              </ng-template>
            </div>
          </sdds-modal>

          <sdds-modal size="sm" selector="#edit{{ rowNode.node.key }}" id="edit-operator{{rowNode.node.key}}" prevent>
            <h5 slot="sdds-modal-headline">{{ rowData.name }}</h5>
            <div slot="sdds-modal-body">
              <div class="link-operator-value">
                <span class="p-float-label">
                  <app-dynamic-form-field [field]="operatorField" [form]="linkOperatorFormGroup">
                  </app-dynamic-form-field>
                </span>
                <span class="p-float-label">
                  <app-dynamic-form-field [field]="operatorValueField" [form]="linkOperatorFormGroup">
                  </app-dynamic-form-field>
                </span>
              </div>
              <button type="button" class="sdds-btn sdds-btn-secondary sdds-btn-sm" [disabled]="!linkOperatorFormGroup.valid" data-dismiss-modal (click)="updateLinkOperator(rowData)">
                Update
              </button>
            </div>
          </sdds-modal>
      </tr>
  </ng-template>
</p-treeTable>


<sdds-modal size="sm" selector="#displayRootSearch{{ field.name }}">
  <h5 slot="sdds-modal-headline">{{ field?.label }}</h5>
  <div slot="sdds-modal-body">
    <div *ngIf="field && field.options?.length > 0; else noItems">
      Select item(s) from the list
      <app-linked-item-list-search 
          [field]="field" (itemSelected)="addItemToRoot($event)">
      </app-linked-item-list-search>
    </div>
    <ng-template #noItems>
      Items not found
    </ng-template>
  </div>
</sdds-modal>
