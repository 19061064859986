import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { Observable, filter, map, shareReplay, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as LDClient from 'launchdarkly-js-client-sdk';


@Injectable({
  providedIn: 'root'
})
export class LaunchdarklyService {

  /**
   * Observable of the LaunchDarkly client, which emits a new client when user data changes.
   */
  private launchDarklyClient$ = this.authService.userData$.pipe(
    filter((userData) => !!userData.isLoggedIn),
    map(userData => {
      const context: LDClient.LDContext = {
        key: (userData.userName ?? '') !== '' ? userData.userName + '' : 'anonymous',
        name: (userData.userName ?? '') !== '' ? userData.userName + '' : 'anonymous',
        country: (userData.companyName ?? '') !== '' ? userData.companyName : 'anonymous',
      }

      return LDClient.initialize(environment.launchDarklyClientId, context)
    }),
    shareReplay(1)
  )

  /**
   * Observable of the LaunchDarkly flags, using the client observable above.
   * Creates an observable of flag changes in the current client which are then shared with subscribers.
   */
  public launchDarklyFlags$ = this.launchDarklyClient$.pipe(
    switchMap(client => new Observable<{ [key: string]: any }>(subscriber => {
      client.on('ready', () => {
        subscriber.next(client.allFlags());
      });

      client.on('change', () => {
        subscriber.next(client.allFlags());
      });
    })),
    shareReplay(1)
  )

  /**
   * Constructor.
   * @param authService To get user data.
   */
  constructor(private authService: AuthService) { }

}
