<p-toast position="bottom-center"></p-toast>
<p-table [value]="linkedItems" [reorderableColumns]="true" dataKey="uid" (onRowReorder)="onRowReorder($event)">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3%"></th>
      <th style="width: 10%">ItemNo </th>
      <th style="width: 70%">{{ field.label }} </th>
      <ng-container *ngIf="field.name === FormControlConstants.TCGitLabScript">
        <th style="width: 22%" *ngIf="field.name === FormControlConstants.TCGitLabScript"> Project name</th>
        <th style="width: 22%" *ngIf="field.name === FormControlConstants.TCGitLabScript"> Foreign key</th>
        <th class="aligned-buttons">
          <button 
            [disabled]="locked"
            type="button"
            class="sdds-btn sdds-btn-secondary sdds-btn-sm setting-button"
            id="displaySettings{{ field.name }}">
            <i class="pi pi-cog" style="font-size: initial"></i>
          </button>
          <button 
            [disabled]="locked"
            type="button"
            class="sdds-btn sdds-btn-secondary sdds-btn-sm addButton"
            id="displaySearch{{ field.name }}">
            <i class="pi pi-plus" style="font-size: initial"></i>
          </button>
        </th>
      </ng-container>
      <th style="width: 10%" >
        <p *ngIf="showVersion && field.name != FormControlConstants.TCGitLabScript">Version</p>
      </th>
      <th  style="width: 20%" *ngIf="field.name != FormControlConstants.TCGitLabScript">
        <button 
          type="button"
          [disabled]="locked"
          class="sdds-btn sdds-btn-secondary sdds-btn-sm addButton"
          id="displaySearch{{ field.name }}">
          <i class="pi pi-plus" style="font-size: initial"></i>
       </button>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item let-index="rowIndex">
    <tr [pReorderableRow]="index" *ngIf="item">
      <td>
        <span *ngIf="!locked" class="pi pi-bars" pReorderableRowHandle></span>
      </td>
      
      <td>
        <a *ngIf="field.name === FormControlConstants.TATestCase" [routerLink]="['/', RouteConstants.TEST_CASES, item?.key]">{{ item?.data?.itemNo }}</a>
        <a *ngIf="field.name === FormControlConstants.TSpecTestAnalysis" [routerLink]="['/', RouteConstants.TEST_ANALYSIS, item?.key]">{{ item?.data?.itemNo }}</a>
        <a *ngIf="field.name === FormControlConstants.TCGitLabScript">{{ item?.data?.itemNo }}</a>
      </td>

      <td>{{ item?.data?.name }}</td>

      <td> 
        <p *ngIf="field.name != FormControlConstants.TCGitLabScript">{{ item?.data?.version }}</p>
      </td>

      <td *ngIf="field.name === FormControlConstants.TCGitLabScript">{{ item.data.project?.name ?? 'unknown'}}</td>
      <td *ngIf="field.name === FormControlConstants.TCGitLabScript">{{ item.data.foreignSystemKey ?? item.data.missingForeignSystemKeyMessage }}</td>
      <td>
        <button *ngIf="!locked"
          type="button"
          class="sdds-btn sdds-btn-secondary sdds-btn-sm removeItem"
          (click)="removeItem(item)">
          <i class="pi pi-trash" style="font-size: initial"></i>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
<br />

<sdds-modal size="sm" selector="#displaySearch{{ field.name }}">
  <h5 slot="sdds-modal-headline">{{ field.label }}</h5>
  
  <div  slot="sdds-modal-body">
    <div *ngIf="field.name != FormControlConstants.TCGitLabScript" class="linked-item-filter">
      <p-radioButton name="item-filter" [value]="0" [(ngModel)]="itemTableFilter" inputId="itemTableFilter1"
        (onClick)="filterItems()"></p-radioButton>
      <label for="itemTableFilter1" class="ml-2">All Items</label>
      
      <p-radioButton name="item-filter" [value]="2" [(ngModel)]="itemTableFilter" inputId="itemTableFilter3"
      (onClick)="filterItems()"></p-radioButton>
      <label for="itemTableFilter3" class="ml-2">Created By Me</label>
    </div>

    <div *ngIf="field.options && field.options.length > 0; else noItems">
      Select item(s) from the list
      <app-linked-item-list-search
        [field]="field" (itemSelected)="addItem($event)">
      </app-linked-item-list-search>
    </div>
    <ng-template #noItems>
      <p *ngIf="field.name != FormControlConstants.TCGitLabScript">Items not found.</p>
      <p *ngIf="field.name === FormControlConstants.TCGitLabScript">Items not found for chosen project.  <br />Check your token and project ID in settings.</p>
    </ng-template>
  </div>
</sdds-modal>


<sdds-modal size="sm" selector="#displaySettings{{ field.name }}">
  <h4 slot="sdds-modal-headline">{{ field.label }} settings</h4>

  <div  slot="sdds-modal-body" class="gitlab-settings-body">
    <app-dynamic-form-field [field]="tokenField" [form]="gitlabSettingsFormGroup">
    </app-dynamic-form-field>
    <app-dynamic-form-field [field]="projectIdField" [form]="gitlabSettingsFormGroup">
    </app-dynamic-form-field>
  </div>

  <button slot="sdds-modal-actions" type="button" class="sdds-btn sdds-btn-primary sdds-btn-md" data-dismiss-modal (click)="saveGitlabSettings()">Save</button>
  <button slot="sdds-modal-actions" type="button" class="sdds-btn sdds-btn-secondary sdds-btn-md" data-dismiss-modal>Cancel</button>

</sdds-modal> 
