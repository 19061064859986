import { Injectable } from '@angular/core';
import { combineLatest, filter, map, shareReplay } from 'rxjs';
import { setContext } from '@apollo/client/link/context';
import { v4 as uuidv4 } from 'uuid'
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "apollo-angular/http";
import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client/core';
import { AuthService } from '../auth.service';
import { AbstractionLayerConfig, environment } from 'src/environments/environment';
import { LaunchdarklyService } from './launchdarkly.service';


@Injectable({
  providedIn: 'root'
})
export class ApolloClientService {

  constructor(
    private httpLink: HttpLink, 
    private authService: AuthService, 
    private launchdarklyService: LaunchdarklyService
  ) { }

  /**
   * Observable of apollo client.
   */
  public apolloClient$ = combineLatest({ 
    userData: this.authService.userData$, 
    launchDarklyFlags: this.launchdarklyService.launchDarklyFlags$ 

  }).pipe(
    map(source => {
      // Create http link.
      const launchDarklyEnvironmentUri = source.launchDarklyFlags['staging'] ? AbstractionLayerConfig.stagingUri : AbstractionLayerConfig.releaseUri
      const uriLink = this.httpLink.create({ uri: launchDarklyEnvironmentUri });
      console.log('AbstractionLayerConfig url: ' + launchDarklyEnvironmentUri)

      // Set headers for authentication and correlation.
      const headerLink = setContext((_, { headers }) => {
        const correlationId = uuidv4()
        const requestId = uuidv4()
        const sessionId = source.userData.sessionUID ?? 'anonymous'
        console.log("graphql sessionId: " + sessionId)
    
        return {
          headers: {
            ...headers,
            'x-correlation-id': correlationId,
            'x-request-id': requestId,
            'authorization': sessionId
          }
        }
      });

      // Configure error handling.
      const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
        const correlationId = operation.getContext()['headers']['x-correlation-id'];
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, correlationId)
          });
        };
    
        if (networkError) {
          console.log(`[Network error]: ${JSON.stringify(networkError)}`, correlationId)
          if (networkError.message.includes('401')) {
            this.authService.clearSession()
          }
        }
      });

      // Start and end time for requests, for debug purposes.
      const timeStartLink = new ApolloLink((operation, forward) => {
        const correlationId = operation.getContext()['headers']['x-correlation-id'];
        const startTime = new Date();
        console.log(`[${operation.operationName}] Sending request`, correlationId);
        operation.setContext({ start: startTime });
        return forward(operation);
      });
    
      const timeEndLink = new ApolloLink((operation, forward) => {
        return forward(operation).map((data) => {
          const correlationId = operation.getContext()['headers']['x-correlation-id'];
          const duration = new Date().getTime() - operation.getContext()['start'].getTime();
          console.log(`[${operation.operationName}] took ${duration} to complete`, correlationId);
          return data;
        })
      });

      // Return client to subscribers.
      return new ApolloClient({
        link: from([ errorLink, headerLink, timeStartLink, timeEndLink, uriLink ]),
        cache: new InMemoryCache(),
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
          },
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
          }}
      })
    }),
    filter(client => !!client),
    shareReplay(1)
  )
}
