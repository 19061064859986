<p-toast position="bottom-center"></p-toast>

<p-treeTable [value]="fpcsTree" [scrollable]="true" *ngIf="field && field.name">
  <ng-template pTemplate="header">
      <tr>
        <th style="width: 1%"></th>
          <th style="width: 83%">{{ field.label }}: {{ form.controls[field.name]?.value }}</th>
          <th style="width: 11%">
            <button [disabled]="locked"
                type="button"
                class="sdds-btn sdds-btn-secondary sdds-btn-sm addButton"
                id="displayRootSearch{{ field.name }}">
                <i class="pi pi-plus"></i>
            </button>
        </th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr [ttRow]="rowNode">
          <td style="width: 70%">
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              <p-chip *ngIf="rowData.notCondition" class="operator-value-chip" (click)="revertNotCondition(rowNode.node)"> NOT </p-chip>
              {{ rowData.fpcCode }}: {{ rowData.name }}
          </td>
          <td class="link-actions" style="width: 20%">
            <button *ngIf="!locked"
              type="button"
              class="sdds-btn sdds-btn-secondary sdds-btn-sm"
              (click)="rememberSelectedNode(rowNode.node)"
              id="displaySearch{{ rowNode.node.key }}">
              <i class="pi pi-plus"></i>
            </button>

            <button *ngIf="!locked"
              type="button"
              class="sdds-btn sdds-btn-secondary sdds-btn-sm notCondition"
              pTooltip="Change NOT condition"
              (click)="revertNotCondition(rowNode.node)">
              !
            </button>

            <button *ngIf="!locked"
              type="button"
              class="sdds-btn sdds-btn-secondary sdds-btn-sm removeItem"
              (click)="removeItem(rowNode.node)">
              <i class="pi pi-trash"></i>
            </button>
          </td>

          <sdds-modal size="sm" selector="#displaySearch{{ rowNode.node.key }}" >
            <h5 slot="sdds-modal-headline">{{ field.label }}</h5>
            <div slot="sdds-modal-body">
              <div *ngIf="field && field.options?.length > 0; else noItems">
                Select item(s) from the list
                <app-linked-hierarchy-list-search 
                  [field]="field" (itemSelected)="addItemToSelectedNode($event)">
                </app-linked-hierarchy-list-search >
              </div>
              <ng-template #noItems>
                FPCs not loaded yet. It might take a while.
              </ng-template>
            </div>
          </sdds-modal>
      </tr>
  </ng-template>
</p-treeTable>


<sdds-modal size="sm" selector="#displayRootSearch{{ field.name }}">
  <h5 slot="sdds-modal-headline">{{ field?.label }}</h5>
  <div slot="sdds-modal-body">
    <div *ngIf="field && field.options?.length > 0; else noItems">
      Select item(s) from the list
      <app-linked-hierarchy-list-search 
          [field]="field" (itemSelected)="addItemToRoot($event)">
      </app-linked-hierarchy-list-search>
    </div>
    <ng-template #noItems>
      FPCs not loaded yet. It might take a while.
    </ng-template>
  </div>
</sdds-modal>
