import { Injectable } from '@angular/core';
import { LinksService } from './api/links.service';
import { Subject } from 'rxjs';
import { formControlConstants } from '../constants/formControlConstants';
import { TreeNode } from 'primeng/api';
import { Fpc } from '../models/dto/fpc';
import { areOfSameFpcFamily } from './helpers/patternHelper';
import { CheckedOutItemService } from './checked-out-item.service';

@Injectable({
  providedIn: 'root'
})
export class LinkValidationService {

  constructor(public checkOutItemService: CheckedOutItemService, private linksService: LinksService) { }

  validateLink(linkIdToAdd: string, existingLinkIds: string[], fieldName: string): Promise<string> {
    let errorMessage: string;

    errorMessage = this.checkForDuplicateLinks(linkIdToAdd, existingLinkIds);
    if(errorMessage)
      return new Promise<string>(resolve => resolve(errorMessage));

    let itemUid = this.checkOutItemService.getCheckedOutItemUid();
    errorMessage = this.checkIfAddedSelf(linkIdToAdd, itemUid);
    if(errorMessage)
      return new Promise<string>(resolve => resolve(errorMessage));

    if(fieldName === formControlConstants.TSTestSuite) {
      let subject = new Subject<string>();
      this.validateForTestSuite(linkIdToAdd, itemUid, existingLinkIds).then(message => {
        subject.next(message);
        subject.complete();
      });
      return subject.toPromise();
    }

    return new Promise<string>(resolve => resolve(undefined));
  }

  private checkForDuplicateLinks(linkIdToAdd: string, existingLinkIds: string[]): string {
    let errorMessage: string = undefined;
    let existingLink = existingLinkIds.find(x => x && x === linkIdToAdd);
    if(existingLink) 
      errorMessage = 'Selected link is already added.';
    
    return errorMessage;
  }

  private checkIfAddedSelf(linkIdToAdd: string, itemUid: string):string {
    let errorMessage: string = undefined;
    if(itemUid && linkIdToAdd === itemUid) 
      errorMessage = 'Selected link is same as selected item.';
    
    return errorMessage;
  }

  private validateForTestSuite(linkIdToAdd: string, mainItemUid: string, existingLinkIds: string[]): Promise<string> {
    let subject = new Subject<string>();
    this.linksService.getLinksWhereItemIsLinked(linkIdToAdd, 170) //170 is TS link on TS
    .subscribe(links => {
      if(mainItemUid) {
        let itemAsLink = links.find(x => x.linkedItemKey === mainItemUid);
        if(itemAsLink) {
          subject.next('Selected link contains selected item as link.');
          subject.complete();
          return;
        }
      }
      
      for(let i = 0; i < links.length; i++) {
        let nestedLinkDuplicate = existingLinkIds.find(x => x && x === links[i].linkedItemKey)
        if(nestedLinkDuplicate) {
          subject.next('Selected link contains a link added to selected item.');
          subject.complete();
          return;
        }
      }

      subject.next(undefined);
      subject.complete();
    });
    
    return subject.toPromise();
  }

  validateFPC(fpcCodeToAdd: string, destinationParentNode: TreeNode<Fpc>) {
    let parentNode = destinationParentNode;

    while(parentNode) {
      if(parentNode.data.fpcCode == fpcCodeToAdd)
        return 'Same FPC already exists on a branch.';

      if(areOfSameFpcFamily(parentNode.data.fpcCode, fpcCodeToAdd))
        return 'FPC from same family already exists on a branch.';

      parentNode = parentNode.parent;
    }

    return undefined;
  }
}
