export namespace UserFunctionsActions {
    export class SetUserFunctions {
      static readonly type = '[UserFunctions] SetUserFunctions';
      constructor() {}
    }

    export class SetUserFunctionHierarchy {
      static readonly type = '[UserFunctions] SetUserFunctionHierarchy';
      constructor() {}
    }
  }
  