import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) {}
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request?.body?.operationName === 'configuratorHalfFpcs'){
      // download the FPC data in the background.
      return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        this.loaderService.hideLoader();
      }));
    }

    this.loaderService.showLoader();
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
          this.loaderService.hideLoader();
      }
      }, (error) => {
          this.loaderService.hideLoader();
      }));
  }
}
