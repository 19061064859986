import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApprovalDto } from 'src/app/models/dto/approvals/approvalDto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApprovalsApiService {
  private apiUrl = environment.approvalsApiUrl + 'TestItemApprovals';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private httpClient: HttpClient) {}

  get(uid: string, version: number): Observable<ApprovalDto> {
    return this.httpClient.get<ApprovalDto>(`${this.apiUrl}/${uid}/${version}`);
  }

  //todo map test levels
  post(items: ApprovalDto[]): Observable<ApprovalDto[]> {
    return this.httpClient.post<ApprovalDto[]>(
      `${this.apiUrl}`,
      items,
      this.httpOptions
    );
  }
}
