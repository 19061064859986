import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TpcActions } from './tpc.actions';
import { TpcStateModel } from './tpc.state.model';

@State<TpcStateModel>({
  name: 'tpc',
  defaults: {
    initialValue: undefined,
    currentValue: undefined,
  },
})
@Injectable()
export class TpcState {
  constructor() {}

  @Action(TpcActions.SetInitialValue)
  SetInitialValue(
    ctx: StateContext<TpcStateModel>,
    action: TpcActions.SetInitialValue
  ) {
    ctx.patchState({ initialValue: action.initialValue });
  }

  @Selector()
  static getInitialValue(state: TpcStateModel) {
    return state.initialValue;
  }

  @Action(TpcActions.SetCurrentValue)
  SetCurrentValue(
    ctx: StateContext<TpcStateModel>,
    action: TpcActions.SetCurrentValue
  ) {
    ctx.patchState({ currentValue: action.currentValue });
  }

  @Selector()
  static getCurrentValue(state: TpcStateModel) {
    return state.currentValue;
  }
}
