import { Comparison } from "src/app/models/dto/polarion/comparison";
import { ComparisonResult } from "src/app/models/dto/polarion/comparisonResult";
import { GraphQLComparisonOutput, GraphQLComparisonResultOutput } from "../models/output/graphql-comparison-output";
import { convertGraphQLSpecificationToType } from "./specification.mapper";

export function convertGraphQLComparisonToDto(input: GraphQLComparisonOutput): Comparison{
    return {
        itemOne: convertGraphQLSpecificationToType(input.ItemOne),
        itemTwo: convertGraphQLSpecificationToType(input.ItemTwo),
        result: input.Result.map(res => convertGraphQLComparisonResultToType(res))
    }
}

export function convertGraphQLComparisonResultToType(input: GraphQLComparisonResultOutput): ComparisonResult{
    return {
        requirementPolarionId: input.RequirementPolarionId,
        updateRequired: input.UpdateRequired,
        highestVersionInTA: input.HighestVersionInTA,
        versionInSpecA: input.VersionInSpecA,
        versionInSpecB: input.VersionInSpecB,
        info: input.Info
    }
}