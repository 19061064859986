import { gql } from "apollo-angular";

export const GET_COMPARISON_QUERY = gql`query polarionSpecificationComparison($IdOne: String, $IdTwo: String, $IrmaOne: Int, $IrmaTwo: Int) {
    polarionSpecificationComparison(IdOne: $IdOne, IdTwo: $IdTwo, IrmaOne: $IrmaOne, IrmaTwo: $IrmaTwo) {
        ItemOne {
            PolarionId
            IrmaVersion
          }
          ItemTwo {
            PolarionId
            IrmaVersion
          }
          Result {
            HighestVersionInTA
            Info
            RequirementPolarionId
            UpdateRequired
            VersionInSpecA
            VersionInSpecB
          }
    }
  }
  `