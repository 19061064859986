import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { formField } from 'src/app/models/formField';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { FormFieldOption } from 'src/app/models/formFieldOption';
import { ColumnFilterFormElement } from 'primeng/table';

@Component({
  selector: 'app-linked-item-list-search',
  templateUrl: './linked-item-list-search.component.html',
  styleUrls: ['./linked-item-list-search.component.scss'],
})
export class LinkedItemListSearchComponent implements OnInit {
  @Input() field!: formField;
  @Input() formGroup: FormGroup;
  @Input() locked: boolean;

  @Output() itemSelected = new EventEmitter();
  selectedItem: FormFieldOption;
  isGitLabField : boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.isGitLabField = this.field.name === formControlConstants.TCGitLabScript;

    // filter instantly without pressing Enter
    ColumnFilterFormElement.prototype.onModelChange = function (value) {
      this.filterConstraint.value = value;
      if (this.type || value === '') {
        this.dt._filter();
      }
    }
  }


  onRowSelect(event) {
    if(this.field.name === formControlConstants.TCGitLabScript || this.field.name === formControlConstants.TATestCase || this.field.name === formControlConstants.TSpecTestAnalysis)
      this.itemSelected.emit(event.data);
    else
      this.itemSelected.emit(event.data.data);
  }
}
