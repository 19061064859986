import { gql } from "apollo-angular";

export const GETALL_USER_FUNCTIONS_QUERY = gql`query configuratorUserFunctions {
    configuratorUserFunctions {
        Id
        Name
    }
  }
  `


export const GETALL_USER_FUNCTIONS_HIERARCHY_QUERY = gql`query configuratorUserFunctions {
    configuratorUserFunctions {
        Id
        Name
        UseCases {
            Id
            Name
            Scenarios {
                Id
                Name
            }
        }
    }
  }
  `
