<div class="linked-item-search-table">
    <p-treeTable #tt 
      [value]="itemsTree"
      [scrollable]="true"
      scrollHeight="350px"
      filterMode="lenient" 
      selectionMode="single"
      (onNodeSelect)="onRowSelect($event)" 
      [rowsPerPageOptions]="[10, 20, 50]" 
      [paginator]="true"
      [rows]="20"
      currentPageReportTemplate="{first} to {last} of {totalRecords}"
      [showCurrentPageReport]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th >{{ isUf ? 'Id' : 'FPC'}}</th>
          <th>Name</th>
        </tr>
        <tr>
            <th>
              <input *ngIf="!isUf" type="text" pInputText placeholder="Search" 
                (input)="tt.filter($any($event.target).value, 'fpcCode', 'contains')" />
                <input *ngIf="isUf" type="text" pInputText placeholder="Search" 
                (input)="tt.filter($any($event.target).value, 'id', 'contains')" />
            </th>
            <th>
                <input type="text" pInputText placeholder="Search" 
                  (input)="tt.filter($any($event.target).value, 'name', 'contains')" />
              </th>
          </tr>
      </ng-template>

      
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
          <td *ngIf="isUf"> 
            <p-treeTableToggler [rowNode]="rowNode"/> 
            {{ rowNode.node.label }} {{ rowData.id }}:{{ rowData.name }}
          </td>
          <td *ngIf="!isUf"> 
            <p-treeTableToggler [rowNode]="rowNode"/> 
            {{ rowData.fpcCode }}: {{ rowData.name }}
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </div>

