import { gql } from "apollo-angular";

export const GETALL_ORGANIZATIONAL_GROUPS_QUERY = gql`query configuratorAllOrganizationalGroups {
    configuratorAllOrganizationalGroups {
        Uid
        Name
        Description
    }
  }
  `

export const GET_ORGANIZATIONAL_GROUP_BY_USERNAME_QUERY = gql`query configuratorOrganizationalGroupByUsername($Username: String!) {
    configuratorOrganizationalGroupByUsername(Username: $Username) {
        Uid
        Name
        Description
    }
  }
  `