<p-table [value]="steps "dataKey="no" [reorderableColumns]="true" (onRowReorder)="onRowReorder($event)" editMode="row" #stepTable reorderableRows>
    <ng-template pTemplate="header">
        <tr>
            <th style="width:4%"></th>
            <th style="width:6%">Order</th>
            <th style="width:20%">Pre Condition</th>
            <th style="width:20%">Stimuli</th>
            <th style="width:20%">Expected Response</th>
            <th style="width:20%">Post Condition</th>
            <th style="width:10%">
                <button
                  class="sdds-btn sdds-btn-secondary sdds-btn-sm"
                  type="button"
                  pTooltip="Add new step"
                  tooltipPosition="bottom"
                  [disabled]="locked"
                  (click)="addNewStep()"
                >
                  <i class="pi pi-plus" style="font-size: initial"></i>
                </button>
              </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-step let-editing="editing" let-index="rowIndex">
        <tr [pReorderableRow]="index"  [pEditableRow]="step">
            <td pReorderableRowHandle [ngClass]="{ 'disabled': locked}">
                <span class="pi pi-bars"  pTooltip="Drag to reorder" style="font-size: initial; margin-left: 3rem;"></span>
            </td>
            <td>
                <span>{{step?.no}}</span>
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input *ngIf="!locked" pInputText type="text" [(ngModel)]="step.preCondition.value">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{step.preCondition?.value}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input *ngIf="!locked" pInputText type="text" [(ngModel)]="step.stimuli.value">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{step.stimuli?.value}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input *ngIf="!locked" pInputText type="text" [(ngModel)]="step.expectedResponse.value">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{step.expectedResponse?.value}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input *ngIf="!locked" pInputText type="text" [(ngModel)]="step.postCondition.value">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{step.postCondition?.value}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td>
                <button *ngIf="!editing" pButton pRipple type="button" [disabled]="locked" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(step)" class="p-button-rounded p-button-text"></button>
                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(step)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(step, index)" class="p-button-rounded p-button-text p-button-danger"></button>
                
                <button *ngIf="!editing" pButton pRipple type='button' [disabled]="locked" id="delete{{ step.no }}" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger">
                </button> 
            </td>

            <sdds-modal size="sm" selector="#delete{{ step.no }}">
                <h5 slot="sdds-modal-headline">Delete confirmation</h5>
                <p slot="sdds-modal-body">
                  Are you sure you want to delete {{ step.no }}. step?
                </p>
                <button type='button' slot="sdds-modal-actions" data-dismiss-modal (click)="deleteStep(step.no)"
                  class="sdds-btn sdds-btn-danger sdds-btn-md">
                  Delete
                </button>
                <button type='button' slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md">
                  Cancel
                </button>
              </sdds-modal>
        </tr>
    </ng-template>
</p-table>
