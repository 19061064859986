import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formField } from 'src/app/models/formField';
import { FieldTypeEnum } from 'src/app/models/enums/fieldTypeEnum';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { ItemType } from 'src/app/models/enums/itemType';

export function enableAllFields(fields: formField[],  form: FormGroup) {
  fields.forEach(field => {
    if(field.name != formControlConstants.TotalRiskLevel && field.name != formControlConstants.ItemNo){
      let control = form.controls[field.name];
      if(control && control instanceof FormControl)
        control.enable();
    }
  })
}

export function disableAllFields(fields: formField[],  form: FormGroup) {
  fields.forEach(field => {
    let control = form.get(field.name);
    if(control && control instanceof FormControl)
      control.disable();
  })
}


export function createFormGroup(fields: formField[], itemType: ItemType){
  let form = new FormGroup({});

  fields.forEach(field => {
    form.addControl(field.name, new FormControl(field.defaultValue));

    if(field.isRequired)
      form.controls[field.name].validator = Validators.required;

    setFieldDefaultValueToForm(field, form);
  });

  if(itemType == ItemType.TestCase)
    form.addControl(formControlConstants.Steps, new FormControl([]));

  if(itemType !== ItemType.TestCoverage && itemType !== ItemType.TestSpecification)
    form.addControl(formControlConstants.Description, new FormControl());

  return form;
}

function setFieldDefaultValueToForm(field: formField, form: FormGroup) {
  if(field.fieldType == FieldTypeEnum.toggle) {
    try {
      form.controls[field.name].setValue(Boolean(JSON.parse(field.defaultValue)));     
    }
    catch {
      form.controls[field.name].setValue(false);     
    }
  } else {
    if(!field.defaultValue)
    return;

    if(field.name == formControlConstants.InputTypeName)
      form.controls[field.name].setValue({key: field.defaultValue, value: field.defaultValue});     
    else
      form.controls[field.name].setValue(field.defaultValue);   
  }
}
