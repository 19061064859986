//obsolete
export enum DerivedFrom {
  AnalysisOfBoundaryValues = 'Analysis of boundary values',
  AnalysisOfRequirements = 'Analysis of requirements',
  ErrorGuessing = 'Error guessing',
  GenerationAndAnalysisOfEquivalenceClasses = 'Generation and analysis of equivalence classes',
  FieldExperience = 'Field experience',
  AnalysisOfFunctionalDependencies = 'Analysis of functional dependencies',
  AnalysisOfOperationalUseCases = 'Analysis of operational use cases',
  AnalysisOfExternalAndInternalInterfaces = 'Analysis of external and internal interfaces',
  AnalysisOfPhysicalPropertiesOfSensorsOrActuators = 'Analysis of physical properties of sensors/actuators',
  AnalysisOfCommonLimitConditionsSequencesAndSourcesOfDependentFailures = 'Analysis of common limit conditions sequences and sources of dependent failures',
  AnalysisOfDegradationModes = 'Analysis of degradation modes',
  Legacy = 'Legacy',
}
