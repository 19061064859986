export const TpcConstants = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  IS_GREATER_THAN: '>',
  IS_LOWER_THAN: '<',
  IS_LOWER_OR_EQUAL: '<=',
  IS_GREATER_OR_EQUAL: '>=',
  TRUE_STRING: 'true',
  FALSE_STRING: 'false',
  OPEN_BRACKET: '(',
  CLOSED_BRACKET: ')',
  AND: 'and',
  OR: 'or',
};

export class Regexes {
  public static LogicalOperations: RegExp = new RegExp(
    `(${TpcConstants.NOT_EQUAL}|${TpcConstants.IS_GREATER_OR_EQUAL}|${TpcConstants.IS_LOWER_OR_EQUAL}|${TpcConstants.EQUAL}|${TpcConstants.IS_LOWER_THAN}|${TpcConstants.IS_GREATER_THAN})`
  );
  public static ArrayOfNumbers: RegExp = new RegExp('[0-9]+');
  public static BetweenBrackets: RegExp = new RegExp('(?<=().*?(?=))');
}

export const StringConstants = {
  PREFIX: 'TPC',
};
