<div class="top-container">

  <!-- <h4>Rules</h4>
  <p-table [value]="rules" [scrollable]="true" dataKey="value">
    <ng-template pTemplate="header">
      <tr>
        <th>Name</th>
        <th>Version</th>
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.label }}</td>
        <td>{{ item.data.version }}</td>
        <td>
          <button
            id="details{{ item.data.uid }}"
            class="sdds-btn sdds-btn-secondary sdds-btn-sm"
          >
            <i class="pi pi-eye" style="font-size: initial"></i>
          </button>
        </td>

        <sdds-modal size="sm" selector="#details{{ item.data.uid }}">
          <app-item slot="sdds-modal-body" [itemUid]="item.data.uid">
          </app-item>
        </sdds-modal>
      </tr>
    </ng-template>
  </p-table> -->



  <h4>Specification details</h4>
  <p *ngIf="!selectedSpecification">Select a specification from table to see details</p>
  <h4 *ngIf="selectedSpecification">{{selectedSpecification.specification.polarionId}}: {{selectedSpecification.specification.title}}</h4>

  <div class='requirementsTable' style="padding: 50px 50px 10px;" *ngIf="selectedSpecification">

    <app-evaluation-statistics [specificationEvaluation]="selectedSpecification"></app-evaluation-statistics>

    <h5>Requirements linked to specification</h5>
    <p-table #datatable
             [value]="selectedSpecification.requirements"
             dataKey="requirement.id"
             scrollDirection="horizontal"
             responsiveLayout="scroll"
             *ngIf="selectedSpecification"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem"></th>
          <th style="text-align: start">Requirement title</th>
          <th style="width: 10%">IRMA version</th>
          <th style="width: 10%">Asil</th>
          <th style="width: 10%">Test Analysis connected</th>
          <th style="width: 10%">
            Evaluation status
            <i class="pi pi-question-circle"
               style="font-size: initial"
               [pTooltip]="EvaluationRuleMessage"
               tooltipPosition="bottom"></i>
          </th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>
            <p-dropdown [options]="filterOptions" (onChange)="datatable.filter($event.value, 'testAnalysis.length', 'customTableFilter')"
                        styleClass="p-column-filter" placeholder="Any" [showClear]="true">
              <ng-template let-option pTemplate="item">
                <i [class]="option.value === 1
                            ? 'evaluationPassed pi pi-check-circle'
                            : 'evaluationFailed pi pi-exclamation-circle' "
                  style="font-size: initial"></i>
                <span>
                  {{option.label}}
                </span>
              </ng-template>
            </p-dropdown>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-requirementEvaluation
        let-expanded="expanded"
        >
        <tr>
          <td>
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="requirementEvaluation"
              class="p-button-text p-button-rounded p-button-plain"
              >
              <i
                 [class]="expanded
                            ? 'pi pi-chevron-down'
                            : 'pi pi-chevron-right'
              "
                 style="font-size: initial"></i>
            </button>
          </td>
          <td style="text-align: start">{{ requirementEvaluation.requirement.title }}</td>
          <td>{{ requirementEvaluation.requirement.irmaVersion }}</td>
          <td>{{ requirementEvaluation.requirement.asil }}</td>
          <td>
            <i [class]="requirementEvaluation.testAnalysis.length > 0
                ? 'evaluationPassed pi pi-check-circle'
                : 'evaluationFailed pi pi-exclamation-circle' "
               [pTooltip]="requirementEvaluation.testAnalysis.length > 0
                ? 'Test Analysis connected'
                : 'Test Analysis not connected'"
               style="font-size: initial"></i>
          </td>
          <td>
            <i [class]="requirementEvaluation.requirement.evaluationPassed
                ? 'evaluationPassed pi pi-check-circle'
                : 'evaluationFailed pi pi-exclamation-circle' "
               [pTooltip]="requirementEvaluation.requirement.evaluationPassed
                ? 'Evaluation passed'
                : 'Evaluation failed'"
               style="font-size: initial"></i>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-requirementEvaluation>
        <tr>
          <td colspan="4">
            <div style="margin: 4rem 15rem;">
              <p-table [value]="requirementEvaluation.testAnalysis"
                       dataKey="testAnalyze.uid"
                       scrollDirection="horizontal"
                       responsiveLayout="scroll">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Test Analyze</th>
                    <th>Version</th>
                    <th>Approval status</th>
                    <th>Test level</th>
                    <!-- <th>Actions</th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-testAnalyze>
                  <tr>
                    <td>{{ testAnalyze.name }}</td>
                    <td>{{ testAnalyze.version }}</td>
                    <td>
                      <a class="sdds-link"
                         href="http://review.tms.test.scania.com/#/approvals/item/{{
                        testAnalyze.uid
                      }}/{{ testAnalyze.version }}"
                         target="_blank">
                        {{
                        testAnalyze.approvalStatus === 1
                          ? "Approved"
                          : "Not approved"
                        }}
                      </a>
                    </td>
                    <td>{{ testAnalyze.approvalLevel }}</td>
                    <!-- <td>
                      <button
                        class="sdds-btn sdds-btn-secondary sdds-btn-sm"
                      >
                        <i
                          class="pi pi-eye"
                          style="font-size: initial"
                        ></i>
                      </button>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="6">
                      There are no Test Analysis for this requirement.
                      <!-- <button
                        class="sdds-btn sdds-btn-secondary sdds-btn-sm"
                        style="margin-left: 5rem"
                        (click)="
                          createNewTestAnalyze(
                            requirementEvaluation.requirement,
                            selectedSpecification.specification
                          )
                        "
                      >
                        Create new TestAnalyze
                      </button> -->
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">
            There are no requirements for this specification.
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
</div>

