import { FormGroup } from '@angular/forms';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { TestAnalysisAlternativeDto } from 'src/app/models/dto/TestAnalysisAlternativeDto';
import { ExcludedFromTestDto } from 'src/app/models/dto/excludedFromTestDto';
import { TestAnalysisDto } from 'src/app/models/dto/testAnalysisDto';
import { v4 as uuidv4 } from 'uuid';

export class TestAnalysisFactory {

  public static create(originalItem: TestAnalysisDto, form: FormGroup): TestAnalysisDto {
    let result: TestAnalysisDto = {} as TestAnalysisDto;
    result.uid = originalItem?.uid ?? uuidv4().toString();
    result.itemNo = originalItem?.itemNo;

    result.name = form.value[formControlConstants.Name] ?? '';
    result.label = form.value[formControlConstants.Label] ?? '';
    result.testedWithExploratoryTestingOnly = form.value[formControlConstants.TestedWithExploratoryTestingOnly];
    result.restrictedViewAccess = form.value[formControlConstants.RestrictedViewAccess];
    result.impactIfFailureAppears = form.value[formControlConstants.ImpactIfFailureAppears].value ?? '';
    result.probabilityOfFailure = form.value[formControlConstants.ProbabilityOfFailure].value ?? '';
    result.totalRiskLevel = form.controls[formControlConstants.TotalRiskLevel]?.value ?? ''; //form.value won't work since TotalRiskLevel is disabled
    result.rationaleForRiskAssessment = form.value[formControlConstants.RationaleForRiskAssessment] ?? '';
    result.requirements = form.value[formControlConstants.TARequirement] ?? [];
    result.description = form.value[formControlConstants.Description] ?? '';

    result.alternative = {} as TestAnalysisAlternativeDto;
    result.alternative.uid = originalItem?.alternative?.uid ?? uuidv4().toString();

    result.alternative.ownerIds = form.value[formControlConstants.TAOwner]?.map(x => x.key) ?? [];
    result.alternative.testedByGroupIds = form.value[formControlConstants.TATestedByOrganizationalGroup]?.map(x => x.key) ?? [];
    let testLevelId = form.value[formControlConstants.TATestLevels]?.key?.toString();
    result.alternative.testLevelIds = testLevelId ? [testLevelId] : [];
    result.alternative.testCases = form.value[formControlConstants.TATestCase] ?? [];
    result.alternative.sopStart = form.value[formControlConstants.SOPStart]?.value ?? '';//Sop is key:value value, but we only want to use the display name(value)
    result.alternative.sopEnd = form.value[formControlConstants.SOPEnd]?.value ?? '';
    result.alternative.specifications = form.value[formControlConstants.TASpecification] ?? [];

    if(form.value[formControlConstants.TADelegatedToTestLevels]?.length > 0 || originalItem?.alternative?.excludedFromTest?.uid){
      result.alternative.excludedFromTest = {} as ExcludedFromTestDto;
      result.alternative.excludedFromTest.uid = originalItem?.alternative?.excludedFromTest?.uid ?? uuidv4().toString();
      result.alternative.excludedFromTest.delegatedToTestLevelIds = form.value[formControlConstants.TADelegatedToTestLevels]?.map(x => x.key) ?? [];
    }

    //empty properties
    result.comment = '';
    result.lockedBy = '';
    result.createdBy = '';
    result.modifiedBy = '';
    result.approvalStatus = '';
    result.approvedForTestLevel = '';

    return result;
  }
}
