import { TestAnalysisDto } from "src/app/models/dto/testAnalysisDto";
import { TestAnalysisAlternativeDto } from "src/app/models/dto/TestAnalysisAlternativeDto"
import { RequirementDto } from "src/app/models/dto/polarion/requirementDto";
import { convertBaseTestItemDtoToGraphqlInput } from "./common.mappers";
import { SpecificationDto } from "src/app/models/dto/polarion/specificationDto";
import { ExcludedFromTestDto } from "src/app/models/dto/excludedFromTestDto";
import { ExcludedFromTestInput, RequirementInput, SpecificationInput, TestAnalysisAlternativeInput, TestAnalysisInput } from "../models/input/graphql-test-analysis-input";

export function convertTestAnalysisDtoToGraphqlBaseTestItem(input: TestAnalysisDto): TestAnalysisInput{
    return {
      Uid: input.uid,
      ItemNo: input.itemNo,
      Name: input.name,
      Version: input.version,
      ModifiedBy: input.modifiedBy,
      ModificationTime: null,
      Comment: input.comment,
      LockedBy: input.lockedBy,
      LockedTime: null,
      CreatedBy: input.createdBy,
      CreationTime: null,
      ApprovalStatus: input.approvalStatus,
      ApprovedForTestLevel: input.approvedForTestLevel,
      Label: input.label,
      RestrictedViewAccess: input.restrictedViewAccess,
      TestedWithExploratoryTestingOnly: input.testedWithExploratoryTestingOnly,
      ImpactIfFailureAppears: input.impactIfFailureAppears,
      ProbabilityOfFailure: input.probabilityOfFailure,
      TotalRiskLevel: input.totalRiskLevel,
      RationaleForRiskAssessment: input.rationaleForRiskAssessment,
      Description: input.description,
      Requirements: input.requirements.map(item => {
        return convertRequirementToGraphQlInput(item);
      }),
      Alternative: convertTestAnalysisAlternativeDtoToGraphqlInput(input.alternative)
     }
}

export function convertTestAnalysisAlternativeDtoToGraphqlInput(input: TestAnalysisAlternativeDto): TestAnalysisAlternativeInput {
    return {
        Uid: input.uid,
        OwnerIds: input.ownerIds,
        TestedByGroupIds: input.testedByGroupIds,
        TestLevelIds: input.testLevelIds,
        TestCases: input.testCases.map(item => {
            return convertBaseTestItemDtoToGraphqlInput(item)
        }),
        SopStart: input.sopStart,
        SopEnd: input.sopEnd,
        Specifications: input.specifications.map(item => {
            return convertSpecificationToGraphQlInput(item);
          }),
        ExcludedFromTest: input.excludedFromTest ? convertExcludedFromTestToGraphQlInput(input.excludedFromTest) : undefined
    }
}

export function convertExcludedFromTestToGraphQlInput(input: ExcludedFromTestDto): ExcludedFromTestInput{
    return {
        Uid: input.uid,
        DelegatedToTestLevelIds: input.delegatedToTestLevelIds.map(id =>{
            return id;
        })
    }
}


export function convertRequirementToGraphQlInput(input: RequirementDto): RequirementInput{
    if(input == null)
        return null;
    return {
        PolarionId: input.polarionId,
        Title: input.title,
        IrmaVersion: input.irmaVersion,
        Revision: input.revision,
        Asil: input.asil,
        Status: input.status,
        Legislation: input.legislation
    }
}

export function convertSpecificationToGraphQlInput(input: SpecificationDto): SpecificationInput{
    return {
        PolarionId: input.polarionId,
        Title: input.title,
        IrmaVersion: input.irmaVersion,
        Revision: input.revision,
        Asil: input.asil,
        Status: input.status,
        MaturityStatus: input.maturityStatus,
        LinkedRequirement: convertRequirementToGraphQlInput(input.linkedRequirement)
    }
}