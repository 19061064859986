import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap, throwError } from 'rxjs';
import { convertGraphQLComparisonToDto } from 'src/app/graphQL/mappers/comparison.mapper';
import { GraphQLComparisonOutput } from 'src/app/graphQL/models/output/graphql-comparison-output';
import { GET_COMPARISON_QUERY } from 'src/app/graphQL/queries/comparison-queries';
import { Comparison } from 'src/app/models/dto/polarion/comparison';
import { environment } from 'src/environments/environment';
import { ApolloClientService } from './apollo-client.service';

@Injectable({
  providedIn: 'root'
})
export class ComparisonService {
  constructor(private apolloClientService: ApolloClientService) {}

  compareSpecifications(
    IdOne: string,
    IdTwo: string,
    IrmaOne: string,
    IrmaTwo: string
  ): Observable<Comparison> {
    if(!environment.IsHero){
      return this.apolloClientService.apolloClient$.pipe(
        switchMap(client => 
          client.query<{polarionSpecificationComparison : GraphQLComparisonOutput}>({
            query: GET_COMPARISON_QUERY,
            variables: {IdOne: IdOne, IdTwo: IdTwo, IrmaOne: IrmaOne, IrmaTwo: IrmaTwo}
          })
        ),
        switchMap(result => {
          const comparison = result.data.polarionSpecificationComparison;
          if (!comparison) {
            return throwError(() => new Error('One or more of the specification/version combinations provided does not exist'));
          }
          return of(convertGraphQLComparisonToDto(comparison)); 
        })
      )
    }
    else {
      return null
    }
  }
}