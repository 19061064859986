<nav class="sdds-nav">
  <div class="sdds-nav__left">
    <div class="sdds-nav__app-name element" [routerLink]="testAnalysisRoute">
      @if(reviewEnabled$ | async) {
        Fenix Test Configurator with Review
     } @else {
        Fenix Test Configurator
     }     
    </div>
  </div>

  <div class="sdds-nav__right">
    <ul class="sdds-nav__toolbar-menu">
      <li class="sdds-nav__item">
        <a class="sdds-nav__item-core" href="#">
          <p class="sdds-nav__item-core-text">{{ username }}</p>
        </a>
      </li>
    </ul>

    <a class="sdds-nav__item sdds-nav__app-logo" href="#"></a>
  </div>
</nav>

<nav class="sdds-nav">
  <div class="sdds-nav__left">
    <ul class="sdds-nav__inline-menu">
      <li class="sdds-nav__item" [routerLink]="testAnalysisRoute" routerLinkActive="sdds-nav__item sdds-nav__item--active">
        <p class="sdds-nav__item-core sdds-nav__item-core-text">Test Analyses</p>
      </li>
      <li class="sdds-nav__item" [routerLink]="testCasesRoute" routerLinkActive="sdds-nav__item sdds-nav__item--active">
        <p class="sdds-nav__item-core sdds-nav__item-core-text">Test Cases</p>
      </li>
      <li class="sdds-nav__item" [routerLink]="testSuitesRoute" routerLinkActive="sdds-nav__item sdds-nav__item--active">
      <p class="sdds-nav__item-core sdds-nav__item-core-text">Test Suites</p>
      </li>
      <li class="sdds-nav__item" [routerLink]="testSpecificationsRoute" routerLinkActive="sdds-nav__item sdds-nav__item--active">
        <p class="sdds-nav__item-core sdds-nav__item-core-text">Test Specifications</p>
      </li>
      <li class="sdds-nav__item" [routerLink]="testCoverageRoute" routerLinkActive="sdds-nav__item sdds-nav__item--active" *ngIf="!isHero">
        <p class="sdds-nav__item-core sdds-nav__item-core-text">Test Coverages</p>
      </li>
      <li class="sdds-nav__item" [routerLink]="rmsSpecificationsComparisonRoute" routerLinkActive="sdds-nav__item sdds-nav__item--active" *ngIf="!isHero">
        <p class="sdds-nav__item-core sdds-nav__item-core-text">RMS Specification Comparison</p>
      </li>
      <li class="sdds-nav__item" [routerLink]="reviewRoute" routerLinkActive="sdds-nav__item sdds-nav__item--active" *ngIf="!isHero && reviewEnabled$ | async">
        <p class="sdds-nav__item-core sdds-nav__item-core-text">Review</p>
      </li>
    </ul>
  </div>
</nav>


