import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SpecificationDto } from 'src/app/models/dto/polarion/specificationDto';
import { ApolloClientService } from './apollo-client.service';
import { GET_SPECIFICATION_FOR_REQUIREMENT_QUERY, GET_SPECIFICATION_QUERY } from 'src/app/graphQL/queries/specification-queries';
import { GraphQLSpecificationOutput } from 'src/app/graphQL/models/output/graphql-specification-output';
import { convertGraphQLSpecificationToDto } from 'src/app/graphQL/mappers/specification.mapper';

@Injectable({
  providedIn: 'root',
})
export class SpecificationApiService {

  constructor(private apolloClientService: ApolloClientService) {}

  getByIdAndIrmaVersion(specificationId: string, irmaVersion: number): Observable<SpecificationDto>{
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{polarionSpecificationByIdAndIrma : GraphQLSpecificationOutput}>({
          query: GET_SPECIFICATION_QUERY,
          variables: {SpecificationId: specificationId, IrmaVersion:irmaVersion}
        })
      ),map(result =>{
        const specification = result.data.polarionSpecificationByIdAndIrma;
        if(!specification)
          return undefined; 

        return convertGraphQLSpecificationToDto(specification)
      })
    )
  }

  getSpecificationsForRequirement(requirementId: string, irmaVersion: number): Observable<SpecificationDto[]>{

      return this.apolloClientService.apolloClient$.pipe(
        switchMap(client => 
          client.query<{polarionSpecificationsForRequirement: GraphQLSpecificationOutput[]}>({
            query: GET_SPECIFICATION_FOR_REQUIREMENT_QUERY,
            variables: {RequirementId: requirementId, IrmaVersion: irmaVersion}
          })),
          map(result => {
            const specifications = result.data.polarionSpecificationsForRequirement
            if(!specifications)
              return undefined;
            
            return specifications.map(spec =>{
              return convertGraphQLSpecificationToDto(spec)
            })
        })
      )    
  }
}
