<p-toast></p-toast>
<div class="title">
  <h2 *ngIf="testItem">{{ testItem.itemNo }}: {{ testItem.name }}</h2>
  <i
    class="pi lockIcon"
    *ngIf="testItem && testItem.lockedBy !== ''"
    pTooltip="Locked by {{ testItem.lockedBy }} 
              Since {{ testItem.lockedTime | date : 'dd.MM.yyyy HH:mm' }}"
    style="font-size: initial"
    [class]="
      testItem?.lockedBy
        ? 'checkedOutByYou pi-unlock'
        : 'checkedOutBySomeoneElse pi-lock'
    "
  ></i>
</div>

<div
  *ngIf="form"
  class="test-case-page"
  [ngClass]="{ 'hide-form': this.hideForm }"
>
  <div class="test-case-form">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div>
        <div *ngIf="testItem && approval && reviewVisable$ | async">
          <app-review-header
            [testItem]="testItem"
            [approval]="approval"
            (enableComments)="handleEnableReviewComments($event)"
          ></app-review-header>
        </div>
        <p-panel
          toggler="header"
          styleClass="panelHeader"
          [toggleable]="true"
          class="grid-column-panel"
        >
          <ng-template pTemplate="header">
            <p class="boldText">General Information</p>
          </ng-template>
          <div class="panelContentWrapper">
            <div class="panelContent gridDisplay">
              <div
                *ngFor="let field of informationFields"
                class="field-section"
              >
                <app-dynamic-form-field
                  [field]="field"
                  [form]="form"
                ></app-dynamic-form-field>
              </div>
            </div>
            <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
              <app-review-comments
                [itemUid]="testItem.uid"
                [itemVersion]="testItem.version"
                [commentGroup]="ReviewCommentGroup.TC_General"
                [enableComments]="enableReviewComments"
                [approval]="approval"
              ></app-review-comments>
            </div>
          </div>
        </p-panel>
      </div>

      <div>
        <p-panel
          toggler="header"
          styleClass="panelHeader"
          [toggleable]="true"
          class="grid-column-panel"
        >
          <ng-template pTemplate="header">
            <p class="boldText">Options</p>
          </ng-template>
          <div class="panelContentWrapper">
            <div class="panelContent gridDisplay">
              <div *ngFor="let field of optionFields" class="field-section">
                <app-dynamic-form-field
                  [field]="field"
                  [form]="form"
                ></app-dynamic-form-field>
              </div>
            </div>
            <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
              <app-review-comments
                [itemUid]="testItem.uid"
                [itemVersion]="testItem.version"
                [commentGroup]="ReviewCommentGroup.TC_Options"
                [enableComments]="enableReviewComments"
                [approval]="approval"
              ></app-review-comments>
            </div>
          </div>
        </p-panel>
      </div>

      <div>
        <p-panel
          toggler="header"
          [toggleable]="true"
          *ngIf="fpcLinkField && (fpcLinksLoadedToForm || addPageActive)"
          class="item-links link-panel"
        >
          <ng-template pTemplate="header">
            <p class="boldText">FPC conditions</p>
          </ng-template>
          <div class="panelContentWrapper">
            <app-fpc
              [field]="fpcLinkField"
              [form]="form"
              [items]="fpcLinkField.options"
              [locked]="
                !addPageActive && testItem?.lockedBy !== loggedInUsername
              "
              [fpcsLoaded]="fpcDataLoaded"
            >
            </app-fpc>
            <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
              <app-review-comments
                [itemUid]="testItem.uid"
                [itemVersion]="testItem.version"
                [commentGroup]="ReviewCommentGroup.TC_Fpc"
                [enableComments]="enableReviewComments"
                [approval]="approval"
              ></app-review-comments>
            </div>
          </div>
        </p-panel>
      </div>

      <div>
        <p-panel
          toggler="header"
          [toggleable]="true"
          *ngIf="
            userFunctionLinkField &&
            !isHero &&
            (userFunctionLinksLoadedToForm || addPageActive)
          "
          class="item-links link-panel"
        >
          <ng-template pTemplate="header">
            <p class="boldText">User Functions</p>
          </ng-template>
          <div class="panelContentWrapper">
            <app-user-functions
              [field]="userFunctionLinkField"
              [form]="form"
              [items]="userFunctionLinkField.options"
              [locked]="
                !addPageActive && testItem?.lockedBy !== loggedInUsername
              "
            >
            </app-user-functions>
            <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
              <app-review-comments
                [itemUid]="testItem.uid"
                [itemVersion]="testItem.version"
                [commentGroup]="ReviewCommentGroup.TC_UserFunction"
                [enableComments]="enableReviewComments"
                [approval]="approval"
              ></app-review-comments>
            </div>
          </div>
        </p-panel>
      </div>

      <div>
        <p-panel
          toggler="header"
          styleClass="panelHeader"
          [toggleable]="true"
          [collapsed]="false"
          *ngIf="form"
        >
          <ng-template pTemplate="header">
            <p class="boldText">Description</p>
          </ng-template>
          <div class="sdds-on-white-bg m-right">
            <app-text-editor
              [description]="testItem?.description"
              [form]="form"
              [readOnly]="
                !addPageActive && testItem?.lockedBy !== loggedInUsername
              "
            ></app-text-editor>
          </div>
        </p-panel>
      </div>

      <div>
        <p-panel
          *ngIf="stepsLoadedToForm"
          toggler="header"
          styleClass="panelHeader"
          [toggleable]="true"
          [tooltipDisabled]="
            (addPageActive && stepsApplicable) ||
            (!addPageActive &&
              (testItem?.lockedBy !== loggedInUsername || stepsApplicable))
          "
          pTooltip="Steps are not applicable for selected InputTypeName"
          tooltipPosition="top"
        >
          <ng-template pTemplate="header">
            <p class="boldText">Steps</p>
          </ng-template>
          <div class="panelContentWrapper">
            <div class="panelContent">
              <app-steps
                [form]="form"
                [locked]="
                  (addPageActive && !stepsApplicable) ||
                  (!addPageActive && testItem?.lockedBy !== loggedInUsername) ||
                  !stepsApplicable
                "
              >
              </app-steps>
            </div>
            <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
              <app-review-comments
                [itemUid]="testItem.uid"
                [itemVersion]="testItem.version"
                [commentGroup]="ReviewCommentGroup.TC_Steps"
                [enableComments]="enableReviewComments"
                [approval]="approval"
              ></app-review-comments>
            </div>
          </div>
        </p-panel>
      </div>

      <div *ngIf="!isHero">
        <p-panel toggler="header" styleClass="panelHeader" [toggleable]="true">
          <ng-template pTemplate="header">
            <p class="boldText">Scripts</p>
          </ng-template>
          <div class="panelContentWrapper">
            <div class="panelContent">
              <div
                *ngIf="
                  gitLabScriptLinkField &&
                  (gitLabScriptLinksLoadedToForm || addPageActive)
                "
              >
                <app-linked-item-list
                  [field]="gitLabScriptLinkField"
                  [form]="form"
                  [items]="gitLabScriptLinkField.options"
                  [locked]="
                    !addPageActive && testItem?.lockedBy !== loggedInUsername
                  "
                ></app-linked-item-list>
              </div>
            </div>
            <div class="panelReview" *ngIf="testItem && reviewVisable$ | async">
              <app-review-comments
                [itemUid]="testItem.uid"
                [itemVersion]="testItem.version"
                [commentGroup]="ReviewCommentGroup.TC_Scripts"
                [enableComments]="enableReviewComments"
                [approval]="approval"
              ></app-review-comments>
            </div>
          </div>
        </p-panel>
      </div>

      <test-item-action
        [testItem]="testItem"
        [changes]="changes"
        (checkOutTestItem)="checkOut()"
        (unlockTestItem)="unlockItem()"
        class="footer"
        [actionButton]="actionButtons"
        [toolTip]="checkOutBtnToolTip"
        [itemType]="itemType"
      ></test-item-action>
    </form>
  </div>

  <div class="test-case-properties">
    <app-item-registration-info [item]="testItem"></app-item-registration-info>
  </div>
</div>
