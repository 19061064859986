import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { StepDto } from 'src/app/models/dto/stepDto';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit, OnChanges {
  @Input() locked: boolean;
  @Input() form!: FormGroup;

  steps: StepDto[] = [];
  clonedSteps: { [s: string]: StepDto; } = {};
  @ViewChild('stepTable') stepTable: any;

  constructor() { }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initialize();
  }

  initialize() {
    this.steps = this.form?.controls[formControlConstants.Steps]?.value ?? [];
    if(this.steps.length == 0)
      return;

    this.populateMissingValues();
    this.steps.sort((a, b) => a.no - b.no);
    this.reorderSteps(); //in case original data is not 1, 2, 3...
  }

  populateMissingValues() {
    this.steps.forEach(step => {
      if(!step.preCondition)
        step.preCondition = {key: null,value: ''};

      if(!step.stimuli)
        step.stimuli = {key: null,value: ''};

      if(!step.expectedResponse)
        step.expectedResponse = {key: null,value: ''};

      if(!step.postCondition)
        step.postCondition = {key: null, value: ''};
    });
  }

  addNewStep() {
    let newStep : StepDto = {
      expectedResponse: {key: null,value: ''},
      stimuli: {key: null,value: ''},
      postCondition: {key: null,value: ''},
      preCondition: {key: null,value: ''},
      no: this.steps.length + 1 
    };

    this.steps.push(newStep);
    this.stepTable.editingRowKeys = {[newStep.no]:true}
    this.onRowEditInit(newStep);
  }

  reorderSteps() {
    let currentRowNo = 1;
    this.steps.forEach(step => step.no = currentRowNo++);
    
    this.form.patchValue({ [formControlConstants.Steps]: this.steps });
  }

  onRowReorder(event) {
    this.reorderSteps();
  }
  
  deleteStep(stepNo: number) {
    let copy: StepDto[] = [];
    this.steps.forEach(step => {
      if(step.no !== stepNo)
        copy.push(step);
    });
    this.steps = copy;

    this.reorderSteps();
  }

  onRowEditInit(step: StepDto) {
    this.clonedSteps[step.no] = JSON.parse(JSON.stringify(step));
  }

  onRowEditSave(step: StepDto) {
    this.form.patchValue({ [formControlConstants.Steps]: this.steps });
  }

  onRowEditCancel(step: StepDto, index: number) {
    this.steps[index] = this.clonedSteps[step.no];
    delete this.clonedSteps[step.no];
  }
}
