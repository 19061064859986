import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { OffboardComponent } from 'src/app/models/dto/offboardComponent';
import { ApolloClientService } from './apollo-client.service';
import { GraphQLOffboardComponentOutput } from 'src/app/graphQL/models/output/graphql-offboard-component-output';
import { GETALL_OFFBOARD_COMPONENTS_QUERY } from 'src/app/graphQL/queries/offboard-component-queries';
import { convertGraphqOffboardComponentToDto } from 'src/app/graphQL/mappers/offboard-component.mappers';

@Injectable({
  providedIn: 'root'
})
export class OffboardComponentsApiService {

constructor(private apolloClientService: ApolloClientService) { }

get(): Observable<OffboardComponent[]> {
  return this.apolloClientService.apolloClient$.pipe(
    switchMap(client => 
      client.query<{configuratorAllOffboardComponents: GraphQLOffboardComponentOutput[]}>({
        query: GETALL_OFFBOARD_COMPONENTS_QUERY,
        variables: {}
      })
    ),
    map(results => {
      const offboardComponents = results.data.configuratorAllOffboardComponents
      if (!offboardComponents)
        return undefined

      return offboardComponents.map(item => {
        return convertGraphqOffboardComponentToDto(item);
      })
    })
  )
}
}
