import { gql } from "apollo-angular";

export const CREATE_TEST_SPECIFICATION_MUTATION = gql`mutation configuratorCreateTestSpecification($TestSpecification: TestSpecificationInput) {
    configuratorCreateTestSpecification(TestSpecification: $TestSpecification) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `

export const UPDATE_TEST_SPECIFICATION_MUTATION = gql`mutation configuratorUpdateTestSpecification($TestSpecification: TestSpecificationInput) {
    configuratorUpdateTestSpecification(TestSpecification: $TestSpecification) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `
  

export const DELETE_TEST_SPECIFICATION_MUTATION = gql`mutation configuratorDeleteTestSpecification($Uid: String!) {
    configuratorDeleteTestSpecification(Uid: $Uid)
  }
  `
  