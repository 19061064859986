export namespace TestAnalysisActions {
  export class SetAllTestAnalysis {
    static readonly type = '[TestAnalysis] SetAllTestAnalysis';
    constructor() {}
  }
  export class SetOrgGroupTestAnalysis {
    static readonly type = '[TestAnalysis] SetOrgGroupTestAnalysis';
    constructor(public orgGroupId: string) {}
  }

  export class DeleteTestAnalysis {
    static readonly type = '[TestAnalysis] DeleteTestAnalysis';
    constructor(public testAnalysisUid: string, public regBy: string) {}
  }

  export class SetUpdateNeeded {
    static readonly type = '[TestAnalysis] SetUpdateNeeded';
    constructor(public updateNeeded: boolean) {}
  }
}
