export enum ReviewCommentGroup {
  General = 'general',

  TC_General = 'tc_general',
  TC_Options = 'tc_options',
  TC_Steps = 'tc_steps',
  TC_Scripts = 'tc_scripts',
  TC_Description = 'tc_description',
  TC_Fpc = 'tc_fpc',
  TC_UserFunction = 'tc_userfunction',

  TA_General = 'ta_general',
  TA_Options = 'ta_options',
  TA_Requirement = 'ta_requirement',
  TA_Risk = 'ta_risk',
  TA_TestCases = 'ta_testcases',
  TA_Description = 'ta_description',

  TS_General = 'ts_general',
  TS_Options = 'ts_options',
  TS_TestSuites = 'ts_testsuites',
  TS_TestCases = 'ts_testcases',
  TS_Description = 'ts_description',
  TS_Fpc = 'ts_fpc',

  TSP_General = 'tsp_general',
  TSP_TestAnalyses = 'tsp_testanalyses',
}