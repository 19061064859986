import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { convertGraphqlTestObjectToTestObject } from 'src/app/graphQL/mappers/test-object.mappers';
import { GraphQLTestObjectOutput } from 'src/app/graphQL/models/output/graphql-test-object-output';
import { GET_TEST_OBJECTS_QUERY } from 'src/app/graphQL/queries/test-object-queries';
import { ApolloClientService } from './apollo-client.service';

@Injectable({
  providedIn: 'root'
})
export class TestObjectApiService {
  constructor(private apolloClientService: ApolloClientService) { }
  get() {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorTestObjects: GraphQLTestObjectOutput[]}>({
          query: GET_TEST_OBJECTS_QUERY,
          // variables: {Uid: uid}
        })
      ),
      map(results => {
        const testLevels = results.data.configuratorTestObjects
        if (!testLevels) 
          return undefined
        return testLevels.map(testLevel => {
          return convertGraphqlTestObjectToTestObject(testLevel);
        })
      })
    )
  }
}