import { gql } from "apollo-angular";

export const GET_LINKS_QUERY = gql`query configuratorLinks($ItemUid: String!, $LinkTypeId: Int!) {
    configuratorLinks(ItemUid: $ItemUid, LinkTypeId: $LinkTypeId) {
        Uid
        ItemUid
        TypeId
        ParentUid
        LinkedItemKey
        LinkedItemName
        LinkedItemVersion
        Order
        Operator
        Value
        ValidTo
        Properties{
            ItemUid
            Language
            Uid
            Value
        }
    }
  }
  `

export const GET_LINKS_BY_TYPEID_QUERY = gql`query configuratorLinksByTypeId($LinkTypeId: Int!) {
    configuratorLinksByTypeId(LinkTypeId: $LinkTypeId) {
        Uid
        ItemUid
        TypeId
        ParentUid
        LinkedItemKey
        LinkedItemName
        LinkedItemVersion
        Order
        Operator
        Value
        ValidTo
        Properties{
            ItemUid
            Language
            Uid
            Value
        }
    }
  }
  `