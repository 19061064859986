import { Injectable } from '@angular/core';
import { EMPTY, map, Observable, of, switchMap } from 'rxjs';
import { GraphQLFpcFamilyOutput } from 'src/app/graphQL/models/output/graphql-fpc-family-output';
import { GET_HALF_FPCS_QUERY } from 'src/app/graphQL/queries/fpc-queries';
import { FpcFamily } from 'src/app/models/dto/fpc-family';
import { ApolloClientService } from './apollo-client.service';
import { convertGraphqlFpcFamilyToFpcFamily } from 'src/app/graphQL/mappers/fpc.mappers';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FpcsApiService {

  constructor(private apolloClientService: ApolloClientService) {}

  getHalf(firstHalf: boolean): Observable<FpcFamily[]> {
    if(environment.IsHero == true)
      return EMPTY;
    
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorHalfFpcs: GraphQLFpcFamilyOutput[]}>({
          query: GET_HALF_FPCS_QUERY,
          variables: {FirstHalf: firstHalf}
        })
      ),
      map(results => {
        const fpcs = results?.data?.configuratorHalfFpcs;
        if (!fpcs) 
          return undefined;

        return fpcs.map(fpc => {
          return convertGraphqlFpcFamilyToFpcFamily(fpc);
        })
      })
    )
  }
}
