<div class="sdds-block sdds-block__onwhite properties">
  <div>
      <h4>Details</h4>
  </div>

  <div class="info sdds-block">
    <label>ItemNo: {{item?.itemNo}}</label>
    <label>Uid: {{ item?.uid }}</label>
    <label>Version: {{ item?.version }}</label>
    <label *ngIf="item?.version!=1">Modification time: {{ item?.modificationTime | date: "dd.MM.yyyy HH:mm" }}</label>
    <label *ngIf="item?.version!=1">Modified By: {{ item?.modifiedBy }}</label>
    <label>Creation Time: {{ item?.creationTime | date: "dd.MM.yyyy HH:mm" }}</label>
    <label>Created By: {{ item?.createdBy }}</label>
    <label>Version comment: {{ item?.comment }}</label>
  </div>
</div>
