import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ItemsService } from './api/items.service';
import { ItemType } from '../models/enums/itemType';

@Injectable({
  providedIn: 'root'
})
export class CheckedOutItemService {
  public checkedItemUidChange = new Subject<string>();

  private checkedOutItemUid: string  = undefined; 
  private checkedOutItemType: ItemType  = undefined; 


  constructor(private itemsService: ItemsService) {
    this.getCheckedOutItemFromDatabase();
   }

  getCheckedOutItemUid(): string {
    return this.checkedOutItemUid;
  }
  
  getCheckedOutItemType(): ItemType {
    return this.checkedOutItemType;
  }

  set(checkedOutItemUid?: string, checkedOutItemType?: ItemType) {
    this.checkedOutItemUid = checkedOutItemUid;
    this.checkedOutItemType = checkedOutItemType;

    this.checkedItemUidChange.next(checkedOutItemUid);
  }

  exists(): boolean {
    return this.checkedOutItemUid != undefined;
  }

  private getCheckedOutItemFromDatabase() {
    this.itemsService.getCheckedOutItemUidAndType().subscribe(checkedOutItemUidAndType => this.set(checkedOutItemUidAndType?.uid, checkedOutItemUidAndType?.itemType));
  }
}
