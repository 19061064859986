import { gql } from "apollo-angular";

export const GETALL_PRODUCT_PROPERTIES_QUERY = gql`query configuratorProductProperties {
    configuratorProductProperties {
        Uid
        Name
        ParentUid
    }
  }
  `
