import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SopActions } from './sop.actions';
import { SopStateModel } from './sop.state.model';
import { SopApiService } from 'src/app/services/api/sops-api.service';

@State<SopStateModel>({
  name: 'sop',
  defaults: {
    sop: [],
  },
})
@Injectable()
export class SopState {
  constructor(private sopApiService: SopApiService) {}

  @Action(SopActions.SetAllSop)
  SetAllSop(ctx: StateContext<SopStateModel>) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (state.sop !== undefined && state.sop.length > 0) return;
    
    this.sopApiService.get().subscribe(sop => {
      ctx.patchState({ sop: sop });
    });
  }

  @Selector()
  static getAllSop(state: SopStateModel) {
    return state.sop;
  }
}
