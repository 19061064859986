<div class="charts">
    <div style="position: relative" >
      <ngx-charts-pie-chart
                      [results]="evaluationReportData"
                      [legend]="true"
                      [legendTitle]="'Evaluation status report'" 
                      [labels]="true"
                      [trimLabels]="false"
                      [view]="[600, 350]"
                      [scheme]="colorScheme"
                      [gradient]="true"
                      [legendPosition]="legendPosition">
      </ngx-charts-pie-chart>
  
      <i class="pi pi-question-circle icon" 
        style="font-size: initial; position: absolute; bottom: 2rem; right: 6rem;"  
        [pTooltip]="EvaluationRuleMessage"
        tooltipPosition="top"
      ></i>
  
  </div>
  
  <ngx-charts-pie-chart 
                  [results]="approvalReportData"
                  [legend]="true"
                  [legendTitle]="'Approval status report'"
                  [labels]="true"
                  [trimLabels]="false"
                  [view]="[650, 350]"
                  [scheme]="colorScheme"
                  [gradient]="true"
                  [legendPosition]="legendPosition">
  </ngx-charts-pie-chart>
</div>