import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { FpcsStateModel } from "./fpcs.state.model";
import { FpcsApiService } from "src/app/services/api/fpcs-api.service";
import { FpcsActions } from "./fpcs.actions";

@State<FpcsStateModel>({
    name: 'fpcFamilies',
    defaults: {
        fpcFamilies: []
    },
})
@Injectable()
export class FpcsState{
    constructor(private fpcsApiService: FpcsApiService) {}

    @Action(FpcsActions.SetAllFpcs)
    SetAllFpcs(ctx: StateContext<FpcsStateModel>) {
        const state = ctx.getState();
        //don't update state if we have already downloaded items
        if (state.fpcFamilies !== undefined && state.fpcFamilies.length > 0) 
            return;

        this.fpcsApiService.getHalf(true).subscribe(firstHalf => {
            this.fpcsApiService.getHalf(false).subscribe(secondHalf => {
                var allFpcFamilies = firstHalf?.concat(secondHalf);
                ctx.patchState({fpcFamilies: allFpcFamilies});
            })
        });
    }

    @Selector()
    static getAllFpcs(state: FpcsStateModel){
        return state.fpcFamilies;
    }
}