<p-table [value]="parentItems" [scrollable]="true" scrollHeight="250px" dataKey="uid">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 20%;">Type</th>
        <th style="width: 20%;">Item No</th>
        <th style="width: 60%;">Name</th>
      </tr>
    </ng-template>
  
    <ng-template pTemplate="body" let-item>
      <tr>
        <td style="width: 20%;">{{ itemType[item.typeName] }}</td>
        <td style="width: 20%;">{{ item.itemNo }}</td>
        <td style="width: 60%;">
            <a [routerLink]="['/', getRoute(item.typeName), item.uid]" (click)="closeModal()" >{{ item.name }}</a>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="7">No parent items found.</td>
        </tr>
    </ng-template>
</p-table>

