import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EcusApiService } from 'src/app/services/api/ecus-api.service';
import { EcuActions } from './ecu.actions';
import { EcuStateModel } from './ecu.state.model';
import { ChildItem, ParentChildHierarchy } from 'src/app/models/parent-child-hierarchy';
import { Ecu } from 'src/app/models/ecu';


@State<EcuStateModel>({
  name: 'ecus',
  defaults: {
    ecus: [],
    ecuHierarchy: [],
  },
})
@Injectable()
export class EcuState {  
  constructor(private ecusApiService: EcusApiService) {}

  @Action(EcuActions.SetAllEcus)
  SetAllEcus(ctx: StateContext<EcuStateModel>) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (state.ecus !== undefined && state.ecus.length > 0) 
      return;

    this.ecusApiService.get()
      .subscribe(ecus => {
        ctx.patchState({ ecus: ecus });
        ctx.patchState({ ecuHierarchy: this.parentChildMapperForEcus(ecus)});
      });
  }

  @Selector()
  static getAllEcus(state: EcuStateModel) {
    return state.ecus;
  }

  @Selector()
  static getEcuHierarchy(state: EcuStateModel) {
    return state.ecuHierarchy;
  }


  parentChildMapperForEcus(ecuList: Ecu[]): ParentChildHierarchy[]{
    let ecuHierarchy : ParentChildHierarchy[] = [];
    let children = ecuList.filter(ecu => ecu.parentId != 0);
    let parents = ecuList.filter(ecu => ecu.parentId === 0);

    parents.forEach((parent) =>{
      var childrenOfParentEcu : ChildItem[] = [];
      var childrenOfParent = children.filter(child => child.parentId == parent.id);
      childrenOfParent.forEach((child) =>
        childrenOfParentEcu.push({
              value: child.id.toString(),
              label: child.name,
              parent: child.parentId
          })
      )

      ecuHierarchy.push({
        value:parent.id.toString(),
        label: parent.name,
        items: childrenOfParentEcu
      })
      childrenOfParentEcu.splice;
    })
    return ecuHierarchy;
  }
}
