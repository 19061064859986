import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { formField } from 'src/app/models/formField';
import { FieldTypeEnum } from 'src/app/models/enums/fieldTypeEnum';
import { FormGroup } from '@angular/forms';
import { createFormGroup } from 'src/app/services/helpers/formHelper';
import { ItemType } from 'src/app/models/enums/itemType';
import { ReviewCommentGroup } from 'src/app/models/review/commentGroup';
import { ApprovalStatus } from 'src/app/models/enums/approvalStatus';
import { Approval } from 'src/app/models/review/approval';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { ReviewService } from 'src/app/services/review/review.service';

@Component({
  selector: 'app-review-header',
  templateUrl: './review-header.component.html',
  styleUrls: ['./review-header.component.scss'],
})
export class ReviewHeaderComponent {
  @Input() testItem?:BaseTestItemDto;
  @Input() approval: Approval
  @Output() enableComments = new EventEmitter<boolean>();

  username: string;
  currentUserSubscription: Subscription;
  allCommentsHandledSubscription: Subscription;
  commentsSubscription: Subscription;
  isHero = environment.IsHero;
  fields: formField[] = [];
  fieldTypes = FieldTypeEnum;
  form: FormGroup;

  readonly ReviewCommentGroup = ReviewCommentGroup;
  isReviewCompleted: boolean = true;
  addCommentButtonDisabled: boolean = true;
  clearButtonDisabled: boolean = true;
  saveButtonDisabled: boolean = false;
  enableReviewComment: boolean;
  clearReviewComments: boolean;
  approvalStatus = ApprovalStatus;
  allCommentsHandled :Boolean;

  constructor(
    private authService: AuthService, 
    private reviewService: ReviewService) 
    {}

  ngOnInit(): void {
    console.log("Review header is initializing");
    this.currentUserSubscription = this.authService.userData$.subscribe((result) => {
      this.username = result.userName;
      if (this.testItem) {
        this.initializeApprovalAndForm();
      }
    });

    this.allCommentsHandledSubscription = this.reviewService.allCommentsHandled$.subscribe({
      next: (handled) => {
        this.allCommentsHandled = handled;
        this.handleSaveButtonEnablement();
      },
      error: (err) => {
        console.error('Error while subscribing to allCommentsHandled', err);
      }
    });

    // console.log("Approver field on init: ", this.approval.approver);
    // If testItem is already set, initialize the approval and form
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.testItem && changes.testItem.currentValue) || (changes.approval && changes.approval.currentValue)) {
      this.initializeApprovalAndForm();
    }
  }
  
  ngOnDestroy(): void {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
    if (this.allCommentsHandledSubscription) {
      this.allCommentsHandledSubscription.unsubscribe();
    }
    if (this.commentsSubscription) {
      this.commentsSubscription.unsubscribe();
    }
  }

  // todo: separate this two initializations
  initializeApprovalAndForm(): void {
    this.form = createFormGroup(this.fields, ItemType.TestCase);
    if (this.approval) {
      this.setButtonsEnablement();
    }
  }

  getReviewersString(): string {
    return this.approval?.reviewers?.join(', ');
  }

  // #region + Comment buttons +

  setButtonsEnablement() {

    this.addCommentButtonDisabled = !this.isUserReviewer();

    this.clearButtonDisabled = true;
    this.saveButtonDisabled = true;

    if(this.addCommentButtonDisabled){
      this.reviewService.allCommentsHandled$.subscribe()
    }
    console.log("Add button disabled: ", this.addCommentButtonDisabled);

  }

  onAddCommentButtonClick(){
    if(this.approval.approvalStatus == ApprovalStatus.ReadyForReview)
      this.approval.approvalStatus = ApprovalStatus.InReview;

    if(this.clearButtonDisabled)
      this.clearButtonDisabled = false;

    if(this.saveButtonDisabled){
      this.saveButtonDisabled = false;
    }

    this.addCommentButtonDisabled = true;
    //Approvers can't add or change comments, only reviewers
    if(this.isUserApprover())
      this.handleCommentsEnablement(false);
    else{
      this.handleCommentsEnablement(true);
    }

    this.handleSaveButtonEnablement()
  }

  onClearCommentsButtonClick(){
    this.addCommentButtonDisabled = false;
    this.clearButtonDisabled = true;
    this.saveButtonDisabled = true;
    //
    //this.handleCommentsClearing(true);
    if(this.doOldOldCommentsExist())
      this.approval.approvalStatus = ApprovalStatus.ReadyForReview;

    this.reviewService.clearComments()
  }

  onSaveCommentsButtonClick(){
    this.addCommentButtonDisabled = false;
    this.clearButtonDisabled = true;
    this.saveButtonDisabled = true;
    this.approval.approvalStatus = ApprovalStatus.Approved;
    this.handleCommentsEnablement(false);
    //this.saveComments();
  }

  handleCommentsEnablement(enable: boolean){
    this.enableReviewComment = enable;
    this.enableComments.emit(enable);
  }

  handleReviewCompleted (event: Event) {
    const target = event.target as HTMLInputElement;
    const enable = target.checked;
    this.approval.approvalStatus = enable ? ApprovalStatus.Approved : ApprovalStatus.WaitingForApproval
    this.reviewService.updateApprovalStatus(this.approval, this.username).subscribe()
  }

  handleSaveButtonEnablement(){
    this.saveButtonDisabled = !this.allCommentsHandled;
    console.log("handleSaveButtonEnablement - =", this.saveButtonDisabled)
  }

  isUserApprover(): boolean{
    if(this.username.toLowerCase() === this.approval.regBy.toLowerCase()) 
      return true;
    return false;
  }

  isUserReviewer(): boolean {
    for (const element of this.approval.reviewers) {
      if (this.username.toLowerCase() === element.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
  
  doOldOldCommentsExist(): Boolean{
    if (!this.approval || !this.approval.comments) {
      return false;
    }
    return this.approval.comments.some(comment => !comment.newComment);
  }
  //#endregion
}

