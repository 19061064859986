import { gql } from "apollo-angular";

export const GET_PARENT_ITEMS_QUERY = gql`query configuratorParentItems($ItemUid: String!) {
    configuratorParentItems(ItemUid: $ItemUid) {
      Uid
      Name
      TypeId
      ItemNo
    }
  }
  `

export const TRY_GET_LOCKED_ITEM_UID_AND_TYPE_QUERY = gql`query configuratorTryGetLockedItemUidAndTypeByUser($User: String!) {
    configuratorTryGetLockedItemUidAndTypeByUser(User: $User) {
      Uid
      ItemType
    }
  }
  `

export const GET_ITEMS_WITH_LINK_QUERY = gql`query configuratorItemsWithLink($ItemTypeId: Int!, $LinkTypeId: Int!, $LinkValue: String!) {
  configuratorItemsWithLink(ItemTypeId: $ItemTypeId, LinkTypeId: $LinkTypeId, LinkValue: $LinkValue) {
    Version
    Uid
    ItemNo
    Name
    ModifiedBy
    ModificationTime
    LockedTime
    LockedBy
    CreationTime
    CreatedBy
    Comment
    ApprovedForTestLevel
    ApprovalStatus
  }
}
`