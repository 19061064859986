import { gql } from "apollo-angular";

export const GETALL_TEST_SUITES_QUERY = gql`query configuratorAllTestSuites($RegBy: String, $OrgGroup: String) {
    configuratorAllTestSuites(RegBy: $RegBy, OrgGroup: $OrgGroup) {
        Version
        Uid
        ItemNo
        Name
        ModifiedBy
        ModificationTime
        LockedTime
        LockedBy
        CreationTime
        CreatedBy
        Comment
        ApprovedForTestLevel
        ApprovalStatus
    }
  }
  `
  
export const GET_TEST_SUITE_QUERY = gql`query configuratorTestSuiteById($Uid: String!) {
    configuratorTestSuiteById(Uid: $Uid) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `

export const GET_TEST_SUITE_UI_TEMPLATE_QUERY = gql`query configuratorTestSuiteUiTemplate {
    configuratorTestSuiteUiTemplate {
        Name
        Category
        DefaultValue
        FieldType
        IsMultiValue
        HasHierarchyValues
        IsReadOnly
        Label
        IsRequired
        Order
        Tooltip
        ValueList
    }
  }
  `