import { gql } from "apollo-angular";

export const GET_REQUIREMENT_QUERY = gql`query polarionRequirementByIdAndIrma($RequirementId: String, $IrmaVersion: Int) {
  polarionRequirementByIdAndIrma(RequirementId: $RequirementId, IrmaVersion: $IrmaVersion) {
      PolarionId
      Title
      Revision
      IrmaVersion
      Asil
      Status
      Legislation
    }
  }
  `


  export const GET_REQUIREMENTS_FOR_SPECIFICATION_QUERY = gql`query polarionRequirementsForSpecification($SpecificationId: String, $IrmaVersion: Int) {
    polarionRequirementsForSpecification(SpecificationId: $SpecificationId, IrmaVersion: $IrmaVersion) {
      PolarionId
      Title
      Revision
      IrmaVersion
      Asil
      Status
      Legislation
    }
  }
  `